import React, { useState, useEffect } from "react";
import {
    useRouteMatch,
    Switch,
    Link,
    useParams,
    useHistory
} from "react-router-dom";
import { Col, Row, Button } from "antd";
import Icon from '@ant-design/icons';
import './styles.less';
import AccessDeniedSvg from  '../../../assets/svg-icons/access-denied.svg';

const AccessDeniedIcon = props => <Icon component={AccessDeniedSvg} style={{ fontSize: 200, verticalAlign: 'middle'}} {...props} />;

export default function PermissionDenied() {
    const history = useHistory();

    return (
        <div style={{ height: '100%'}}>
            <Row className="permission-denied">
                <Col>
                    <AccessDeniedIcon className="access-denied-icon"/>
                    <span className="access-denied-title">Permission Denied</span>
                    <p>You don't have permission to access this section.</p>
                    {/* <Button type="primary" id="access-denied-back" onClick={() => history.goBack()}>Go back</Button> */}
                </Col>
            </Row>
        </div>
    );
}