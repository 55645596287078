import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';


String.prototype.timeZoneDate = function (value, timezone, format = 'dddd, D MMM YYYY') {
    if (!value) {
        return value;
    }
    if (timezone) {
        return momentTimeZone.utc(value).tz(timezone).format(format);
    } else {
        return moment.utc(value).local().format(format);
    }
}
