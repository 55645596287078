import { createStore } from 'redux';

const dataReducer = (state = { case: null, settings: null, chatCountRefresh: null, roles: null, user: null}, action) => {
    if (action.type === 'case') {
        return {
            case: action.value,
            settings: state.settings,
            chatCountRefresh: state.chatCountRefresh,
            roles: state.roles,
            user: state.user
        };
    }
    if (action.type === 'settings') {
        return {
            case: state.case,
            settings: action.value,
            chatCountRefresh: state.chatCountRefresh,
            roles: state.roles,
            user: state.user
        };
    }
    if (action.type === 'chatCountRefresh') {
        return {
            case: state.case,
            settings: state.settings,
            chatCountRefresh: action.value,
            roles: state.roles,
            user: state.user
        };
    }
    if (action.type === 'roles') {
        return {
            case: state.case,
            settings: state.settings,
            chatCountRefresh: state.chatCountRefresh,
            roles: action.value,
            user: state.user
        };
    }
    if (action.type === 'user') {
        return {
            case: state.case,
            settings: state.settings,
            chatCountRefresh: state.chatCountRefresh,
            roles: state.roles,
            user: action.value
        };
    }
    return state;
}
const store = createStore(dataReducer);

export default store;