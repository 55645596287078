import React, { useState, useEffect } from "react";
import { Row, Col, Menu, Drawer, Collapse, Checkbox, DatePicker, Dropdown, Button, Space, Card, Avatar, Select, Skeleton, message } from 'antd';
import {
    useRouteMatch,
    Switch,
    Link,
    useHistory,
    useParams,
} from "react-router-dom";
import Icon from "@ant-design/icons";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import "../../../logics/prototypes";
import { websocketService, socket } from "../../../logics/services/websocket.service";
import InfiniteScroll from "react-infinite-scroll-component";
import CreateOutcome from "../../../pages/app/CaseView/CreateOutcome";
import OutcomeView from "../../../pages/app/CaseView/OutcomeView";
import DecisionView from "../../../pages/app/CaseView/DecisionView";
import AddSvg from "../../../assets/svg-icons/add.svg";
import EmptyOutcomeSvg from "../../../assets/svg-icons/empty_outcome.svg";
import ProposalSvg from "../../../assets/svg-icons/proposal_icon.svg";
import DecisionSvg from "../../../assets/svg-icons/decision.svg";

const AddIcon = props => <Icon component={AddSvg} style={{ fontSize: 24, verticalAlign: 'middle', float: 'right' }} {...props} />;
const EmptyOutcomeIcon = props => <Icon component={EmptyOutcomeSvg} style={{ fontSize: 236, verticalAlign: 'middle'}} {...props} />;
const ProposalIcon = props => <Icon component={ProposalSvg} style={{ fontSize: 40, verticalAlign: 'middle' }} {...props} />;
const DecisionIcon = props => <Icon component={DecisionSvg} style={{ fontSize: 40, verticalAlign: 'middle' }} {...props} />;

function Outcome() {

    let { caseId, caseDetailView } = useParams();
    const history = useHistory();
    const [detailLink, setDetailLink] = useState(caseDetailView);
    let link = caseDetailView ? JSON.parse(atob(caseDetailView)): '';
    if(detailLink != caseDetailView) {
        setDetailLink(caseDetailView);
    }
    const user = userService.getUser();
    const loaderList = [1,2,3,4];
    const pageSize = 10;
    const [caseInfo, setCaseInfo] = useState([]);
    const [initLoading, setInitLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [type, setType] = useState('all');
    const [pageIndex, setPageIndex] = useState(1);
    const [assignee, setAssignee] = useState(0);
    const [filterparamsData, setFilterparamsData] = useState([]);
    const [outcomeList, setOutcomeList] = useState([]);
    const [createOutcome, setCreateOutcome] = useState(false);
    const [addProposal, setAddProposal] = useState(false);
    const [editProposal, setEditProposal] = useState(false);
    const [proposalId, setProposalId] = useState(0);
    const [rolePermissions, setRolePermissions] = useState([]);

    socket.onmessage = resData => {
        let res = (resData && resData.data) ? JSON.parse(resData.data): [];
        if(res && res.method == 'proposal') {      
            let data = res.data;             
            getSettlement(type, 1, [], 0, filterparamsData);
        } else  if(res && res.method == 'decision') {      
            let data = res.data;             
            getSettlement(type, 1, [], 0, filterparamsData);
        }
    }

    const getSettlement = (typeTab = type, pageIndexTab = pageIndex, outcomeTab = outcomeList, assigneeTab = assignee, filterparams = filterparamsData) => {
        setHasMore(true);
        setRolePermissions(user.permissions);
        let sortOrder;
        let sortField;
        if (sortOrder == 'ascend') {
            sortOrder = 'asc';
        }  else if (sortOrder == 'descend') {
            sortOrder = 'desc';
        }
        if (sortField == null) sortField = ''; 
        if (sortOrder == null) sortOrder = ''; 
        var offset = (pageIndexTab - 1) * pageSize;
        let param = {
            type: typeTab,
            caseId: caseId,
            overall_search: '',
            assignee: assigneeTab,
            page: pageIndexTab,
            offset: offset,
            results: pageSize,
            sortField: sortField,
            sortOrder: sortOrder
        };
        let paramData = {...param, ...filterparams};
        apiService.getSettlement(paramData).then((res) => {
            if (res.status === 'success') {
                setOutcomeList(outcomeTab.concat(res.data));
                setInitLoading(false);
                setPageIndex(pageIndexTab + 1);
                var nextOffset = ((pageIndexTab) * pageSize);
                if (res.count <= nextOffset) {  
                    setHasMore(false);
                }
            }
        });
    }

    useEffect(() => {
        getSettlement();
        getSingleCase();
        if(link && link.link_id) {
            apiService.getSingleSettlement({
                csId: link.link_id,
                caseId: caseId
            }).then(res => {
                if (res.status == 'success') {
                    if(!res.data)  {
                        message.error('This outcome is currently unavailable.');
                    }
                    let settlementParties = res.data.parties.split(',');
                    if(res.data.created_by.u_id == user.id || settlementParties.indexOf(user.id.toString()) != -1) {
                        if (res.data.is_deleted != 1 && res.data.type == 'proposal') {
                            setProposalId(link.link_id);
                            setOutcomeView(true)
                        } else if (res.data.is_deleted != 1 && res.data.type == 'decision') {
                            setProposalId(link.link_id);
                            setDecisionView(true)
                        }
                    }
                } else {
                    message.error(res.message);
                }
            });
        }
    }, [detailLink]);

    const getSingleCase = () => {
        apiService.getSingleCase({
            case_id: caseId
        }).then((res) => {
            if (res.status === 'success') {
                setCaseInfo(res.data);
            }
        });
    }

    const showCreateOutcome = () => {
        setCreateOutcome(true);
        setAddProposal(true);
    };
    
    const closeCreateOutcome = (val) => {
        setCreateOutcome(val);
        setAddProposal(false);
        getSettlement(type, 0, [], assignee, filterparamsData);
    }

    const [outcomeView, setOutcomeView] = useState(false);
    const [decisionView, setDecisionView] = useState(false);

    const showOutcomeView = (proposalId, type) => {
        setProposalId(proposalId);
        (type == 'decision') ? setDecisionView(true): setOutcomeView(true);        
    };

    const closeOutcomeView = (val) => {
        setOutcomeView(val);
        getSettlement(type, 0, [], assignee, filterparamsData)
    }

    const closeDecisionView = (val) => {
        setDecisionView(val);
        getSettlement(type, 0, [], assignee, filterparamsData)
    }

    const viewEditProposal = (val, proposalId) => {
        setOutcomeView(val);
        setProposalId(proposalId);
        setCreateOutcome(true);
        setEditProposal(true);
    }

    return (

        <div>
            <Row className="page-header">
                <Col span={12}>
                    Outcomes
                </Col>
                <Col span={12}>
                    {   Boolean(rolePermissions['Create Proposal']) &&
                        (
                            <AddIcon className={(caseInfo.case_status != 3 || caseInfo.is_auto_close_denied != 0 || (user && user.active != 1 && user.status == 0)) ? "disabled-opacity": ""} onClick={() => showCreateOutcome()}/>
                        )
                    }
                </Col>
                {createOutcome && (<CreateOutcome caseId={caseId} visible={createOutcome} addProposal={addProposal} editProposal={editProposal} proposalId={proposalId} user={user} history={history} closeCreateOutcomeVisible={closeCreateOutcome}/>)}
            </Row>

            {   initLoading
                ?
                    loaderList.map((item, index) => (
                        <Card key={index}>
                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                        </Card>
                    ))
                :
                    <InfiniteScroll
                        dataLength={outcomeList.length}
                        next={getSettlement}
                        hasMore={hasMore}
                        loader={loaderList.map((item, index) => (<Card key={index}>
                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                        </Card>))}
                    >
                        { outcomeList && outcomeList.length > 0 
                            ? outcomeList.map((item, index) => (
                                <div className="all-outcome-list" key={index} onClick={() => showOutcomeView(item.c_s_id, item.outcome_type)}>
                                    <Row>
                                        <Col xs={3} sm={3} md={2} lg={2} xl={1}> {item.outcome_type == "decision" ? <DecisionIcon />: item.outcome_type == "proposal" ? <ProposalIcon />: ""} </Col>
                                        <Col xs={21} sm={21} md={22} lg={22} xl={23}>
                                            <Row className="outcome-title">{item.type}</Row>
                                            <Row className="outcome-due-date">
                                                <b>Created On:</b> {item.created_at.utcLocal().dateTimeFormat()}
                                                <span className={(item.status == "Rejected" || item.status == "Voided" || item.status == "Approval Denied") ? "text-red": (item.status == "Accepted" || item.status == "Completed" || item.status == "Approved" || item.status == "Case Decision Submitted") ? "text-green": (item.status == "Withdrawn") ? "text-warning-yellow": "text-daybreak-blue"}>{item.status}</span>
                                            </Row>
                                        </Col>                               
                                    </Row>
                                </div>
                            ))
                            :
                                <div className="empty-nocontent">
                                    <span>
                                        <EmptyOutcomeIcon/>
                                    </span>
                                    <p className="no-yet">
                                        No Outcomes yet !
                                    </p>
                                </div>
                        }
                    </InfiniteScroll>
            }
            {outcomeView && (<OutcomeView caseId={caseId} visible={outcomeView} proposalId={proposalId} user={user} history={history} closeOutcomeViewVisible={closeOutcomeView} viewEditProposal={viewEditProposal}/>)}
            {decisionView && (<DecisionView caseId={caseId} visible={decisionView} proposalId={proposalId} user={user} closeDecisionViewVisible={closeDecisionView}/>)}
        </div>
    );
}
export default Outcome;