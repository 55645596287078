import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Card, Drawer, Skeleton } from 'antd';
import { useParams, useHistory } from "react-router-dom";
import userService from "../../logics/services/user.service";
import apiService from "../../logics/services/api.service";
import { encrypt } from '../../logics/helpers';
import { websocketService, socket } from "../../logics/services/websocket.service";
import { useSelector } from "react-redux";
import { EyeIcon, EyeInvisibleIcon } from '../../logics/images'

function SetupAccount() {
    const settings = useSelector(state => state.settings);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);
    const [isVisiblePrivacy, setPrivacyVisible] = useState(false);
    const [form] = Form.useForm();
    let { token } = useParams();

    useEffect(() => {
        apiService.inviteeToken(token)
            .then(res => {
                if (res.status === 'success') {
                    form.setFieldsValue({
                        email: res.data.email,
                        fname: res.data.first_name,
                        lname: res.data.last_name
                    })
                } else if (res.status === 'error') {
                    message.error(res.message);
                    setTimeout(() => {
                        history.push('/auth/login');
                    }, 500);
                }
            });
        console.log(form)
    },[]);

    const checkPassValid = (type, method) => {
        switch (type) {
            case 0:
                if (method == 0)
                    return form.password.value && !form.password.value.match(".*[a-z].*");
                else
                    return form.password.value && form.password.value.match(".*[a-z].*");
                break;
            case 1:
                if (method == 0)
                    return form.password.value && !form.password.value.match(".*[A-Z].*");
                else
                    return form.password.value && form.password.value.match(".*[A-Z].*");
                break;
            case 2:
                if (method == 0)
                    return form.password.value && !form.password.value.match(".*[0-9].*");
                else
                    return form.password.value && form.password.value.match(".*[0-9].*");
                break;
            case 3:
                if (method == 0)
                    return form.password.value && !form.password.value.match(".*[$@$!*#?&].*");
                else
                    return form.password.value && form.password.value.match(".*[$@$!*#?&].*");
                break;        
            default:
                break;
        }
    }

    const onFinish = (data) => {
        setLoading(true);
        let password = encrypt(data.createpassword).toString();
        let confirmPassword = encrypt(data.confirmpassword).toString();
        apiService.setInviteePassword({
            first_name: data.fname,
            last_name: data.lname,
            email: data.email,
            password: password,
            password_confirmation: confirmPassword,
            token: token
        })
        .then(res => {
            if (res.status === 'success') {
                message.success('Case accepted successfully.');
                userService.setUserToken(res.data.token);
                if (res.data.casecount == 1 && res.data.lastestCaseId) {
                    history.push('/app/case/'+(res.data.lastestCaseId)+'/overview');
                } else {
                    history.push('/app/allcases');
                }
                if(res.inviteData) {
                    websocketService.send({
                        method: 'case',
                        isDisplay: true,
                        data: {
                            users: res.inviteData.users,
                            content: res.inviteData.content,
                            type: 'accept-case',
                            case_id: res.inviteData.case_id,
                            case_name: res.inviteData.case_name,                                
                        }
                    });
                }
            } else if (res.status === 'error') {
                setLoading(false);
                form.setFieldsValue({
                    createpassword: null,
                    confirmpassword: null
                })
                message.error(res.message);
            }
        }, () => {
            setLoading(false);
            form.setFieldsValue({
                createpassword: null,
                confirmpassword: null
            })
        });
    };

    const showTermsCondition = () => (
        setTermsConditions(true)
    )

    const showPrivacy = () => {
        setPrivacyVisible(true);
    }

    const onClose = () => {
        setTermsConditions(false)
    }

    const closePrivacy = () => {
        setPrivacyVisible(false)
    }
   
    return (
        <Row className="max-480 p16 auth-container">
            <Col className="textCenter mb24 mt40 mb40" span={24}>
                { settings && <img className="portal-logo" src={settings?.logo} alt="logo" /> }
                { !settings && <Skeleton style={{width: 100, margin: '0 auto'}} active paragraph={false} /> }
            </Col>
            <Col span={24}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <h3 className="mb8">Setup  your account</h3>
                    <Form.Item>
                        <Form.Item 
                            label="Email" 
                            name='email' 
                            className="w100 mb0"
                        >
                            <Input placeholder="Enter email" disabled/>
                        </Form.Item> 
                    </Form.Item>
                    <Form.Item>
                        <Form.Item 
                            label="First Name" 
                            name='fname' 
                            className="w100 mb0"
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z ]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <Input placeholder="Enter first name" />
                        </Form.Item> 
                    </Form.Item>
                    <Form.Item>
                        <Form.Item 
                            label="Last Name" 
                            name='lname' 
                            className="w100 mb0"
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z ]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <Input placeholder="Enter last name" />
                        </Form.Item> 
                    </Form.Item>
                    <Form.Item>
                        <Form.Item 
                            label="Create Password" 
                            name='createpassword' 
                            className="w100 mb0"
                        >
                            <Input.Password placeholder="Enter password" name="create-password" maxLength={32}
                                iconRender={visible => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)} 
                            />
                        </Form.Item> 
                    </Form.Item>
                    { form.getFieldValue('password') && 
                        <ul class="list">
                            <li> 1 Lower case.</li>
                            <li> 1 Upper case.</li>
                            <li> 1 Number.</li>
                            <li> 1 Special character from @$!*#?&.</li>
                            <li> 8 Characters minimum.</li>  
                        </ul>
                    }
                    <Form.Item>
                        <Form.Item 
                            label="Confirm Password" 
                            name='confirmpassword' 
                            className="w100 mb0"
                        >
                            <Input.Password placeholder="Enter password" name="confirm-password" maxLength={32}
                                iconRender={visible => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)} 
                            />
                        </Form.Item> 
                    </Form.Item>
                    <Form.Item>
                        <Button className="w100 br8" loading={loading} type="primary" htmlType="submit">Login</Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} className="terms-policy">
                <p>By signing in you confirm that you’ve read and accepted our <span className="terms-conditions" onClick={showTermsCondition}>Terms and Conditions</span> and <span className="terms-conditions" onClick={showPrivacy}>Privacy Policy</span>.</p>
            </Col>
            <Drawer
                    title="Terms and Conditions"
                    className="custom-drawer-popup"
                    placement="bottom"
                    height={"100%"}
                    visible={termsConditions}
                    onClose={onClose}
                >
                    <Card className="terms-condition-content"><div dangerouslySetInnerHTML={{ __html: settings?.terms }}></div></Card>
                </Drawer>
                <Drawer
                    title="Privacy Policy"
                    className="custom-drawer-popup"
                    placement="bottom"
                    height={"100%"}
                    visible={isVisiblePrivacy}
                    onClose={closePrivacy}
                >
                    <Card className="terms-condition-content"><div dangerouslySetInnerHTML={{ __html: settings?.privacy }}></div></Card>
                </Drawer>
        </Row>
    );
}
export default SetupAccount;