import React, { useState, useEffect } from "react";
import {
  useHistory
} from "react-router-dom";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { message } from 'antd';
import Icon from '@ant-design/icons';
import { websocketService, socket } from "../../../logics/services/websocket.service";
import { useRouteMatch, Switch, Link, useParams } from "react-router-dom";
import CommonCaseResponse from "../../../components/CommonCaseResponse/CommonCaseResponse";

function CaseResponse() {

    const user = userService.getUser();
    const history = useHistory();
    let { token } = useParams();
    const [caseToken, setCaseToken] = useState();
    const [caseId, setCaseId] = useState();
    const [showCaseResponse, setShowCaseResponse] = useState(false);
    if(caseToken != token) {
      setCaseToken(token);
      setCaseId();
      setShowCaseResponse(false);
    }

    useEffect(() => {
      apiService.inviteeInfo({invite_id: token})
      .then(res => {
          if (res.status === 'success') {
            setCaseId(res.data.case_id);
            setShowCaseResponse(true);
          } else if (res.status === 'error') {
              message.error(res.message);
              setTimeout(() => {
                  if(user && user.casecount ==1 && user.lastestCaseId) {
                    history.push('/app/case/'+(user.lastestCaseId)+'/overview');
                  } else {
                    history.push('/app/dashboard');
                  }
              }, 1000);
          }
      });
    }, [caseToken]);

    const closeCaseResponse = (event) => {
      if (event && event.status == 'success') {
        history.push('/app/case/'+(caseId)+'/overview');
      }
    }

    return (
      <div className="case-response-container">
        { showCaseResponse &&
          (<CommonCaseResponse type={1} caseId={caseId} inviteId={token} history={history} closeCaseResponseVisible={closeCaseResponse} />)
        }
      </div>
    );
}
export default CaseResponse;
