import React, { useState, useEffect } from "react";
import {
  useHistory
} from "react-router-dom";
import userService from "../../logics/services/user.service";
import apiService from "../../logics/services/api.service";
import store from '../../logics/store/case.store';
import { Row, Col, Avatar, Tag, Button, Drawer, Select, Input, Collapse, Spin, message } from 'antd';
import Icon from '@ant-design/icons';
import { websocketService, socket } from "../../logics/services/websocket.service";
import { useRouteMatch, Switch, Link, useParams } from "react-router-dom";
import "../../logics/prototypes";

import RejectCaseSvg from '../../assets/svg-icons/reject_case.svg';

const RejectCaseIcon = props => <Icon component={RejectCaseSvg} style={{ fontSize: 200, verticalAlign: 'middle', float: 'middle' }} {...props} />;

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
class CommonCaseResponse extends React.Component {
    
    constructor(props) {
        super(props);
        this.siteSetting=store.getState().settings;
        this.roles = store.getState().roles;
        this.caseId=this.props.caseId;
        this.type=this.props.type;
        this.token=this.props.inviteId;
        this.history=this.props.history;
        this.caseInfo=[];
        this.state = {
            caseInfo: [],
            rejectTypes: [],
            loading: false,
            showSuccess: false,
            mainStatus: '',
            subStatus: '',
            module: 'rejectcase',
            reasonType: 'reject',
            rejectReason: '',
            rejectComments: '',
            rejectVisible: false,
            roleRefresh: false,
            ws: null
        };
    }

    componentDidMount = async () => {
        this.getCaseRejectReason();
        if (this.caseId && this.type == 0) {
            apiService.inviteCaseInfo({
                invite_token: this.token,
                case_id: this.caseId
            })
            .then(res => {
                if (res.status == 'success') {
                    this.setState({caseInfo: res.data, showSuccess: false});
                    this.caseInfo = res.data;
                }
            });
        } else if (this.caseId && this.type == 1) {
            this.getCasesInfo(); 
            this.setState({showSuccess: false});
        }
    };

    getCasesInfo() {
        apiService.getCaseInfo({ 
            case_id: this.caseId, 
            invite_id: this.token
        })
        .then(res => {
            if (res.status == 'success') {
                this.caseInfo = res.data;
                this.setState({caseInfo: res.data, mainStatus: 'Case Creation', subStatus: 'Completed'});
                if (this.caseInfo.case_status == 1) {
                    this.setState({mainStatus: 'Case Invitations', subStatus: 'Awaiting Response'});
                } else if (this.caseInfo.case_status == 2) {
                    this.setState({mainStatus: 'Case Invitations', subStatus: 'Awaiting Admin allocation'});
                } else if (this.caseInfo.case_type == 1 && this.caseInfo.case_status == 3) {
                    this.setState({mainStatus: this.caseInfo.resolution.rc_name, subStatus: 'In Progress'});
                } else if (this.caseInfo.case_type == 1 && this.caseInfo.case_status != 3) {
                    this.setState({mainStatus: "Case Closed", subStatus: this.caseInfo.casesubstatus?.s_label});
                } else if (this.caseInfo.case_type == 2 && this.caseInfo.case_status == 3) {
                    this.setState({mainStatus: this.caseInfo.resolution.rc_name, subStatus: 'In Progress'});
                } else if (this.caseInfo.case_type == 2 && this.caseInfo.case_status != 3) {
                    this.setState({mainStatus: 'Case Closed', subStatus: this.caseInfo.casesubstatus.s_label});
                } else if (this.caseInfo.case_type == 3 && this.caseInfo.case_status == 3) {
                    this.setState({mainStatus: this.caseInfo.resolution.rc_name, subStatus: 'In Progress'});
                } else if (this.caseInfo.case_type == 3 && this.caseInfo.case_status != 3) {
                    this.setState({mainStatus: 'Case Closed', subStatus: this.caseInfo.casesubstatus.s_label});
                }
            }
        });
    }

    callback(key) {
        //console.log(key);
    }

    getCaseRejectReason() {
    	let rejectTypes = [];
        apiService.AuthGetCommonReason({
            module_name:this.state.module,
            type:this.state.reasonType
        })
        .then(res => {
            if(res.status == 'success') {
                res.data.forEach(e => {
                    rejectTypes.push(e.reason);
                });
            }
        });
        this.setState({rejectTypes: rejectTypes});
	}

    showRejectDrawer = () => {
        this.setState({rejectVisible: true});
    };

    onClose = () => {
        this.setState({rejectVisible: false, rejectReason: '', rejectComments: '', showSuccess: false});
    };

    handleRejectReason(reason) {
        this.setState({rejectReason: reason});
	}

    handleRejectComments = (e) =>  {
        this.setState({rejectComments: e.target.value});
    }

    acceptCase() {
        if (this.type == 0) {
            this.history.push(`/auth/setupaccount/${this.token}`);
        } else if (this.caseId && this.type == 1) {
            this.setState({loading: true});
            apiService.acceptCase({
                case_id: this.caseId,
                in_id: this.token
            })
            .then(res => {
                this.setState({loading: false});
                if (res.status == 'success') {
                    message.success(res.message);
                    websocketService.send({
                        method: 'case',
                        isDisplay: true,
                        data: {
                            users: res.userIds,
                            content: 'accepted the invitation to participate in the case.',
                            case_name: this.state.caseInfo.case_name,
                            customCase: true,
                            case_id: res.case_id,
                            type: 'accept-case'
                        }
                    });
                    this.props.closeCaseResponseVisible({
                        status: 'success',
                        token : res.token
                    });
                } else {
                    message.error(res.message);
                }
            }, () => {
                this.setState({loading: false});
                message.error('Unable to process data.');
            });
        }
    }

    rejectCase() {
        this.setState({loading: true});
        if(this.caseId && this.type == 0) {
            apiService.inviteUserRejectCase({
                reject_reason: this.state.rejectReason,
                reject_comments:this.state.rejectComments,
                case_id: this.caseId,
                in_id: this.token,
            })
            .then(res => {
                this.setState({loading: false});
                if (res.status == 'success') {
                    this.getCaseDetails();
                    message.success(res.message);
                    this.setState({showSuccess: true});
                    websocketService.send({
                        method: 'case',
                        isDisplay: true,
                        data: {
                            users: res.userIds,
                            content: 'rejected the invitation to participate in the case.',
                            case_name: this.state.caseInfo.case_name,
                            customCase: true,
                            customFrom: true,
                            from: res?.from,
                            case_id: this.caseId,
                            type: 'reject-case'
                        }
                    });
                    setTimeout(() => {
                        this.props.history.push('/auth/login');
                    }, 30000);
                } else {
                    message.error(res.message);
                }
            });
        } else if(this.caseId && this.type == 1) {
            apiService.rejectCase({
                reject_reason: this.state.rejectReason,
                reject_comments:this.state.rejectComments,
                case_id: this.caseId,
                in_id: this.token
            })
            .then(res => {
                this.setState({loading: false});
                if (res.status == 'success') {
                    this.setState({showSuccess: true});
                    this.getCasesInfo(); 
                    websocketService.send({
                        method: 'case',
                        isDisplay: true,
                        data: {
                            users: res.userIds,
                            content: 'rejected the invitation to participate in the case.',
                            case_name: this.state.caseInfo.case_name,
                            customCase: true,
                            case_id: this.caseId,
                            type: 'reject-case'
                        }
                    });
                } else {
                    message.error(res.message);
                }
            });
        }
    }

    getCaseDetails() {
        if (this.caseId && this.type == 0) {
            apiService.inviteCaseInfo({
                invite_token: this.token,
                case_id: this.caseId
            })
            .then(res => {
                if (res.status == 'success') {
                    this.setState({caseInfo: res.data, mainStatus: 'Case Creation', subStatus: 'Completed'});
                    this.caseInfo = res.data;
                    if (this.caseInfo.case_status == 1) {
                        this.setState({mainStatus: 'Case Invitations', subStatus: 'Awaiting Response'});
                    } else if (this.caseInfo.case_status == 2) {
                        this.setState({mainStatus: 'Case Invitations', subStatus: 'Awaiting Admin allocation'});
                    } else if (this.caseInfo.case_type == 1 && this.caseInfo.case_status == 3) {
                        this.setState({mainStatus: this.caseInfo.resolution.rc_name, subStatus: 'In Progress'});
                    } else if (this.caseInfo.case_type == 1 && this.caseInfo.case_status != 3) {
                        this.setState({mainStatus: "Case Closed", subStatus: this.caseInfo.casesubstatus.s_label});
                    } else if (this.caseInfo.case_type == 2 && this.caseInfo.case_status == 3) {
                        this.setState({mainStatus: this.caseInfo.resolution.rc_name, subStatus: 'In Progress'});
                    } else if (this.caseInfo.case_type == 2 && this.caseInfo.case_status != 3) {
                        this.setState({mainStatus: 'Case Closed', subStatus: this.caseInfo.casesubstatus.s_label});
                    } else if (this.caseInfo.case_type == 3 && this.caseInfo.case_status == 3) {
                        this.setState({mainStatus: this.caseInfo.resolution.rc_name, subStatus: 'In Progress'});
                    } else if (this.caseInfo.case_type == 3 && this.caseInfo.case_status != 3) {
                        this.setState({mainStatus: 'Case Closed', subStatus: this.caseInfo.casesubstatus.s_label});
                    }
                }
            });
        } else if (this.caseId && this.type == 1) {
            this.getCasesInfo();
        }
    }

    render () {
        const { loading, caseInfo, showSuccess, rejectVisible, rejectTypes, rejectReason, rejectComments } = this.state;

        return (
            
            <div>
                <Spin spinning={loading} delay={500}>
                    {   (caseInfo && !showSuccess) &&
                        (
                            <div className="case-response-details">

                                {   (caseInfo.caseinviteuserstatus && caseInfo.caseinviteuserstatus.invite_status == 2) &&
                                    (
                                        <Collapse
                                            className="case-rejected-response"
                                            defaultActiveKey={["1"]}
                                            onChange={this.callback}
                                            expandIconPosition={"right"}
                                        >
                                            <Panel header={"Rejected On " + caseInfo.caseinviteuserstatus.respond_time.utcLocal().dateTimeFormat() } key="1">
                                                <Col className="mb16">
                                                    <Row span={12} className="rowheading">
                                                        Reason
                                                    </Row>
                                                    <Row span={12} className="rowcontent">
                                                        { caseInfo.caseinviteuserstatus.reject_reason }
                                                    </Row>
                                                </Col>

                                                <Col className="mb16">
                                                    <Row span={12} className="rowheading">
                                                        Comments
                                                    </Row>
                                                    <Row span={12} className="rowcontent">
                                                        { caseInfo.caseinviteuserstatus.reject_comments }
                                                    </Row>
                                                </Col>
                                            </Panel>
                                        </Collapse>
                                    )
                                }

                                <Collapse
                                    className="case-response"
                                    defaultActiveKey={["1"]}
                                    onChange={this.callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header="Case Information" key="1">
                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Case Name
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.case_name }
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                { this.siteSetting?.site_name } Case ID
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.case_ref_no }
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Type
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.casecategory?.c_name }
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Date(s) Complained
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                Not Available
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Date Problem Occured
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                Not Available
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Salesperson
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                Not Available
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Purchase Date
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                None Provided
                                            </Row>
                                        </Col>
                                    </Panel>
                                </Collapse>

                                <Collapse
                                    className="case-response"
                                    onChange={this.callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header={(this.roles && this.roles.Claimant ? this.roles.Claimant: '') + " Information"} key="2">
                                        <Col className="mb8">
                                            <Row span={12} className="rowheading">
                                                Name
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.mainClaimant?.name }
                                            </Row>
                                        </Col>
                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Phone
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.mainClaimant?.phone }
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Email
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.mainClaimant?.email }
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Address
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.mainClaimant?.address }
                                            </Row>
                                        </Col>
                                    </Panel>
                                </Collapse>

                                <Collapse
                                    className="case-response"
                                    onChange={this.callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header={(this.roles && this.roles.Respondent ? this.roles.Respondent: '') + " Information"} key="3">
                                        <Col className="mb8">
                                            <Row span={12} className="rowheading">
                                                Business Name
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.mainRespondent?.name }
                                            </Row>
                                        </Col>
                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Phone
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.mainRespondent?.phone }
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Email
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.mainRespondent?.email }
                                            </Row>
                                        </Col>

                                        <Col className="mb16">
                                            <Row span={12} className="rowheading">
                                                Address
                                            </Row>
                                            <Row span={12} className="rowcontent">
                                                { caseInfo.mainRespondent?.address }
                                            </Row>
                                        </Col>
                                    </Panel>
                                </Collapse>

                                <Collapse
                                    className="case-response"
                                    onChange={this.callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header="Desired Resolution Description" key="4">
                                        <Col className="mb16">
                                            <Row span={12} className="rowcontent">
                                                <p dangerouslySetInnerHTML={{ __html: caseInfo.resolution?.rc_description }}></p>
                                            </Row>
                                        </Col>
                                    </Panel>
                                </Collapse>

                                <Collapse
                                    className="case-response"
                                    onChange={this.callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header="Case Description" key="5">
                                        <Col className="mb16">
                                            <Row span={12} className="rowcontent">
                                                <p dangerouslySetInnerHTML={{ __html: caseInfo.case_description }}></p>
                                            </Row>
                                        </Col>
                                    </Panel>
                                </Collapse>
                            </div>
                        )
                    }

                    {   (caseInfo.caseinviteuserstatus && caseInfo.caseinviteuserstatus.invite_status != 2) &&
                        (
                            <Row className="all-view-footer case-response-footer">
                                <Col span={12}>
                                    <Button type="primary" className="cancel" disabled={!caseInfo} onClick={this.showRejectDrawer}>Reject</Button>
                                </Col>
                                <Col span={12}>
                                    <Button type="primary" className="complete" disabled={!caseInfo} onClick={() => this.acceptCase()}>Accept</Button>
                                </Col>
                            </Row>
                        )
                    }
                </Spin>
                
                <Drawer
                title="Reason For Rejection"
                className="custom-drawer-popup reject-reason-drawer"
                id="reject-reason-drawer"
                placement="top"
                forceRender={true}
                height={"100%"}
                onClose={this.onClose}
                visible={rejectVisible}
                >
                    {   !showSuccess &&
                        (
                            <>
                                <div className="reject-body">
                                    <Spin spinning={loading} delay={500}>
                                        <Row className="mb16">
                                            <Col span={24}>
                                                <p className="mb8">Why you would like to reject this case?</p>
                                            </Col>
                                            <Col span={24}>
                                                <Select defaultValue={rejectReason != '' ? rejectReason: 'Select a reason'} name="reject-reason" id="reject-reason" style={{ width: "100%" }} onChange={(e) => this.handleRejectReason(e)}>
                                                    { rejectTypes && rejectTypes.length > 0 
                                                        ? rejectTypes.map((type, index) => (
                                                            <React.Fragment key={[index]}>
                                                                <Option value={type}>{type}</Option>
                                                            </React.Fragment>
                                                        ))
                                                        :
                                                            <></>
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>

                                        <Row className="mb16">
                                            <Col span={24}>
                                                <p className="mb8">Comment (optional)</p>
                                            </Col>
                                            <Col span={24}>
                                                <TextArea name="reject-comment" id="reject-comment" rows={6} value={rejectComments} onChange={this.handleRejectComments} maxLength={1000} />
                                            </Col>
                                        </Row>

                                        <Row className="mb16">
                                            <Col span={24}>
                                                <p className="mb8">What happens if I reject the case invite?</p>
                                            </Col>
                                            <Col span={24}>
                                                <span>Your rejection and the reason mentioned will be recorded by our Platform. You will not be able to participate in the resolution of this case.</span>
                                            </Col>
                                        </Row>
                                    </Spin>
                                </div>

                                <Row className="all-view-footer reject-reason-footer">
                                    <Col span={12}>
                                        <Button type="primary" className="cancel" onClick={this.onClose}>Cancel</Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button type="primary" className="complete" disabled={!rejectReason} onClick={() => this.rejectCase()}>Reject</Button>
                                    </Col>
                                </Row>
                            </>
                        )
                    }

                    {   showSuccess &&
                        (
                            <div className="reject-response">
                                <Row className="reject-response-middle">
                                    <Col>
                                        <RejectCaseIcon />
                                    </Col>
                                </Row>
                                <Row className="reject-response-middle">
                                    <Col>
                                        <p className="response-title">Thanks for your Response</p>
                                    </Col>
                                </Row>
                                <Row className="reject-response-left">
                                    <Col>
                                        <p className="title">Reject Reason :<span className="content">{rejectReason}</span></p>
                                        <p className="title">Comments :<span className="content"><p>{rejectComments}</p></span></p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Drawer>
            </div>
        );
    }
}

export default CommonCaseResponse;