import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Select,
  Menu,
  Upload,
  Modal,
  message
} from "antd";
import Icon from "@ant-design/icons";
import apiService from "../../../logics/services/api.service";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import userService from "../../../logics/services/user.service";
import PhoneInput from "react-phone-input-2";
import "./styles.less";
import closeSVG from "../../../assets/svg-icons/close.svg";
import { decodeToken } from "react-jwt";
import startsWith from 'lodash.startswith';

const { Option } = Select;
const { TextArea } = Input;
const CloseIcon = (props) => (
  <Icon
    component={closeSVG}
    style={{ fontSize: 24, verticalAlign: "middle" }}
    {...props}
  />
);

function MyAccount() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [countryDetails, setCountryDetails] = useState([]);
    const [stateDetails, setStateDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isBioShow, seIsBioShow] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const user = useSelector(state => state.user);//userService.getUser();

    useEffect(() => {
        if (user) {
            getUserDetails();
            getCountryList();
            const rolePermissions = user?.permissions;
            if (rolePermissions && rolePermissions['My Profile - Bio Update']) {
                seIsBioShow(true);
            }
        }
    }, [dispatch]);

    const getUserDetails = () => {
        let address1 = '', address2 = '';
        if (user?.address) {
            const address = user?.address.split(' ; ');
            address1 = address[0];
            address2 = address[1];
        }
        form.setFieldsValue({
            Firstname: user?.first_name,
            Lastname: user?.last_name,
            email: user?.email,
            recoveryemail: user?.alt_email,
            Phone: user?.phone,
            Address1: address1,
            Address2: address2,
            OrgName: user?.org_name,
            bio: user?.bio,
            country: user?.country,
            state: user?.state,
            zipcode: user?.zipcode
        });
        if (user?.country) {
            getState(user?.country);
        }
    }

    const getCountryList = () => {
        apiService.getCountryList()
            .then((res) => {
                if (res.status == 'success') {
                    setCountryDetails(res.data);
                }
            });
    }

    const getState = (country) => {
        apiService.getStateList({
                country: country    
            }).then((res) => {
                if (res.status == 'success') {
                    setStateDetails(res.data);
                }
            });
    }
    
    const fvalid = (fieldsValue) => {
        let nameReg = new RegExp(/^[a-zA-Z0-9#/, \-\']+$/); //(/^[a-zA-Z \-\']+$/);
        let addressReg = new RegExp(/^[a-zA-Z0-9#/, \-\']+$/);
        let emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z ]{2,10}$/);
        if (!fieldsValue['Firstname'] || !nameReg.test(fieldsValue['Firstname'])) {
            return true;
        } else if (!fieldsValue['Lastname'] || !nameReg.test(fieldsValue['Lastname'])) {
            return true;
        } else if (fieldsValue['recoveryemail'] && !emailReg.test(fieldsValue['recoveryemail'])) {
            return true;
        } else if (fieldsValue['Address1'] && !addressReg.test(fieldsValue['Address1'])) {
            return true;
        } else if (fieldsValue['Address2'] && !addressReg.test(fieldsValue['Address2'])) {
            return true;
        } else if (fieldsValue['OrgName'] && !nameReg.test(fieldsValue['OrgName'])) {
            return true;
        }
        return false;
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        getUserDetails();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onSubmit = (fieldsValue) => {
        const valid = fvalid(fieldsValue);
        if (valid) {
            message.error('Please enter valid data.')
            return;
        }
        // console.log({
        //     first_name: fieldsValue["Firstname"],
        //     last_name: fieldsValue["Lastname"],
        //     alt_email: fieldsValue["recoveryemail"],
        //     phone: fieldsValue['Phone'],
        //     address1: fieldsValue["Address1"],
        //     address2: fieldsValue["Address2"],
        //     country: fieldsValue['country'],
        //     state: fieldsValue['state'],
        //     zipcode: fieldsValue['zipcode'],
        //     bio: fieldsValue['bio'],
        //     org_name: fieldsValue['OrgName']
        // });
        setIsLoading(true);
        apiService
            .updateprofile({
                first_name: fieldsValue["Firstname"],
                last_name: fieldsValue["Lastname"],
                alt_email: fieldsValue["recoveryemail"],
                phone: fieldsValue['Phone'],
                address1: fieldsValue["Address1"],
                address2: fieldsValue["Address2"],
                country: fieldsValue['country'],
                state: fieldsValue['state'],
                zipcode: fieldsValue['zipcode'],
                bio: fieldsValue['bio'],
                org_name: fieldsValue['OrgName']
            })
            .then((res) => {
                setIsLoading(false);
                if (res.status === "success") {
                    userService.setUserToken(res.token);
                    dispatch({type: 'user', value: decodeToken(res.token)});
                    message.success(res.message);
                } else {
                    message.error(res.message)
                }
            });
    };

    const onClose = () => {
        let prev = localStorage.getItem("previousUrl")
        ? localStorage.getItem("previousUrl")
        : "app/allcases";
        history.push(prev);
    };

    const handleClick = (e) => {
        if (e.key === "calendar") {
            history.push("/app/account/calendar");
        } else if (e.key === "payment") {
            history.push("/app/account/payment");
        }
    };

    const beforeUpload = (file) => {
        let isImage = { pattern: /[\/.](jpe?g|gif|bmp|png|svg|tiff?)$/i };
        const isvalid = isImage.pattern.test(file.type);
        if (!isvalid) {
            message.error('You can only upload image file');
            return false;
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('Image must be smaller than 10MB');
            return false;
        }
        const myReader = new FileReader();
        myReader.readAsDataURL(file);
        myReader.onload = (e) => {
            let image = new Image();
            image.src = e.target.result;
            image.onload = (rs) => {
                const height = rs.currentTarget['height'];
                const width =  rs.currentTarget['width'];
                if (height > 512 || width > 512) {
                    message.error('Height and Width must not exceed 512px');
                    return false;
                }
            }
        }
    }

    const profileUpload = (file) => {
        const formData = new FormData();
        formData.append('avatar', file.file);
        apiService.uploadProfilePhoto(formData)
            .then((res) => {
                if (res.status == 'success') {
                    userService.setUserToken(res.token);
                    dispatch({type: 'user', value: decodeToken(res.token)});
                } 
            });
    }

    const removeProfilePhoto = () => {
        apiService.removeProfilePhoto({
                user_image: user.image
            }).then((res) => {
                if (res.status == 'success') {
                    userService.setUserToken(res.token);
                    dispatch({type: 'user', value: decodeToken(res.token)});
                }
            });
    }

    return (
        <div className="profile-section">
            <Row className="header">
                <Col className="textCenter">
                    <CloseIcon onClick={onClose} />
                </Col>
                <Col span={22} className="textCenter accountheading">
                    My Account
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                <Menu
                    mode="horizontal"
                    onClick={handleClick}
                    className="p-menu"
                    defaultSelectedKeys={["myprofile"]}
                >
                    <Menu.Item
                        key="myprofile"
                        style={{ width: "33%", textAlign: "center" }}
                    >
                        My Profile
                    </Menu.Item>
                    <Menu.Item
                        key="calendar"
                        style={{ width: "33%", textAlign: "center" }}
                    >
                        Calendar
                    </Menu.Item>
                    <Menu.Item
                        key="payment"
                        style={{ width: "33%", textAlign: "center" }}
                    >
                        Payment
                    </Menu.Item>
                </Menu>
                </Col>
            </Row>
            <div className="profile-inner-section">
                <Row>
                    <Col className="p16">
                        <Avatar
                            style={{ width: 64, height: 64, lineHeight: "64px" }}
                            src={user?.image}
                        >
                            {user?.p_name}
                        </Avatar>
                    </Col>
                    <Col className="p16">
                        <div className="profile-change">
                            <Upload name="logo" showUploadList={false} beforeUpload={(file) => beforeUpload(file)} customRequest={profileUpload}>
                                <Button className="change" type="primary">
                                    Change
                                </Button>
                            </Upload>
                            <Button className="remove" disabled={!(user?.image)} onClick={removeProfilePhoto}>Remove</Button>
                        </div>
                        <p className="img-size">Image size should be 512px X 512 px</p>
                    </Col>
                </Row>
                <Row>
                <Col className="p16" span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onSubmit}
                        className="my-account"
                    >
                        <Form.Item
                            label="First Name"
                            className="w100 mb12"
                            name="Firstname"
                        >
                            <Input
                                showCount
                                maxLength={32}
                                placeholder="First Name"
                                name="Firstname"
                                value={user?.first_name}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="Lastname"
                            className="w100 mb12"
                        >
                            <Input name="Lastname" showCount maxLength={32} placeholder="Last Name" />
                        </Form.Item>
                        { user?.org_name && 
                            <Form.Item
                                label="Org Name"
                                name="OrgName"
                                className="w100 mb12"
                            >
                                <Input name="OrgName" showCount maxLength={100} placeholder="Org Name" />
                            </Form.Item>
                        }
                        <Form.Item
                            label={<div>Mobile Number <span style={{fontWeight: 400}}>(Optional)</span></div>}
                            className="w100 mb12"
                            name="Phone"
                        >
                            <PhoneInput
                                className="react-tel-input w100"
                                name="Phone"
                                country={"us"} 
                                enableSearch
                                value={user?.phone}
                                style={{ width: "-webkit-fill-available" }}
                                isValid={(inputNumber, country, countries) => {
                                    return countries.some((country) => {
                                      return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Email Address" className="w100 mb12">
                            <Input
                                placeholder="Enter email"
                                value={user?.email}
                                name="email"
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            label="Recovery Email Address"
                            name="recoveryemail"
                            className="w100 mb12"
                        >
                            <Input
                                placeholder="Enter Recovery email"
                                name="recoveryemail"
                            />
                        </Form.Item>
                        { isBioShow && <Form.Item name="bio" label={<div>Bio <span style={{fontWeight: 400}}>(Optional)</span></div>}>
                            <TextArea name="bio" placeholder="Enter your bio" rows={3} showCount maxLength={1000} />
                        </Form.Item>}
                        <Form.Item
                            label={<div>Mailing Address <span style={{fontWeight: 400}}>(Optional)</span></div>}
                            className="w100 mb16"
                            name="Address1"
                        >
                            <Input
                                placeholder="Address 1"
                                name="Address1"
                                showCount
                                maxLength={120}
                            />
                        </Form.Item>
                        <Form.Item
                            className="w100 mb16"
                            name="Address2"
                        >
                            <Input
                                placeholder="Address 2"
                                name="Address2"
                                maxLength={120}
                            />
                        </Form.Item>
                        <Form.Item
                            className="w100 mb16"
                            name="country"
                        >
                            <Select fieldNames="country" showSearch optionFilterProp="label" placeholder="Select Country" size="large" onChange={getState}>
                               { countryDetails && countryDetails.map((list, key) => (
                                    <Option value={list.country_code} key={"country_"+key} label={list.country_name}>
                                        {list.country_name}
                                    </Option>
                               ))} 
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className="w100 mb16"
                            name="state"
                        >
                            <Select fieldNames="state" showSearch optionFilterProp="label" placeholder="Select State" size="large">
                                { stateDetails && stateDetails.map((list, key) => (
                                    <Option value={list.state_code} key={"state_"+key} label={list.state_name}>
                                        {list.state_name}
                                    </Option>
                               ))} 
                            </Select>
                        </Form.Item>                        
                        <Form.Item
                            className="w50"
                            name="zipcode"
                        >
                            <Input name="zipcode" placeholder="Zip code" maxLength={20} />
                        </Form.Item>
                    </Form>
                </Col>
                </Row>
            </div>
            <div className="p-footer">
                <Row gutter={32} style={{ margin: 0 }} justify="space-between">
                    <Col span={12}>
                        <Button
                            type="default"
                            size="large"
                            className="w100"
                            onClick={showModal}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            size="large"
                            className="w100"
                            loading={isLoading}
                            disabled={isLoading || (user && fvalid(form.getFieldsValue()))}
                            onClick={() => form.submit()}
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </div>
            <Modal
                title="Cancel Changes"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={
                    <div className="p-footer" style={{ margin: 0}}>            
                        <Row
                        gutter={32}
                        style={{ margin: 0 }}
                        justify="space-between"
                        >
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    size="large"
                                    className="w100"
                                    onClick={() => handleOk()}
                                >
                                    Yes
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    size="large"
                                    className="cancelbutton"
                                    type="default"
                                    onClick={()=> handleCancel()}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
            >
                <div style={{justify:'center'}}>Changes will be lost. Are you sure?</div>
            </Modal>
        </div>
    );
}
export default MyAccount;
