import React, { useState, useEffect } from "react";
import {
    useParams,
    useHistory
} from "react-router-dom";
import { Row, Col, Card, Avatar, Popover, Tag } from 'antd';
import Icon from '@ant-design/icons';
import { useSelector } from 'react-redux';
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import "../../../logics/prototypes";
import { websocketService, socket } from "../../../logics/services/websocket.service";
import Escalate from "./Escalate";
import NoNotificationSvg from  '../../../assets/svg-icons/no-notifications.svg';
import NoMessageSvg from  '../../../assets/svg-icons/no-messages.svg';
import NoTaskEventSvg from  '../../../assets/svg-icons/no-task-event.svg';
import EmptySvg from "../../../assets/svg-icons/empty_outcome.svg";

const NoNotificationIcon = props => <Icon component={NoNotificationSvg} style={{ fontSize: 80, verticalAlign: 'middle'}} {...props} />;
const NoMessageIcon = props => <Icon component={NoMessageSvg} style={{ fontSize: 80, verticalAlign: 'middle'}} {...props} />;
const NoTaskEventIcon = props => <Icon component={NoTaskEventSvg} style={{ fontSize: 80, verticalAlign: 'middle'}} {...props} />;
const EmptyIcon = props => <Icon component={EmptySvg} style={{ fontSize: 140, verticalAlign: 'middle'}} {...props} />;

const tabList = [
    {
      key: 'tab1',
      tab: 'All'
    },
    {
      key: 'tab2',
      tab: 'Due Today'
    },
    {
      key: 'tab3',
      tab: 'Overdue'
    },
];

function Overview() {
    //const caseInfo = useSelector(state => state.data);
    let { caseId } = useParams();
    let user = userService.getUser();
    const history = useHistory();
    let roleLabel = user.role_label;
    const [activeTabKey1, setActiveTabKey1] = useState('tab1');
    let taskType='all';
    const onTab1Change = key => {
        setActiveTabKey1(key);
        if(key=='tab1'){
            taskType='all';
        }else if(key=='tab2'){
            taskType= 'duetoday'
        }else if(key=='tab3'){
            taskType= 'overdue'
        }
        fetchAppoinmentData();
    };
    
    const [caseInfo, setCaseInfo] = useState([]);
    const [notificationList, setNotificationList] = useState([]);
    const [notificationLength, setNotificationLength] = useState(0);
    const [messageList, setMessageList] = useState([]);
    const [messageLength, setMessageLength] = useState(0);
    const [appointmentList, seAppointmentList] = useState([]);
    const [appointmentLength, setAppointmentLength] = useState(0);
    const [escalation, setEscalation] = useState([]);
    const [escalationLength, setEscalationLength] = useState(0);
    const [escalateRequestVisible, setEscalateRequestVisible] = useState(false);
    const [escalateRequest, setEscalateRequest] = useState([]);
    const fetchData = () => {
        apiService.getCaseUsersNotificationsList({
            case_id: caseId
        }).then((res) => {
            if (res.status === 'success') {
                setNotificationList(notificationList.concat(res.data.notification.updates));
                setNotificationLength(res.data.notification.updates.length);
                setMessageList(messageList.concat(res.data.notification.chats));
                setMessageLength(res.data.notification.chats.length);
            }
        });
    }

    const todoContent = (image,pname,name,role) => {
        return (
            <div className="popover-member-detail">
                <Avatar src={image}>{pname}</Avatar>
                <div className="name-role">
                    <p className="name">{name}</p>
                    <p className="role">{role}</p>
                </div>
            </div>
        );
    }

    const msgContent = (msgitem) => {
        return (
            <div className="popover-member-detail">
                <Avatar src={msgitem.creator.user_image}>{msgitem.creator.p_name}</Avatar>
                <div className="name-role">
                    <p className="name">{msgitem.creator.name}</p>
                    <p className="role">{msgitem.caseUserType.role_name}</p>
                </div>
            </div>
        );
    }

    const notifyContent = (notifyitem) => {
        return (
            <div className="popover-member-detail">
                <Avatar src={notifyitem.creator.user_image}>{notifyitem.creator.p_name}</Avatar>
                <div className="name-role">
                    <p className="name">{notifyitem.creator.name}</p>
                    <p className="role">{notifyitem.caseUserType.role_name}</p>
                </div>
            </div>
        );
    }
    
    const fetchAppoinmentData = () => {
        apiService.getAppointCalender({
            case_id: caseId,
            type: taskType
        }).then((res) => {
            if (res.status === 'success') {
                seAppointmentList(res.data);
                setAppointmentLength(res.data.length);
            }
        });
    }

    const getCaseInfo = () => {
        apiService.getCaseInfo({
            case_id: caseId,
        }).then((res) => {
            if (res.status === "success") {
                setCaseInfo(res.data);
                setEscalation(res.data.escalation);
                setEscalationLength(res.data.escalation.length);
            }
        });
   }

    const showEscalate = (item) => {
        setEscalateRequest(item);
        setEscalateRequestVisible(true);
    };

   const closeEscalate = (val) => {
        setEscalateRequestVisible(val);
        getCaseInfo();
    }

    const navigateToPage = (data) => {
        let link;
        switch (data.type) {
            case 'task':
                link = {
                    link_id: data.tk_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/tasks/'+btoa(JSON.stringify(link)));
                break;
            case 'calendar':
                link = {
                    link_id: data.ap_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'keydate':
                link = {
                    link_id: data.ap_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'meeting':
                link = {
                    link_id: data.ap_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'proposal':
                link = {
                    link_id: data.ap_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/outcomes/'+btoa(JSON.stringify(link)));
                break; 
            case 'decision':
                link = {
                    link_id: data.ap_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/outcomes/'+btoa(JSON.stringify(link)));
                break;           
        }
   }

    const navigateToPageNotify = (data) => {
        let link;
        switch (data.type) {
            case 'chat':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/message/'+btoa(JSON.stringify(link)));
                break;
            case 'invite':
                link = {
                    link_id: data.in_id
                };
                if (data.read === 0) {
                    history.push('/app/caseresponse/'+data.in_id);
                } else {
                    history.push('/app/case/'+(data.case_case_id)+'/overview');
                }
                break;
            case 'task':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/tasks/'+btoa(JSON.stringify(link)));
                break;
            case 'calendar':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'keydate':
                link = {
                    link_id: data.link_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'settlement':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/outcomes/'+btoa(JSON.stringify(link)));
                break;
            case 'decision':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/outcomes/'+btoa(JSON.stringify(link)));
                break;
            default:
                history.push('/app/case/'+(data.case_case_id)+'/overview');
                break;
        }
    }

    const [rerender, setRerender] = useState(false);
    useEffect(() => {
        getCaseInfo();
        fetchData();
        fetchAppoinmentData();
        socket.onmessage = resData => {
            let res = (resData && resData.data) ? JSON.parse(resData.data): [];
            if (res && (res.method == 'escalate')) { 
                const data = res.data;
                if ((data.type == 'escalation-request' || data.type == 'escalation-accept' || data.type == 'escalation-reject') && data.case_id == caseId && data.users && data.users.indexOf(user.id) != -1) {
                    getCaseInfo();
                }
            }
        }
        setRerender(!rerender);
    }, []);

    return (
        <div>
            <Row className="page-header">
                <Col span={24}>
                    Overview
                </Col>
            </Row>
            <Row className="p16 overview-todo-cards">
                <Col span={24}>
                    { escalationLength > 0 
                        ? escalation.map((item, index) => ( 
                            <div key={index}>
                                {   (caseInfo.case_status == 3 || caseInfo.case_status == 2) && item.eStatus && item.eStatus.status == 0 && item.status !=2
                                    ?
                                        <Card className="escalate-accept-reject mb8" title="Escalate Request" extra={roleLabel != 'Case Officer' && (<span onClick={() => showEscalate(item)}>Accept / Decline</span>)}>
                                            <Row className="escalate-detail">
                                                <Col span={15} className="escalate-by-container">
                                                   <Row>
                                                        <Col className="escalate-by mb8">Escalated by</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={5}><Avatar src={item.requested_by.user_image}>{item.requested_by.p_name}</Avatar></Col>
                                                        <Col span={12}>
                                                            <Row className="escalate-name">{item.requested_by.name}</Row>
                                                            <Row className="escalate-by-role">{item.requested_by.role_name}</Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={9} className="escalate-to-container">
                                                    <Row>
                                                        <Col className="escalate-to mb8">Escalate to</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="escalate-role">{item.case_type_name}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    :
                                        <></>
                                }
                           </div>
                        ))
                        :
                       <></>
                    }

                    { escalateRequestVisible 
                        ?
                            <Escalate caseId={caseId} visible={false} escalateRequestVisible={escalateRequestVisible} escalationRequest={escalateRequest} closeEscalateVisible={closeEscalate}/>
                        :
                            <></>
                    }

                    <Card 
                        bordered={false}
                        className="w100"
                        title="To Do"
                        tabList={tabList}
                        activeTabKey={activeTabKey1}
                        onTabChange={key => {
                            onTab1Change(key);
                        }}
                        //extra={<a href={location.origin + "/app/case/" + caseId + "/tasks"}>View all</a>}
                    >
                        { appointmentLength > 0 
                            ? appointmentList.map((item, index) => (
                                <Card className={"todo-card mb8 " + (item.type=='questionnaire' ? 'quest ' : '') + (item.overdue ? 'overdue' : '')} key={index} onClick={() => (item.type=='task') && navigateToPage(item)}>
                                    <Row className="todo-title">
                                        <Col>
                                            <Popover placement="bottomLeft" content={todoContent(item.task_details.user_image,item.task_details.p_name,item.task_details.name,item.caseUserType.role_name)}>
                                                <Avatar src={item.task_details.user_image}>{item.task_details.p_name}</Avatar>
                                            </Popover>
                                        </Col>
                                        
                                        <Col className="to-do-appoinment-title" dangerouslySetInnerHTML={{ __html: item.title }}></Col>
                                    </Row>
                                    <Row className="middle-list">
                                        <Col span={12}>Created By: { item.task_details.name }</Col>
                                        <Col span={12} className="textRight">{(item.type == 'proposal' || item.type == 'decision') ? "Created On:": "Due On:"} {item.created_at.utcLocal().dateTimeFormat()}</Col>
                                    </Row>
                                    <Row className={"bottom-list " + (item.type=='questionnaire' ? 'quest ' : '')}>
                                        <Col span={item.overdue ? 12 : 24 } className="left-list">{ item.type == 'keydate'?'Key Date': item.type.substr(0,1).toUpperCase()+item.type.substr(1) }</Col>
                                        {   item.overdue &&
                                            (<Col span={12} className="textRight">
                                                <Tag color="#FF4D4F ">
                                                    Overdue
                                                </Tag>
                                            </Col>)
                                        }
                                    </Row>
                                </Card>
                            ))
                            :
                            <div className="todo-task-questionnaire-nocontent mb8">
                                <p className="todo-task-questionnaire-icon">
                                    <EmptyIcon/>
                                </p>
                                <p className="todo-no-yet">
                                    No Tasks, Outcomes, Meetings or Key Dates yet!
                                </p>
                                {/* <p className="todo-no-yet">
                                    No Tasks, Meetings or Key Dates yet!
                                </p>
                                <p className="todo-no-content">
                                    This section will display the Meetings / Key Dates / Tasks associated with this case.
                                </p> */}
                            </div>
                        }
                    </Card>
                    
                    <Card className="todo-card mb8 message" title="Messages" extra={messageLength>5?<a href={location.origin + "/app/case/" + caseId + "/message"}>View all</a>:""}>
                        { messageLength > 0 
                            ? messageList.slice(0, 2).map((item, index) => (
                                <div className="todo-message" key={index} onClick={() => navigateToPageNotify(item)}>
                                    <Row className="todo-title">
                                        <Col>
                                            <Popover placement="bottomLeft" content={msgContent(item)}>
                                                <Avatar src={item.creator.user_image}>{item.creator.p_name}</Avatar>
                                            </Popover>
                                        </Col>
                                        <Col>
                                            <Row>{item.creator.name} ({ item.caseUserType.role_name }) { item.description }</Row>
                                            <Row className="todo-message-time">{item.created_at.utcLocal().dateTimeFormat()}</Row>
                                        </Col>                                
                                    </Row>
                                </div>
                            ))
                            :   
                            <div className="todo-message-nocontent">
                                <p className="todo-message-icon">
                                    <NoMessageIcon/>
                                </p>
                                <p className="todo-no-yet">
                                    No new messages yet!
                                </p>
                                <p className="todo-no-content">
                                    This section will display the messages related updates from other Participants in this case.
                                </p>
                            </div>
                        }
                    </Card>

                    <Card className="todo-card mb8 notification" title="Notification" extra={notificationLength>5?<a href={location.origin + "/app/case/" + caseId + "/activity"}>View all</a>:""}>
                        { notificationLength > 0 
                            ? notificationList.slice(0, 2).map((item, index) => (
                                <div className="todo-notification" key={index} onClick={() => navigateToPageNotify(item)}>
                                    <Row className="todo-title">
                                        <Col>
                                            <Popover placement="bottomLeft" content={notifyContent(item)}>
                                                <Avatar src={item.creator.user_image}>{item.creator.p_name}</Avatar>
                                            </Popover>
                                        </Col>
                                        <Col>
                                            <Row>{item.creator.name} ({item.caseUserType.role_name}) { item.description }</Row>
                                            <Row className="todo-notification-time">{item.created_at.utcLocal().dateTimeFormat()}</Row>
                                        </Col>                                
                                    </Row>
                                </div>
                            ))
                            :   
                            <div className="todo-notification-nocontent">
                                <p className="todo-notification-icon">
                                    <NoNotificationIcon/>
                                </p>
                                <p className="todo-no-yet">
                                    No new notifications yet!
                                </p>
                                <p className="todo-no-content">
                                    This section will display all the updates related to in this case.
                                </p>
                            </div>
                        }
                    </Card> 
                </Col>
            </Row>
        </div>
    );
}
export default Overview;