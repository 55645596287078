import React, { useState, useEffect } from "react";
import { Row, Col, Menu, Card, Button, Skeleton } from 'antd';
import Icon from '@ant-design/icons';
import { Link } from "react-router-dom";
import MyCaseSvg from  '../../assets/svg-icons/my_cases.svg';
import MyCaseActiveSvg from  '../../assets/svg-icons/my_cases_active.svg';
import ActivitySvg from  '../../assets/svg-icons/activity.svg';
import ActivityActiveSvg from  '../../assets/svg-icons/activity_active.svg';
import apiService from "../../logics/services/api.service";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import userService from "../../logics/services/user.service";

const MyCaseIcon = props => <Icon component={MyCaseSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const MyCaseActiveIcon = props => <Icon component={MyCaseActiveSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ActivityIcon = props => <Icon component={ActivitySvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ActivityActiveIcon = props => <Icon component={ActivityActiveSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;

function AllCases() {
    const history = useHistory();
    const [current, setState] = useState('mycases');
	const [totalCount, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const handleClick = e => {
        setState(e.key);
        if (e.key === 'allactivity') {
            history.push('/app/allcases-activity');
        }
    };
    const loaderList = [1,2,3];
    const [casesList, setCaseList] = useState([]);
    const fetchMoreData = () => {
        setHasMore(true);
        const user = userService.getUser();
        let type = 'mycase';
        let rolePermissions = user.permissions;
        if (rolePermissions['All Cases Tab']) {
            type = 'all';
        } else {
            type = 'mycase';
        }
        apiService.getAllCasesList({
            type: type,
            page: page,
            offset: offset,
            response_type: 'json',
            role_sorting: user.role_label,
            results: 10
        }).then((res) => {
            if (res.status === 'success') {
                setCount(res.count);
                setPage(page+1);
                setOffset(offset + 10);
                setCaseList(casesList.concat(res.data));
                var nextOffset = ((page) * 10);
                if (res.count <= nextOffset) {  
                    setHasMore(false);
                }
            }
        });
    }
    useEffect(() => {
        fetchMoreData();
    }, []);
    return (
        <div>
            <Row className="home-header">
                <Col span={12}>
                    <Link to={`/app/allcases`}>Home</Link>
                </Col>
            </Row>
            <Row className="mt8">
                <Col span={24}>
                    <Menu className="top-menu" onClick={handleClick} selectedKeys={[current]} mode="horizontal">
                        <Menu.Item key="mycases" style={{ width: '49.5%' }} icon={(current === 'mycases'? <MyCaseActiveIcon /> : <MyCaseIcon />)}>
                            My Cases
                        </Menu.Item>
                        <Menu.Item key="allactivity" style={{ width: '49.5%' }} icon={(current === 'allactivity'? <ActivityActiveIcon /> : <ActivityIcon />)}>
                            All Activity
                        </Menu.Item>
                    </Menu>
                </Col>
            </Row>
            <Row className="p16 caseCards">
                <Col span={24}>
                <InfiniteScroll
                    dataLength={casesList.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={loaderList.map((item, index) => (<Card key={index}>
                                <Skeleton active title={false} paragraph={{rows:4}} className="p8" />
                            </Card>))}
                    >
                        {casesList.map((item, index) => (  
                            <Card key={index}>
                                <Row className="card-heading">
                                    <Col span={12}>Case Name</Col>
                                    <Col span={12} className="textRight">Stage</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><Link to={`/app/case/${item.case_id}/overview`}>{item.case_name}</Link></Col>
                                    <Col span={12} className="textRight">{item.case_stage}</Col>
                                </Row>
                                <Row className="card-heading">
                                    <Col span={12}>Case Ref No</Col>
                                    <Col span={12} className="textRight">Category</Col>
                                </Row>
                                <Row>
                                    <Col span={12}>{item.case_ref_no}</Col>
                                    <Col span={12} className="textRight">{item.c_name}</Col>
                                </Row>
                                <Row className="card-heading">
                                    <Col span={12}>Created On</Col>
                                    <Col span={12} className="textRight">Business Type</Col>
                                </Row>
                                <Row>
                                    <Col span={12}>{item.created_on.utcLocal().dateTimeFormat()}</Col>
                                    <Col span={12} className="textRight">{item.bt_name}</Col>
                                </Row>
                                <Button type="primary"><Link to={`/app/case/${item.case_id}/overview`}>Open</Link></Button>
                            </Card>
                        ))}                        
                    </InfiniteScroll>
                    {/* <div ref={triggerRef} className={clsx("trigger", { visible: loading })}>                    
                        <Card>
                            <Skeleton active title={false} paragraph={{rows:4}} className="p8" />
                        </Card>
                    </div> */}
                    {/* {isFetching && <h1>Fetching more list items...</h1>} */}
                    {/* {isFetching && loaderList.map((item, index) => (
                        <Card key={index}>
                            <Skeleton active title={false} paragraph={{rows:4}} className="p8" />
                        </Card>
                    ))} */}
                </Col>
            </Row>
        </div>
    );
}
export default AllCases;