import React, { useState, useEffect } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Tabs, Input, Menu, Popover, Button, Checkbox, Card, Popconfirm, Upload, Progress, Avatar, Drawer, Space, Select, message, Spin, Modal } from 'antd';
import Icon, { FileImageOutlined, FileWordOutlined, FilePptOutlined, FileTextOutlined } from '@ant-design/icons';
import { UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import "../../../logics/prototypes";
import { websocketService, socket } from "../../../logics/services/websocket.service";
import store from '../../../logics/store/case.store';
import { encrypt } from '../../../logics/helpers';
import { APIURL } from '../../../constants';
import PDFView from "./PdfView";
import CaseStatusIcon from "./CaseStatusIcon";

import EditSvg from '../../../assets/svg-icons/edit.svg';
import DeleteSvg from '../../../assets/svg-icons/delete.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import StarFillSvg from  '../../../assets/svg-icons/star_fill.svg';
import FileUploadSvg from '../../../assets/svg-icons/file_upload.svg';
import ActivitySvg from '../../../assets/svg-icons/activity.svg';
import CommentSvg from '../../../assets/svg-icons/comment.svg';
import ResponsesBySvg from '../../../assets/svg-icons/responses_by.svg';
import InformationSvg from '../../../assets/svg-icons/information.svg';
import MoreHorizSvg from '../../../assets/svg-icons/more_horiz.svg';
import PdfSvg from '../../../assets/svg-icons/pdf.svg';
import DownloadSvg from '../../../assets/svg-icons/download.svg';
import WhiteDownloadSvg from '../../../assets/svg-icons/white_download.svg';
import EyeSvg from '../../../assets/svg-icons/eye.svg';
import close from "../../../assets/svg-icons/close.svg";
import WaitingSvg from "../../../assets/svg-icons/waiting.svg";
import SendMessageSvg from  '../../../assets/svg-icons/send-message.svg';
import { DownOutlined } from '@ant-design/icons';
import ArrowDownSvg from '../../../assets/svg-icons/arrow_drop_down.svg';
import LeftNavSvg from '../../../assets/svg-icons/left_navigation.svg';
import RightNavSvg from '../../../assets/svg-icons/right_navigation.svg';

const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const EditIcon = props => <Icon component={EditSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const CommentEditIcon = props => <Icon component={EditSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const DeleteIcon = props => <Icon component={DeleteSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: 8}} {...props} />;
const FileUploadIcon = props => <Icon component={FileUploadSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const ActivityIcon = props => <Icon component={ActivitySvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const CommentIcon = props => <Icon component={CommentSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const ResponsesByIcon = props => <Icon component={ResponsesBySvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const InformationIcon = props => <Icon component={InformationSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const MoreHorizIcon = props => <Icon component={MoreHorizSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const PdfIcon = props => <Icon component={PdfSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const DownloadIcon = props => <Icon component={DownloadSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const WhiteDownloadIcon = props => <Icon component={WhiteDownloadSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const EyeIcon = props => <Icon component={EyeSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: '16px'}} {...props} />;
const CloseIcon = props => <Icon component={close} style={{ fontSize: 24, verticalAlign: "middle", float: 'right' }} {...props} />;
const CommentCloseIcon = props => <Icon component={close} style={{ fontSize: 24, verticalAlign: "middle", marginLeft: 'auto' }} {...props} />;
const WaitingIcon = props => <Icon component={WaitingSvg} style={{ fontSize: 236, verticalAlign: "middle" }} {...props} />;
const SendMessageIcon = props => <Icon component={SendMessageSvg} style={{ fontSize: 20, verticalAlign: 'middle'}} {...props} />;
const ArrowDownIcon = props => <Icon component={ArrowDownSvg} style={{ fontSize: 24, verticalAlign: 'middle', float: 'right' }} {...props} />;
const LeftNavIcon = props => <Icon component={LeftNavSvg} style={{ fontSize: 18, verticalAlign: 'middle'}} {...props} />;
const RightNavIcon = props => <Icon component={RightNavSvg} style={{ fontSize: 18, verticalAlign: 'middle'}} {...props} />;

const { TabPane } = Tabs;
const { TextArea } = Input;

const menu = (
    <Menu>
        <Menu.Item key="0">
            <span>Please provide a reason why you would like to reject this proposal?</span>
        </Menu.Item>
    </Menu>
);

const loaderList = [1,2];
const { Option } = Select;
const patterns = {
    isImageFilePattern: /\.(jpe?g|gif|bmp|png|svg)$/i,
    isImageNSFilePattern: /\.(tiff)$/i,
    isVideoFilePattern: /\.(mp4|webm|3gp|ogg)$/i,
    isVideoNSFilePattern: /\.(flv|wmv|avi)$/i,
    isAudioFilePattern: /\.(mp3)$/i,
    isPdfFilePattern: /\.(pdf)$/i,
    isDocFilePattern: /\.(doc|docx)$/i,
    isExcelFilePattern: /\.(xls|xlsx|csv)$/i,
    isTxtFilePattern: /\.(txt)$/i,
    isPptFilePattern: /\.(ppt|pptx)$/i,
    isExtractableFilePattern: /\.(gz|tar|rar|g?zip)$/i,
    isViewable: /\.(jpe?g|gif|bmp|png|svg|mp3|mp4|webm|3gp|ogg|pdf|txt)$/i,
    isUploadable: /\.(jpe?g|gif|bmp|png|svg|tiff?|mp3|mp4|3gp|flv|wmv|mkv|avi|webm|ogg|pdf|doc|docx|csv|xls|xlsx|ppt|pptx|txt)$/i
};

class OutcomeView extends React.Component {
    
    constructor(props) {
        super(props);
        this.siteSetting=store.getState().settings;
        this.caseId=this.props.caseId;
        this.visible=this.props.visible;
        this.user=this.props.user;
        this.rolePermissions = this.user.permissions;
        this.proposalId=this.props.proposalId;
        this.editList = [];
        this.state = {
            caseInfo: [],
            loading: false,
            outcomeViewVisible: this.visible,
            confirm: false,
            proposalId: this.proposalId,
            previewFileName: '',
            previewFileId: 0,
            previewFileDrawer: false,
            previewPdfFile: false,
            previewImgFile: false,
            noPreviewFile: false,
            previewFileLength: 0,
            openFileNo: 0,
            uploadFileData: '',
            previewIcon: '',
            acceptModel: false,
            rejectModel: false,
            signedPassword: '',
            agreementSign: false,
            outcomeComment: '',
            outcomeCommentLoading: false,
            outcomeActivityLoading: false,
            selectedRejReason: 'Please select',
            rejectReason: '',
            rejectDescription: '',
            acceptSign: false,
            appvRejModal: false,
            selectedProposal: [],
            proposalStatus: [],
            proposActivites: [],
            proposCommentList: [],
            reasonList: [],
            rejectReasonList: [],
            isUserView: false,
            userViewParam: [],
            userViewType: '',
            editComment: '',
            editCommentId: '',
            isConfirmModalOpen: false,
            isConfirmApproveModal: false,
            prevFileId: undefined,
            nxtFileId: undefined
        };
    }
    
    componentDidMount = async () => {
        this.user = userService.getUser();
        this.getCaseInfo();
        this.viewProposal();
		this.getRejectReasons();
        this.webSocketReceive();
    };

    webSocketReceive () {
        socket.onmessage = resData => {
            let res = (resData && resData.data) ? JSON.parse(resData.data): [];
            if (res && res.method == 'proposal') {
                if (res.data && res.data.case_id == this.caseId && res.data.users.indexOf(this.user.id) != -1) {
                    this.viewProposal();
                    if (res.data.type == 'withdraw' || res.data.type == 'p_reject') {
                        this.setState({rejectModel: false, acceptModel: false, acceptSign: false});
                    }
                }	
            }

            if (res && res.method == 'comments') {
                this.getComment();
            }
        }
    }

    getCaseInfo = () => {
        apiService.getSingleCase({
            case_id: this.caseId,
        }).then((res) => {
            if (res.status === "success") {
                this.setState({caseInfo: res.data});
            }
        });
    }

    onClose = () => {
        this.setState({outcomeViewVisible: false});
        this.props.closeOutcomeViewVisible(false);
        // let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        // element.style.display = 'flex';
    };

    viewProposal() {
        this.setState({loading: true});
		apiService.viewProposal({
            cs_id: this.state.proposalId,
            case_id: this.caseId
        }).then(res => {
            if(res.status == 'success') {
                this.setState({loading: false, selectedProposal: res.data.proposed_info, proposalStatus: res.data.proposed_info.party_status, proposActivites: res.data.proposal_activity, proposCommentList: res.data.proposal_comments});
            }
        })
	}

    getRejectReasons() {
		apiService.getRejectReasons({module_name : 'Proposal'})
        .then(res => {
            if(res.status == 'success') {
                this.setState({reasonList: res.data});
            }
        });
	}

    onProposalTabChange = key => {
        if(key == "comment") {
            this.setState({outcomeCommentLoading: true});
            this.getComment();
        } else if(key == "activity") {
            this.setState({outcomeActivityLoading: true});
            this.proposalActivity();
        }
    };

    viewFile = (fileId) => {
        this.setState({loading: true, previewPdfFile: false, previewImgFile: false, noPreviewFile: false});
        apiService.getFileData({
            f_id: fileId
        }).then((res) => {
            if (res.status == 'success') {
                this.selectedFile = res.data;	
                this.isImage = patterns.isImageFilePattern.test(this.selectedFile.file.file_name);
                this.isPdf = patterns.isPdfFilePattern.test(this.selectedFile.file.file_name);
                this.isDoc = patterns.isDocFilePattern.test(this.selectedFile.file.file_name);
                this.isTxt = patterns.isTxtFilePattern.test(this.selectedFile.file.file_name);
                this.isExcel = patterns.isExcelFilePattern.test(this.selectedFile.file.file_name);
                this.isPpt = patterns.isPptFilePattern.test(this.selectedFile.file.file_name);
                this.isNSImage = patterns.isImageNSFilePattern.test(this.selectedFile.file.file_name);
                this.isViewable = patterns.isViewable.test(this.selectedFile.file.file_name);
                let isOpenFile = false;
                let prevFileId;
                let nxtFileId;
                this.state.selectedProposal.filesInfo.forEach((e, k) => {
                    if(isOpenFile && nxtFileId == undefined) {
                        nxtFileId = e.f_id;
                    }
                    if(e.f_id == this.selectedFile.f_id) {
                        this.setState({openFileNo: k + 1});
                        isOpenFile = true;
                    }
                    if(!isOpenFile) {
                        prevFileId = e.f_id;
                    }
                });
                this.setState({previewFileId: fileId, previewFileDrawer: true, previewFileName: this.selectedFile.file.file_name, uploadFileData: res.data.url, loading: false, previewFileLength: this.state.selectedProposal.filesInfo.length, prevFileId: prevFileId, nxtFileId: nxtFileId, previewIcon: this.selectedFile.file.ext});
                if(this.isPdf) {
                    this.setState({previewPdfFile: true});
                } else if (this.isImage) {
                    this.setState({previewImgFile: true});
                } else {
                    this.setState({noPreviewFile: true});
                }
            }
        });
    }

    onShowPreviewFileDrawer() {
        this.setState({previewFileId: 0, previewFileDrawer: false, previewPdfFile: false, previewImgFile: false, noPreviewFile: false, previewFileName: '', uploadFileData: '', previewFileLength: 0, prevFileId: undefined, nxtFileId: undefined});
    }

    signPasswordChange = (e) =>  {
        this.setState({signedPassword: e.target.value});
    }

    handleAgreementSign = (agreementSign) =>  {
        this.setState({agreementSign: !agreementSign});
    }

    outcomeCommentChange = (e) =>  {
        this.setState({outcomeComment: e.target.value});
    }

    outcomeAddComment() {
        apiService.addProposalComment({
            csId: this.state.selectedProposal.cs_id,
            comment: this.state.outcomeComment,
            parent_id: 0
        }).then((res) => {
            if(res.status == 'success') {
                message.success(res.message);
                this.getComment();
                this.proposalActivity();
            } else {
                message.error(res.message);
            }
        });
        this.setState({outcomeComment: ''});
	}

    getComment() {
        apiService.getProposalComment({
            csId: this.state.selectedProposal.cs_id,
            caseId: this.caseId
        }).then(res => {
            if (res.status == 'success') {
                this.setState({proposCommentList: res.data, outcomeCommentLoading: false});
            }
        }, () => {
        });
    }

    proposalActivity() {
		apiService.outcomesActivity({
            csId: this.state.proposalId,
            type : 'proposal'
        }).then(res => {
            if(res.status == 'success') {
                this.setState({proposActivites: res.data});
            }
        });

	}

    closeproposal() {
        this.setState({acceptModel: false, rejectModel: false, acceptSign: false, agreementSign: false, signedPassword: '', rejectReason: '', rejectDescription: ''});
        this.onClose();	
	}

    reject() {
        let rejectReasonList = [];
		this.state.reasonList.filter(e => {
			if(e.type == 'reject') {
                rejectReasonList = rejectReasonList.concat(e);
			} 
		});
        // if(rejectReasonList.length > 0) {
        //     this.setState({selectedRejReason: rejectReasonList[0].reason});
        // }
        this.setState({rejectReasonList: rejectReasonList, acceptModel: false, rejectModel: true});
    }

    handleChangeRejectReason(reason) {
        this.setState({selectedRejReason: reason, rejectReason: reason});
	}

    handleRejectDescription = (e) =>  {
        this.setState({rejectDescription: e.target.value});
    }

    rejprevious() {
        this.setState({rejectModel: false, appvRejModal: false, selectedRejReason: 'Please select', rejectReason: '', rejectDescription: '', signedPassword: ''});
	}

    accept() {
        this.setState({rejectModel: false, acceptModel: true, acceptSign: true});
    }

    rejSign() {
        this.setState({acceptModel: false, acceptSign: false, agreementSign: false, signedPassword: ''});
	}

    rejectProposal() {
		this.setState({loading: true});
		apiService.rejectProposal({
            cs_id: this.state.proposalId,
            case_id: this.caseId,
            rejectedReason: this.state.rejectReason,
            rejectDescription: this.state.rejectDescription
        }).then(res => {
            if(res.status == 'success') {
                this.setState({loading: false, proposalStatus: res.data.proposalStatus, rejectModel: false, selectedRejReason: 'Please select', rejectReason: '', rejectDescription: ''});
                message.success(res.message);
                if(res.data.proposalInfo.status == 4) {
                    this.getCaseInfo();
                }			
                this.closeproposal();
                websocketService.send({
                    method: 'proposal',
                    isDisplay: true,
                    data: {
                        users: this.state.selectedProposal.parties.split(",").map(Number),
                        content: res.instant_msg,
                        type: 'p_reject', // Party Reject
                        time_display: false
                    }
                });
            } else if(res.status == 'error') {
                this.setState({loading: false});
                message.error(res.message);
            }
        });
	}

    acceptProposal() {
		this.setState({loading: true});
		apiService.acceptProposal({
            cs_id: this.state.proposalId,
            case_id: this.caseId,
            password: encrypt(this.state.signedPassword)
        }).then(res => {
            this.setState({loading: false});
            if(res.status == 'success') {
                this.viewProposal();           					
                message.success(res.message);	
                let parties = res.data.proposalInfo.parties;
                this.setState({proposalStatus: res.data.proposalStatus});		
                if(res.data.proposalInfo.status == 3) {
                    this.getCaseInfo();
                }							
                websocketService.send({
                    method: 'proposal',
                    isDisplay: true,
                    data: {
                        users: parties.split(",").map(Number),
                        content: res.instant_msg,
                        type: 'p_accept', // Party Accept
                        time_display: false,
                        status: res.data.proposalInfo.status
                    }
                });
                this.setState({agreementSign: false, signedPassword: ''});
                this.rejSign();
            } else if(res.status == 'error') {
                message.error(res.message);
            }				
        });
	}

    apprRej() {
        let rejectReasonList = [];
		this.state.reasonList.filter(e => {
			if(e.type == 'approval_reject') {
                rejectReasonList = rejectReasonList.concat(e);
			} 
		});
        this.setState({rejectReasonList: rejectReasonList, appvRejModal: true});
	}

    approveReject() {
        this.setState({loading: true});
		apiService.approveReject({
			cs_id: this.state.proposalId,
			case_id: this.caseId,
			rejectedReason: this.state.rejectReason,
			rejectDescription: this.state.rejectDescription,
			password: encrypt(this.state.signedPassword)
		}).then(res => {
            this.setState({loading: false});
			if(res.status == 'success') {
				message.success(res.message);
                this.setState({proposalStatus: res.data, loading: false, appvRejModal: false, selectedRejReason: 'Please select', rejectReason: '', rejectDescription: ''});
				this.viewProposal();
			} else if(res.status == 'error') {
				message.error(res.message);
			} 
		});
	}

    approveProposal() {
        this.setState({loading: true, isConfirmApproveModal: false});
		apiService.approveProposal({
			cs_id: this.state.proposalId,
			case_id: this.caseId,
			type_id: this.state.caseInfo.caseUserType.role_id
		}).then(res => {
            this.setState({loading: false});
			if(res.status == 'success') {
				this.closeproposal();
				message.success(res.message);
				this.getCaseInfo();
				websocketService.send({
					method: 'proposal',
					isDisplay: true,
					data: {
						users: res.data.proposalInfo.parties.split(",").map(Number),
						content: 'has approved the proposal.',
						type: 'approve', 
						time_display: false,
						status: res.data.proposalInfo.status
					}
				});
			} else if(res.status == 'error') {
				message.error(res.message);
			}	
		});
	}

    editProposal() {
        this.props.viewEditProposal(false, this.state.proposalId);
		this.closeproposal();
	}

    withdrawProposal() {
        this.setState({loading: true, isConfirmModalOpen: false});
		apiService.withdrawProposal({
			csId: this.state.selectedProposal.cs_id,
			case_id: this.caseId,
            pStatus: this.state.selectedProposal.p_status
		}).then(res => {
            this.setState({loading: false});
			if(res.status == 'success') {
                if(this.state.selectedProposal.p_status == '0') {
					this.closeproposal();
				} else {
					this.viewProposal();
				}
				message.success(res.message);
				websocketService.send({
					method: 'proposal',
					isDisplay: true,
					data: {
						users: this.state.selectedProposal.parties.split(",").map(Number),
						content: res.instant_msg,
						type: 'withdraw', 
						time_display: false
					}
				});
			} else if(res.status == 'error') {
				message.error(res.message);
			}	
		});
	}

    proposPreview() {
        this.setState({loading: true});
		apiService.previewProposal({
            csId: this.state.selectedProposal.cs_id
        }).then(res => {
            if(res.status == 'success') {
                this.setState({previewFileId: 0, previewFileDrawer: true, previewPdfFile: true, previewFileName: '', uploadFileData: res.data, loading: false});
            }
        });
    }

    showUserView = (data, type) => {
        this.setState({isUserView: true, userViewParam: data, userViewType: type});
    };

    closeUserView = () => {
        this.setState({isUserView: false});
    };

    forgotPassword() {
        this.setState({loading: true});
        apiService.logout()
        .then((res) => {
            if (res.status === 'success') {
                userService.removeUser();
                this.props.history.push("/auth/forgotpassword");
            }
        });
    }

    showEditComment(comment) {
        this.setState({editComment: comment.comment, editCommentId: comment.cmt_id});
		let r = !this.editList[comment.cmt_id];
		this.editList.forEach((e, k) => {
			this.editList[k] = false;
		});
		this.editList[comment.cmt_id] = r;		
	}

    closeEditComment(comment) {
        this.setState({editComment: '', editCommentId: ''});
		let r = !this.editList[comment.cmt_id];
        this.editList[comment.cmt_id] = r;
	}
    
    outcomeEditCommentChange = (e) =>  {
        this.setState({editComment: e.target.value});
    }

    outcomeUpdateComment(id) {
        this.setState({loading: true});
        apiService.editProposalComment({
            cs_cmt_id: this.state.editCommentId,
			comment: this.state.editComment
        }).then((res) => {
            if(res.status == 'success') {
                message.success(res.message);
                this.getComment();
                this.proposalActivity();
            } else {
                message.error(res.message);
            }
        });
        this.setState({editComment: '', editCommentId: '', loading: false});
		this.editList[this.state.editCommentId] = false;
	}
    
    getFileIcon(type) {
        let svgIcon = '';
        if(type == 'application/pdf' || type == 'pdf') {
            svgIcon = <PdfIcon />;
        } else if(type == 'image/jpeg' || type == 'image/png' || type == 'jpg' || type == 'png') {
            svgIcon = <FileImageOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type == 'application/msword' || type == 'doc' || type == 'docx') {
            svgIcon = <FileWordOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || type == 'application/vnd.ms-powerpoint' || type == 'ppt' || type == 'pptx') {
            svgIcon = <FilePptOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'text/plain' || type == 'txt') {
            svgIcon = <FileTextOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        }

        return svgIcon;
    }

    confirmDelete() {
        this.setState({isConfirmModalOpen: true});
    }

    handleCancel() {
        this.setState({isConfirmModalOpen: false});
    }

    confirmApprove() {
        this.setState({isConfirmApproveModal: true});
    }
    
    handleApproveCancel() {
        this.setState({isConfirmApproveModal: false});
    }

    render() {
        const { outcomeViewVisible, loading, caseInfo, selectedProposal, proposalStatus, previewFileName, previewFileDrawer, previewFileId, previewPdfFile, previewImgFile, noPreviewFile, previewFileLength, openFileNo, prevFileId, nxtFileId, uploadFileData, previewIcon, acceptModel, rejectModel, signedPassword, agreementSign, outcomeComment, outcomeCommentLoading, proposCommentList, outcomeActivityLoading, proposActivites, selectedRejReason, rejectReason, rejectDescription, acceptSign, appvRejModal, rejectReasonList, isUserView, userViewParam, userViewType, editComment, isConfirmModalOpen, isConfirmApproveModal } = this.state;

        let cs_id = selectedProposal ? selectedProposal.cs_id: 0;
        return (
            <div>
                <Drawer
                    title="View Proposal"
                    className="custom-drawer-popup outcome-view-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={outcomeViewVisible}
                    extra={
                        <Space>
                            {   (selectedProposal && (selectedProposal.p_status == 3 || selectedProposal.p_status == 6) && (this.rolePermissions['Preview Proposal'] || this.rolePermissions['Download Proposal'])) &&
                                (
                                    <>
                                        {this.rolePermissions['Preview Proposal'] && (<EyeIcon onClick={() => this.proposPreview()} />)}
                                        {this.rolePermissions['Download Proposal'] && (<a href={ APIURL + "proposal/downloadProposal?case_id=" + this.caseId + "&csId=" + cs_id + "&token=" + this.user.token} download><DownloadIcon /></a>)}
                                    </>
                                )
                            }
                            {   (caseInfo?.case_status != 5 && selectedProposal && selectedProposal.is_voided != 1 && (selectedProposal.p_status == 0 || selectedProposal.p_status == 1) && (selectedProposal.party_status.permissions == 'Can Amend / Withdraw' || selectedProposal.proposed_by.u_id == this.user.id) && (this.rolePermissions['Delete Proposal'] || this.rolePermissions['Edit Proposal'])) &&
                                (
                                    <>
                                        {this.rolePermissions['Edit Proposal'] && (<>{(selectedProposal && (selectedProposal.signed_count != 0 || selectedProposal.p_status == 4)) ? <EditIcon className="disabled-opacity" />: <EditIcon onClick={() => this.editProposal()} />}</>)}
                                        {this.rolePermissions['Delete Proposal'] && (<>{(selectedProposal && (selectedProposal.signed_count != 0 || selectedProposal.p_status == 4)) ? <DeleteIcon className="disabled-opacity" />: 
                                            // <Popconfirm
                                            //     placement="center"
                                            //     title={<><p>You can't revert this action.</p><span>Are you sure want to {selectedProposal.p_status == 0 ? 'delete' : 'withdraw' } this proposal?</span></>}
                                            //     onConfirm={() => this.withdrawProposal()}
                                            //     okText={selectedProposal.p_status == 0 ? 'Delete' : 'Withdraw' }
                                            // >
                                            //     <DeleteIcon />
                                            // </Popconfirm>
                                            <>
                                                <DeleteIcon onClick={() => this.confirmDelete()} />
                                                <Modal className="proposal-confirm-delete" title={selectedProposal.p_status == 0 ? 'Delete Proposal' : 'Withdraw Proposal'} visible={isConfirmModalOpen} onOk={() => this.withdrawProposal()} okText={selectedProposal.p_status == 0 ? 'Delete' : 'Withdraw'} onCancel={() => this.handleCancel()}>
                                                    <p>You can't revert this action.</p><span>Are you sure want to {selectedProposal.p_status == 0 ? 'delete' : 'withdraw' } this proposal?</span>
                                                </Modal>
                                            </>
                                        }</>)}
                                    </>
                                )
                            }
                        </Space>
                      }
                    >
                        <Row className="sub-header">
                            <Col span={20}>
                                <CaseStatusIcon caseId={this.caseId} case_status={caseInfo.case_status} case_type={caseInfo.case_type} size={24}/>
                                <span className="pl16"><b>{caseInfo ? caseInfo.case_name:''}</b></span>
                                <span className="pl8">
                                    { !caseInfo.isFav ? <StarIcon />: <StarFillIcon />}
                                </span>
                            </Col>
                        </Row>

                        {/* <Drawer
                            title={previewFileName}
                            className="task-file-view-drawer"
                            placement="top"
                            height={"100%"}
                            onClose={() => this.onShowPreviewFileDrawer(previewFileDrawer)}
                            visible={previewFileDrawer}
                            extra={previewFileId != 0 && <a href={ APIURL + "file/downloadFile?f_id=" + previewFileId + "&token=" + this.user.token} download><DownloadIcon /></a>}
                        >
                            {previewPdfFile && (<PDFView pdfData={uploadFileData} isUrl={false} />)}
                            {previewImgFile && (<div><img src={uploadFileData} alt="img" width="100%"/></div>)}
                            {noPreviewFile && (<div className="no-preview-container"><Card><span>No Preview Available</span><p>You can Download copy of the file</p></Card></div>)}
                        </Drawer> */}
                        
                        <Spin spinning={loading} delay={500}>
                            {   selectedProposal && selectedProposal.cs_id &&
                                (
                                    <>
                                        { !previewFileDrawer &&
                                            (
                                                <>
                                                    <Row className="task-details p16">
                                                        <p className="task-title mb16">Details of Proposal</p>
                                                        <p className="task-description" dangerouslySetInnerHTML={{ __html: selectedProposal.description }}></p>
                                                        <Col span={24}>
                                                            <p className="created-by">Proposed by</p>
                                                        </Col>
                                                        <Col span={window.innerWidth > 600 ? 12: 24} className="created-profile">
                                                            <div className="created-details">
                                                                <Avatar className="mr8" src={selectedProposal.proposed_by.user_image}>{selectedProposal.proposed_by.p_name}</Avatar>
                                                                <span>
                                                                    <p className="name">{ selectedProposal.proposed_by.name }</p>
                                                                    <p className="role">{ selectedProposal.proposed_by.role_name }</p>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col span={window.innerWidth > 600 ? 12: 24} className={window.innerWidth > 600 ? "proposed-figure proposed-figure-right": "proposed-figure"}>
                                                            <span className="proposed-value">Value:</span>
                                                            {   selectedProposal.proposal_figure
                                                                ?
                                                                    <span className="proposed-usd">{ selectedProposal.proposal_figure } { this.siteSetting && this.siteSetting.crid ? this.siteSetting.crid : ''  }</span>
                                                                :
                                                                    <span className="proposed-none">None</span>
                                                            }
                                                        </Col>
                                                    </Row>

                                                    <Row className="tabs-container">
                                                        <Col span={24}>
                                                        <Tabs defaultActiveKey={(selectedProposal && selectedProposal.filesInfo.length != 0) ? 1: 2} onChange={this.onProposalTabChange}>
                                                            {   (selectedProposal && selectedProposal.filesInfo.length != 0) &&
                                                                (
                                                                    <TabPane 
                                                                    tab={
                                                                        <span>
                                                                            <FileUploadIcon />
                                                                        </span>
                                                                    } key="1">
                                                                        <Col span={24} className="task-view-tab-heading">
                                                                            <p className="task-view-tab-title">Attachment(s)</p>
                                                                        </Col>
                                                                        <div className="attachments-container">
                                                                            {/* <p className="attachments">Attachment(s)</p> */}
                                                                            <div className="uploaded-files">
                                                                                <ul className="uploaded-file-list">
                                                                                    {
                                                                                        selectedProposal.filesInfo.map((file, index) => (
                                                                                            <li className="uploaded-file" key={index}>
                                                                                                <span className="file-icon" onClick={() => this.viewFile(file.f_id)}>
                                                                                                    {this.getFileIcon(file.mime_type)}
                                                                                                </span>                                               
                                                                                                <span className="file-name" onClick={() => this.viewFile(file.f_id)}>
                                                                                                    { file.orginal_name }
                                                                                                </span>
                                                                                                <span className="file-delete" >
                                                                                                    <a href={ APIURL + "file/downloadFile?f_id=" + file.f_id + "&token=" + this.user.token} download><DownloadIcon /></a>
                                                                                                </span>
                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </TabPane>
                                                                )
                                                            }
                                                            <TabPane  
                                                            tab={
                                                                <span>
                                                                    <ResponsesByIcon />
                                                                </span>
                                                            } key="2">
                                                                <div>
                                                                    <Row>
                                                                        <Col span={24} className="task-view-tab-heading">
                                                                            <p className="task-view-tab-title">Overall Status</p>
                                                                        </Col>
                                                                        {   
                                                                            selectedProposal.proposal_status.map((proposer, index) => (
                                                                                <div className="user-details" key={index}>
                                                                                    <Avatar className="mr16" src={proposer.user_profile.user_image}>{proposer.user_profile.p_name}</Avatar>
                                                                                    <span>
                                                                                        <p className="name">{proposer.user_profile.name}</p>
                                                                                        <p className="role">{proposer.role_name}</p>
                                                                                        {(selectedProposal.p_status == 5) && (<p className="status">No Action Required</p>)}
                                                                                        {(proposer.status == 1 && selectedProposal.p_status != 5) && (<p className="status">Awaiting response</p>)}
                                                                                        {(proposer.status == 2 || proposer.status == 3) && (<p className="status">Accepted on: {proposer.updated_at.utcLocal().dateTimeFormat()}</p>)}
                                                                                        {(proposer.status == 4 || proposer.status == 5) && (<p className="status-reject">{ proposer.status == 4 ? 'Rejected on: ' : 'Approval denied on: '} {proposer.rejected_at.utcLocal().dateTimeFormat()}</p>)}
                                                                                        {(proposer.status == 4 || proposer.status == 5) && 
                                                                                            (
                                                                                                <p className="status-view-reason">
                                                                                                    <Popover 
                                                                                                        content={<><span className="popover-reason-title">Reason</span><p>{proposer.rejected_reason}</p><span className="popover-comments-title">Comments</span><p>{proposer.description}</p></>} 
                                                                                                        title="Rejection Reason" trigger="click">
                                                                                                        View Reason
                                                                                                    </Popover>
                                                                                                </p>
                                                                                                
                                                                                            )
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        {/* <div className="response-download">
                                                                            <p className="view-res">View Response</p>
                                                                            <p>Download</p>
                                                                        </div> */}
                                                                    </Row>
                                                                </div>
                                                            </TabPane>
                                                            <TabPane  
                                                            tab={
                                                                <span>
                                                                    <InformationIcon />
                                                                </span>
                                                            } key="3">
                                                                <div>
                                                                    <Row>
                                                                        <Col span={24} className="task-view-tab-heading">
                                                                            <p className="task-view-tab-title">Information</p>
                                                                        </Col>
                                                                        <Row className="can-view">
                                                                            <Col span={6}>
                                                                                <p>Can View</p>
                                                                            </Col>
                                                                            <Col span={18}>
                                                                                {   (selectedProposal.canView && selectedProposal.canView.length != 0) &&
                                                                                    (
                                                                                        <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                                            {
                                                                                                (selectedProposal.canView).slice(0,3).map((view_party, index) => (
                                                                                                    <Avatar onClick={() => this.showUserView(selectedProposal.canView, 'assigned_to')} src={view_party.user_image} key={index}>{view_party.p_name}</Avatar>
                                                                                                ))
                                                                                            }
                                                                                        </Avatar.Group>
                                                                                    )
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="can-view">
                                                                            <Col span={6}>
                                                                                <p>Sent To</p>
                                                                            </Col>
                                                                            <Col span={18}>
                                                                                {   (selectedProposal.sentTo && selectedProposal.sentTo.length != 0) &&
                                                                                    (
                                                                                        <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                                            {
                                                                                                (selectedProposal.sentTo).slice(0,3).map((view_party, index) => (
                                                                                                    <Avatar onClick={() => this.showUserView(selectedProposal.sentTo, 'assigned_to')} src={view_party.user_image} key={index}>{view_party.p_name}</Avatar>
                                                                                                ))
                                                                                            }
                                                                                        </Avatar.Group>
                                                                                    )
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        <div className="sign-status">
                                                                            <p><span>Type:</span> { selectedProposal.type }</p>
                                                                            <p><span>Overall Status:</span> { selectedProposal.status }</p>
                                                                            {/* <p><span>Created on:</span> 15 Mar 2021 12:28PM</p> */}
                                                                            <p><span>Last modified on:</span> {selectedProposal.updated_at.utcLocal().dateTimeFormat()}</p>
                                                                        </div>
                                                                    </Row>
                                                                </div>
                                                            </TabPane>
                                                            {   (selectedProposal.p_status != 0 && (this.rolePermissions['Add comments in Proposal'] || this.rolePermissions['Edit comments in Proposal'])) &&
                                                                (
                                                                    <TabPane  
                                                                        tab={
                                                                            <span>
                                                                                <CommentIcon />
                                                                            </span>
                                                                        } key="4">
                                                                            <Col span={24} className="task-view-tab-heading">
                                                                                <p className="task-view-tab-title">Comments</p>
                                                                            </Col>
                                                                            <div className="comment-details">
                                                                                <Row>
                                                                                    <Col span={21}>
                                                                                        <TextArea name="outcome-comment" id="outcome-comment" rows={4} autoSize={{ maxRows: 3 }} placeholder="Add your comments" onChange={this.outcomeCommentChange} maxLength={1000} value={outcomeComment} />
                                                                                    </Col>
                                                                                    <Col span={3} className="task-add-comment">
                                                                                        <SendMessageIcon onClick={()=> this.outcomeAddComment() } />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <p className="info-txt">Comments you post are visible to everyone with access to this Proposal</p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            {   outcomeCommentLoading
                                                                                ?
                                                                                    loaderList.map((item, index) => (
                                                                                        <Card key={index}>
                                                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                                                        </Card>
                                                                                    ))
                                                                                :
                                                                                    <>
                                                                                        {   proposCommentList && proposCommentList.length > 0
                                                                                            ? proposCommentList.map((comment, index) => (
                                                                                                <div className="comment-container" key={index}>
                                                                                                    <div className="user-comment-details">
                                                                                                        <Avatar className="mr16" src={comment.user_image}>{comment.p_name}</Avatar>
                                                                                                        <span>
                                                                                                            <p className="name">{comment.name}</p>
                                                                                                            <p className="role">{comment.user_type}</p>
                                                                                                        </span>
                                                                                                        {(!this.editList[comment.cmt_id] && comment.cmt_by == this.user.id) && <CommentEditIcon onClick={() => this.showEditComment(comment)} />}
                                                                                                        {(this.editList[comment.cmt_id] && comment.cmt_by == this.user.id) && <CommentCloseIcon onClick={() => this.closeEditComment(comment)} />}
                                                                                                    </div>
                                                                                                    {(!this.editList[comment.cmt_id]) && <p className="comment">{comment.comment}</p>}
                                                                                                    {   (this.editList[comment.cmt_id]) &&
                                                                                                        (
                                                                                                            <Row className="mb4">
                                                                                                                <Col span={21}>
                                                                                                                    <TextArea name="outcome-edit-comment" id="outcome-edit-comment" rows={3} onChange={this.outcomeEditCommentChange} maxLength={1000} value={editComment} />
                                                                                                                </Col>
                                                                                                                <Col span={3} className="task-edit-comment">
                                                                                                                    <SendMessageIcon className={editComment.length == 0 && "disabled-opacity"} onClick={()=> this.outcomeUpdateComment(comment.f_id) } />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        )
                                                                                                    }
                                                                                                    <p className="comment-date-time">{comment.created_at.utcLocal().dateTimeFormat()}</p>
                                                                                                </div>
                                                                                            ))
                                                                                            :
                                                                                                <></>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </TabPane>
                                                                )
                                                            }
                                                            {   this.rolePermissions['View Activity in Proposal'] &&
                                                                (
                                                                    <TabPane  
                                                                    tab={
                                                                        <span>
                                                                            <ActivityIcon />
                                                                        </span>
                                                                    } key="5">
                                                                        <Col span={24} className="task-view-tab-heading">
                                                                            <p className="task-view-tab-title">Activity</p>
                                                                        </Col>
                                                                        { outcomeActivityLoading
                                                                            ?
                                                                                loaderList.map((item, index) => (
                                                                                    <Card key={index}>
                                                                                        <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                                                    </Card>
                                                                                ))
                                                                            :
                                                                                <>
                                                                                    {   proposActivites && proposActivites.length > 0
                                                                                        ? proposActivites.map((activity, index) => (
                                                                                            <div className="activity-details" key={index}>
                                                                                                <Avatar size={24} className="mr16" src={activity.creator_info.user_image}>{activity.creator_info.p_name}</Avatar>
                                                                                                <span style={{ width: '100%' }}>
                                                                                                    <p className="content">{ activity.creator_info.name +' ('+ activity.user_role +') ' } { activity.content }</p>
                                                                                                    <p className="content">{activity.created_at.utcLocal().dateTimeFormat()}</p>
                                                                                                </span>
                                                                                            </div>
                                                                                        ))
                                                                                        :
                                                                                            <></>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </TabPane>
                                                                )
                                                            }
                                                        </Tabs>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }

                                        {   previewFileDrawer &&
                                            (
                                                <div className="view-file-drawer">
                                                    <Row className="preview-header">
                                                        <Col className="header">
                                                            Preview
                                                        </Col>
                                                        <Col className="close">
                                                            <CloseIcon onClick={() => this.onShowPreviewFileDrawer(previewFileDrawer)} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="attach-count">
                                                        <Col className="attach">
                                                            Attachments
                                                        </Col>
                                                        <Col className="count">
                                                            {openFileNo} / {previewFileLength} Documents
                                                        </Col>
                                                    </Row>
                                                    <Row className="preview-detail">
                                                        <Col span={24}>
                                                            <Row className="preview-name-download">
                                                                <Col className="icon-name">
                                                                    {this.getFileIcon(previewIcon)} <span className="name">{previewFileName}</span>
                                                                </Col>
                                                                <Col className="download">
                                                                    <a href={ APIURL + "file/downloadFile?f_id=" + previewFileId + "&token=" + this.user.token} download><WhiteDownloadIcon /></a>
                                                                </Col>
                                                            </Row>
                                                            <Row className="view-file-drawer">
                                                                <Col span={24}>
                                                                    {previewPdfFile && (<PDFView pdfData={uploadFileData} isUrl={false} />)}
                                                                    {previewImgFile && (<div><img src={uploadFileData} alt="img" width="100%"/></div>)}
                                                                    {noPreviewFile && (<div className="no-preview-container"><Card><span>No Preview Available</span><p>You can Download copy of the file</p></Card></div>)}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="nav-icon">
                                                        <Col className="left-nav">
                                                            <LeftNavIcon className={prevFileId == undefined ? "disabled": ""} onClick={() => this.viewFile(prevFileId)}/>
                                                        </Col>
                                                        <Col className="right-nav">
                                                            <RightNavIcon className={nxtFileId == undefined ? "disabled": ""} onClick={() => this.viewFile(nxtFileId)}/>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        }

                                        {   acceptModel &&
                                            (
                                                <div className="document-sign create-outcome-sign">
                                                    <p>Please enter your account password to electronically sign to accept this Proposal. Your name, date and timezone will be recorded on the the document as evidence of your signature.</p>
                                                    <Input.Password id="view-outcome-sign-password" placeholder="Enter your password" value={signedPassword} onChange={(e) => this.signPasswordChange(e)} maxLength={32} />
                                                    <span className="forget-pswd" onClick={() => this.forgotPassword()}>Forgot password?</span>
                                                    <Checkbox id="agreement-sign" checked={agreementSign} onChange={() => this.handleAgreementSign(agreementSign)}>I understand that electronically signing this Proposal evidences my intention to be bound by its contents including 'Details of Proposal' and any Attachment(s).</Checkbox>
                                                </div>
                                            )
                                        }

                                        {   rejectModel &&
                                            (
                                                <div className="document-sign outcome-reject">
                                                    <p className="reject-proposal">Why are you rejecting the Proposal?</p>
                                                    <Select defaultValue={selectedRejReason} className="reject-reason-select" onChange={(e) => this.handleChangeRejectReason(e)} suffixIcon={<ArrowDownIcon />} getPopupContainer={trigger => trigger.parentNode}>
                                                        { rejectReasonList && rejectReasonList.length > 0 
                                                            ? rejectReasonList.map((reject, index) => (
                                                                <React.Fragment key={[index]}>
                                                                    <Option value={reject.reason}>{reject.reason}</Option>
                                                                </React.Fragment>
                                                            ))
                                                            :
                                                                <></>
                                                        }
                                                    </Select>
                                                    <TextArea className="reject-reason" rows={5} placeholder="Please provide a reason why you would like to reject this proposal?" onChange={this.handleRejectDescription} maxLength={500}/>
                                                </div>
                                            )
                                        }

                                        {   appvRejModal &&
                                            (
                                                <div className="document-sign outcome-reject">
                                                    <p className="reject-proposal">Why are you rejecting the Proposal?</p>
                                                    <Select defaultValue={selectedRejReason} className="reject-reason-select" onChange={(e) => this.handleChangeRejectReason(e)} suffixIcon={<ArrowDownIcon />}>
                                                        { rejectReasonList && rejectReasonList.length > 0 
                                                            ? rejectReasonList.map((reject, index) => (
                                                                <React.Fragment key={[index]}>
                                                                    <Option value={reject.reason}>{reject.reason}</Option>
                                                                </React.Fragment>
                                                            ))
                                                            :
                                                                <></>
                                                        }
                                                    </Select>
                                                    <TextArea className="reject-reason mb8" rows={5} placeholder="Please provide a reason why you would like to reject this proposal?" onChange={this.handleRejectDescription} maxLength={500} />
                                                    <p className="reject-proposal">Please enter your account password.</p>
                                                    <Input.Password id="view-outcome-sign-password" placeholder="Enter your password" value={signedPassword} onChange={(e) => this.signPasswordChange(e)} maxLength={32} />
                                                </div>
                                            )
                                        }

                                        {   (selectedProposal && selectedProposal.p_status == 1 && proposalStatus && proposalStatus.status == 1 && proposalStatus.permissions == 'Accept & Sign' && !rejectModel && !acceptModel) &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-reject" className="cancel" disabled={caseInfo && caseInfo.case_status != 3} onClick={() => this.reject()}>Reject</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-accept" className="complete" disabled={caseInfo && caseInfo.case_status != 3} onClick={() => this.accept()}>Accept & Sign</Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        {   rejectModel &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-reject-cancel" className="cancel" onClick={() => this.rejprevious()}>Cancel</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-reject-proposal" className="complete" disabled={!rejectReason || !rejectDescription} onClick={() => this.rejectProposal()}>Reject</Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        {   acceptSign &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-reject-sign" className="cancel" onClick={() => this.rejSign()}>Cancel</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-accept-proposal" className="complete" disabled={!signedPassword || !agreementSign} onClick={() => this.acceptProposal()}>Sign & Complete</Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        {   ((selectedProposal && selectedProposal.p_status != 1 && selectedProposal.p_status != 2) || (proposalStatus && (proposalStatus.status == 2 || proposalStatus.status == 3 || proposalStatus.status == 4 || proposalStatus.permissions == 'Propose' || proposalStatus.permissions == 'Propose & Sign' || proposalStatus.permissions == 'Can View' || proposalStatus.permissions == 'Can Amend / Withdraw'))) &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-close" className="cancel" onClick={() => this.closeproposal()}>Close</Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        {   (selectedProposal && selectedProposal.p_status == 1 && proposalStatus && proposalStatus.permissions == 'Must Approve') &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-close" className="cancel" onClick={() => this.closeproposal()}>Close</Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        {   (selectedProposal && selectedProposal.p_status == 2 && proposalStatus && proposalStatus.permissions == 'Must Approve' && !appvRejModal) &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-reject-approve" className="cancel" disabled={caseInfo && caseInfo.case_status != 3} onClick={() => this.apprRej()}>Reject</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <>
                                                            <Button type="primary" id="outcome-approve-proposal" className="complete" disabled={caseInfo && caseInfo.case_status != 3} onClick={() => this.confirmApprove()}>Approve</Button>
                                                            <Modal className="proposal-confirm-approve" title="Are you sure you want to Approve this Proposal?" visible={isConfirmApproveModal} onOk={() => this.approveProposal()} okText="Confirm" onCancel={() => this.handleApproveCancel()}>
                                                            </Modal>
                                                        </>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        {   appvRejModal &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-reject-previous" className="cancel" onClick={() => this.rejprevious()}>Cancel</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-approve-reject" className="complete" disabled={!rejectReason || !rejectDescription || !signedPassword} onClick={() => this.approveReject()}>Reject</Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Spin>
                </Drawer>

                <Modal 
                    title={userViewType == 'assigned_to' ? <span>Assigned To</span>: (userViewType == 'can_view' ? <span>Can View</span> : <></>)}
                    visible={isUserView}  
                    onCancel={this.closeUserView}
                    className="custom-modal-popup user-view-details"
                    footer={null}>
                        {   userViewParam && userViewParam.length > 0
                                ? userViewParam.map((view_party, index) => (
                                <div className="popover-member-detail" key={index}>
                                    <Avatar src={view_party.user_image}>{view_party.p_name}</Avatar>
                                    <div className="name-role">
                                        <p className="name">{view_party.name}</p>
                                        <p className="role">{view_party.role_name}</p>
                                    </div>
                                </div>
                            ))
                            :
                                <></>
                        }
                    
                </Modal>
            </div>
        );
    }
}
export default OutcomeView;