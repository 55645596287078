import userService from "../services/user.service";
import * as moment from 'moment';

const dateFormats = [
    {format: 'DD/MM/YYYY', value: 'dd/MM/YYYY'},
    {format: 'MM/DD/YYYY', value: 'MM/dd/YYYY'},
    {format: 'MMM DD, YYYY', value: 'MMM dd, YYYY'},
    {format: 'DD MMM YYYY', value: 'dd MMM YYYY'},
    {format: 'DD MMM YYYY', value: 'd MMM yyyy'},
    {format: 'ddd, DD/MM/YYYY', value: 'EEE, dd/MM/YYYY'},
    {format: 'ddd, MM/DD/YYYY', value: 'EEE, MM/dd/YYYY'},
    {format: 'ddd, MMM DD, YYYY', value: 'EEE, MMM dd, YYYY'},
    {format: 'ddd, DD MMM YYYY', value: 'EEE, dd MMM YYYY'},
    {format: 'DD-MM-YYYY', value: 'd-MM-yyyy'},
    {format: 'DD-MM-YYYY', value: 'dd-MM-yyyy'}
];

String.prototype.dateTimeFormat = function () {
    let dateFormat = 'DD/MM/YYYY';
    let timeFormat = 'hh:mm';
    let matchFormat = '';
    const user = userService.getUser();
    if(user) {
        dateFormat = user.date_long;
        timeFormat = user.time;
        dateFormats.forEach((item,index) => {
            if(item.value.toString().toLowerCase() == dateFormat.toString().toLowerCase()) {
                matchFormat = item.format;
            }
        });
    }
    return moment(this).format(((matchFormat) ? matchFormat: dateFormat) + " " + timeFormat);
}
