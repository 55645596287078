import React, { useState, useEffect } from "react";
import { Row, Col, Menu, Drawer, Collapse, Checkbox, DatePicker, Dropdown, Button, Space, Card, Avatar, Select, Skeleton, message } from 'antd';
import {
    useRouteMatch,
    Switch,
    Link,
    useHistory,
    useParams,
} from "react-router-dom";
import Icon from "@ant-design/icons";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import "../../../logics/prototypes";
import * as moment from 'moment';
import { websocketService, socket } from "../../../logics/services/websocket.service";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterSvg from "../../../assets/svg-icons/filter.svg";
import ArrowdownSvg from "../../../assets/svg-icons/keyboard_arrow_down.svg";
import EmptytaskSvg from "../../../assets/svg-icons/empty_task.svg";
import UploadTaskSvg  from "../../../assets/svg-icons/upload_task_icon.svg";
import SignTaskSvg  from "../../../assets/svg-icons/sign.svg";
import PaymentTaskSvg  from "../../../assets/svg-icons/payment.svg";
import GeneralTaskSvg  from "../../../assets/svg-icons/general-task.svg";
import TaskView from "../../../pages/app/CaseView/TaskView";
import close from "../../../assets/svg-icons/close.svg";
import AddSvg from "../../../assets/svg-icons/add.svg";
import MinimizeSvg from "../../../assets/svg-icons/minimize.svg";

const FilterIcon = props => <Icon component={FilterSvg} style={{ fontSize: 24, verticalAlign: 'middle', float: 'right'}} {...props} />;
const DropDownIcon = props => <Icon component={ArrowdownSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const EmptyTaskIcon = props => <Icon component={EmptytaskSvg} style={{ fontSize: 236, verticalAlign: 'middle'}} {...props} />;
const UploadTaskIcon = props => <Icon component={UploadTaskSvg} style={{ fontSize: 16, verticalAlign: 'middle'}} {...props} />;
const SignTaskIcon = props => <Icon component={SignTaskSvg} style={{ fontSize: 16, verticalAlign: 'middle'}} {...props} />;
const PaymentTaskIcon = props => <Icon component={PaymentTaskSvg} style={{ fontSize: 16, verticalAlign: 'middle'}} {...props} />;
const GeneralTaskIcon = props => <Icon component={GeneralTaskSvg} style={{ fontSize: 16, verticalAlign: 'middle'}} {...props} />;
const CloseIcon = props => <Icon component={close} style={{ fontSize: 24, verticalAlign: "middle" }} {...props} />;
const AddIcon = props => <Icon component={AddSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px', marginTop: '2px'}} {...props} />;
const MinimizeIcon = props => <Icon component={MinimizeSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;

const { Panel } = Collapse;
const tabList = [
    {
      key: 'tab1',
      tab: 'All'
    },
    {
      key: 'tab2',
      tab: 'Due Today'
    },
    {
      key: 'tab3',
      tab: 'Overdue'
    },
    {
        key: 'tab4',
        tab: 'Completed'
      },
];
const { Option } = Select;

const taskStatusList = [
    { label: 'Pending', value: 'Pending', checked:false},
    { label: 'Completed', value: 'Completed', checked:false},
    // { label: 'Withdraw', value: 'Withdraw', checked:false},
    { label: 'Overdue', value: 'Overdue', checked:false}
]; 
const taskTypeList = [
    { label: 'File Upload', value: 'file', checked:false},
    { label: 'Sign Document', value: 'task', checked:false},
    { label: 'General', value: 'general', checked:false}
];

function Tasks() {
    let { caseId, caseDetailView } = useParams();
    const history = useHistory();
    const [detailLink, setDetailLink] = useState(caseDetailView);
    let link = caseDetailView ? JSON.parse(atob(caseDetailView)): '';
    if(detailLink != caseDetailView) {
        setDetailLink(caseDetailView);
    }
    const user = userService.getUser();
    const loaderList = [1,2,3,4];
    const scrollHeight = 350;
    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(1);
    const [activeTabKey1, setActiveTabKey1] = useState('tab1');
    const [taskList, setTaskList] = useState([]);
    const [taskTotalCount, setTaskTotalCount] = useState(0);
    const [initLoading, setInitLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [taskType, setTaskType] = useState('all');
    const [caseParties, setCaseParties] = useState([]);
    const [taskAssigned, setTaskAssigned] = useState("all");
    const [taskAssignee, setTaskAssignee] = useState(0);
    const [taskView, setTaskView] = useState(false);
    const [taskId, setTaskId] = useState(0);
    const [visible, setVisible] = useState(false);
    const [caseCanViewPartiesList, setCaseCanViewPartiesList] = useState([]);
    const [createdFromDate, setCreatedFromDate] = useState('');
    const [createdToDate, setCreatedToDate] = useState('');
    const [dueFromDate, setDueFromDate] = useState('');
    const [dueToDate, setDueToDate] = useState('');
    const [caseCanViewParties, setCaseCanViewParties] = useState([]);
    const [taskStatus, setTaskStatus] = useState([]);
    const [taskFilterType, setTaskFilterType] = useState([]);
    const [filterparamsData, setFilterparamsData] = useState([]);
    const onTab1Change = key => {
        setInitLoading(true);
        setTaskList([]);
        setTaskAssigned("all");
        setTaskAssignee(0);
        setActiveTabKey1(key);
        let taskTypeTab = taskType;
        if(key=='tab1'){
            taskTypeTab = 'all';
        }else if(key=='tab2'){
            taskTypeTab = 'duetoday';
        }else if(key=='tab3'){
            taskTypeTab = 'overdue';
        }else if(key=='tab4'){
            taskTypeTab = 'completed';
        }
        setTaskType(taskTypeTab);
        getTaskList(taskTypeTab, 1, [], 0, filterparamsData);
    };

    socket.onmessage = resData => {
        let res = (resData && resData.data) ? JSON.parse(resData.data): [];
        if(res && res.method == 'task' && res.data && res.data.case_id == caseId && res.data.users.indexOf(user.id) != -1 && res.isDisplay) {  
            getTaskList(taskType, 1, [], 0, filterparamsData);    
        }
    }

    const getTaskList = (taskTypeTab = taskType, pageIndexTab = pageIndex, taskListTab = taskList, taskAssigneeTab = taskAssignee, filterparams = filterparamsData) => {
        setHasMore(true);
        let sortOrder;
        let sortField;
        if (sortOrder == 'ascend') {
            sortOrder = 'asc';
        }  else if (sortOrder == 'descend') {
            sortOrder = 'desc';
        }
        if (sortField == null) sortField = ''; 
        if (sortOrder == null) sortOrder = ''; 
        var offset = (pageIndexTab - 1) * pageSize;
        let param = {
            type: taskTypeTab,
            case_id: caseId,
            overall_search: '',
            assignee: taskAssigneeTab,
            page: pageIndexTab,
            offset: offset,
            results: pageSize,
            sortField: sortField,
            sortOrder: sortOrder
        };
        let paramData = {...param, ...filterparams};
        apiService.getTaskList(paramData).then((res) => {
            if (res.status === 'success') {
                setTaskList(taskListTab.concat(res.data));
                setTaskTotalCount(res.count);
                setInitLoading(false);
                setPageIndex(pageIndexTab + 1);
                var nextOffset = ((pageIndexTab) * pageSize);
                if (res.count <= nextOffset) {  
                    setHasMore(false);
                }
            }
        });
    }

    const applyFilter = () => {
        var nf_created_on_from =''; var nf_created_on_to =''; 
        if (createdFromDate != '' && createdFromDate != undefined && createdFromDate != null) {
            nf_created_on_from = formatDate(createdFromDate);
        } 
        if (createdToDate != '' && createdToDate != undefined && createdToDate != null) {
            nf_created_on_to = formatDate(createdToDate);
        } 
        if(nf_created_on_from != '' && nf_created_on_to != '') {
            if (nf_created_on_to < nf_created_on_from) {
                message.error('Created On From date should not greater than to date.');
                return;
            }
        } else if (nf_created_on_from != '' || nf_created_on_to != ''){
            if (nf_created_on_from == '') {
                message.error('Created On From date cannot be blank.');
                return;
            }
            if (nf_created_on_to == '') {
                message.error('Created On To date cannot be blank.');
                return;
            }
        } 
        var nf_due_on_from =''; var nf_due_on_to =''; 
        if (dueFromDate != '' && dueFromDate != undefined && dueFromDate != null) {
            nf_due_on_from = formatDate(dueFromDate);
        } 
        if (dueToDate != '' && dueToDate != undefined && dueToDate != null) {
            nf_due_on_to = formatDate(dueToDate);
        } 
        if(nf_due_on_from != '' && nf_due_on_to != '') {
            if (nf_due_on_to < nf_due_on_from) {
                message.error('Due On From date should not greater than to date.');
                return;
            }
        } else if (nf_due_on_from != '' || nf_due_on_to != ''){
            if (nf_due_on_from == '') {
                message.error('Due On From date cannot be blank.');
                return;
            }
            if (nf_due_on_to == '') {
                message.error('Due On To date cannot be blank.');
                return;
            }
        }
        
        let filterparamsData = {
            created_on_from: nf_created_on_from,
            created_on_to: nf_created_on_to,
            due_on_from: nf_due_on_from,
            due_on_to: nf_due_on_to,
            caseCanViewParties: JSON.stringify(caseCanViewParties),
            task_type: taskFilterType && taskFilterType.length > 0 ? "'"+taskFilterType.join("','")+"'" : '',
            task_status: taskStatus && taskStatus.length > 0 ? "'"+taskStatus.join("','")+"'" : ''
        };
        setFilterparamsData(filterparamsData);
        setVisible(false);
        getTaskList(taskType, 1, [], taskAssignee, filterparamsData);     
    }

    const clearFilters = () => {
        setCreatedFromDate('');
        setCreatedToDate('');
        setDueFromDate('');
        setDueToDate('');
        setCaseCanViewParties([]);
        setTaskStatus([]);
        setTaskFilterType([]);
        setFilterparamsData([]);
        setVisible(false);
        getTaskList(taskType, 1, [], taskAssignee, []); 
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const getTaskParties = () => {
        apiService.getTaskParties({
            case_id: caseId
        }).then(res => {
            if (res.status === 'success') {
                let parties = [];
                res.data.forEach(element => {
                    if (user.id == element.u_id) {
                        parties.unshift(element);
                    } else {
                        parties.push(element);
                    }
                });
                setCaseParties(parties);
            }
        });
    }

    const getCanViewParties = () => {
        apiService.getTaskParties({
            case_id: caseId,
            permission: 'Can view all responses'
        }).then(res => {
            if (res.status === 'success') {
                let parties = [];
                res.data.forEach(element => {
                    let canView = {label: element.name, value: element.u_id}
                    if (user.id == element.u_id) {
                        parties.unshift(canView);
                    } else {
                        parties.push(canView);
                    }
                });
                setCaseCanViewPartiesList(parties);
            }
        });
    }

    const handleTaskAssignedChange = (key) => {
        setInitLoading(true);
        setTaskAssigned(key);
        let taskAssign = key;
        if(key == "all") {
            taskAssign = 0;
        }
        setTaskAssignee(taskAssign);
        getTaskList(taskType, 1, [], taskAssign);
    }

    const showTaskView = (task_id) => {
        setTaskId(task_id);
        setTaskView(true);
    };

    const closeTaskView = (val) => {
        setTaskView(val);
        getTaskList(taskType, 1, [], taskAssignee, filterparamsData);
    }

    useEffect(() => {
        getTaskList();
        getTaskParties();
        getCanViewParties();
        if(link && link.link_id) {
            apiService.getSingleTask({
                tk_id: link.link_id
            }).then(res => {
                if (res.status == 'success') {
                    if (res.data.assigned_to.findIndex(ele => ele.u_id === user.id) !== -1 || res.data.assigned_by.u_id == user.id) {
                        showTaskView(link.link_id);
                    }
                } else {
                    message.error(res.message);
                }
            });
        }
    }, [detailLink]);

    const showDrawer = () => {
        setVisible(!visible);
        document.body.style.overflow = 'hidden';
    };
    
    const onClose = () => {
        setVisible(!visible);
        document.body.style.overflow = 'unset';
    };

    const createdFromDateChange = (date, dateString) => {
        setCreatedFromDate(dateString);
    };

    const createdToDateChange = (date, dateString) => {
        setCreatedToDate(dateString);
    };

    const dueFromDateChange = (date, dateString) => {
        setDueFromDate(dateString);
    };

    const dueToDateChange = (date, dateString) => {
        setDueToDate(dateString);
    };

    const onCanViewChange = (key) => {
        setCaseCanViewParties(key);
    };

    const onTaskStatusChange = (key) => {
        setTaskStatus(key);
    };

    const onTaskTypeChange = (key) => {
        setTaskFilterType(key);
    };

    return (
        <div>
            <Row className="page-header">
                <Col span={12}>
                    Task
                </Col>
                <Col span={12} onClick={showDrawer}>
                    <FilterIcon />
                </Col>
            </Row>
            <Row className="task-block-container">                
                <Col span={24}>
                    <Card
                        tabList={tabList}
                        activeTabKey={activeTabKey1}
                        onTabChange={key => {
                            onTab1Change(key);
                        }}
                    >
                        <div className="assign-block">
                            <span className="assigned-to">Assigned to:
                                <Select name="task-assigned" id="task-assigned" value={taskAssigned} onChange={handleTaskAssignedChange}>
                                    <Option value="all">All Assignees</Option>
                                    { caseParties && caseParties.length > 0 
                                        ? caseParties.map((party, index) => (
                                            <React.Fragment key={[index]}>
                                                <Option value={party.u_id}>{party.u_id == user.id ? 'You' : party.name}</Option>
                                            </React.Fragment>
                                        ))
                                        :
                                            <></>
                                    }
                                </Select>
                            </span>
                        </div>

                        {initLoading
                            ?
                                loaderList.map((item, index) => (
                                    <Card key={index}>
                                        <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                    </Card>
                                ))
                            :
                                <InfiniteScroll
                                    dataLength={taskList.length}
                                    next={getTaskList}
                                    hasMore={hasMore}
                                    loader={loaderList.map((item, index) => (<Card key={index}>
                                        <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                    </Card>))}
                                >
                                    { taskList && taskList.length > 0 
                                        ? taskList.map((item, index) => (
                                            <div className="all-task-list" key={index}>
                                                <Row onClick={() => showTaskView(item.task_id)}>
                                                    <Col span={3}><Row className="task-title-icon">{item.type == "task" ? <SignTaskIcon/>: item.type == "file" ? <UploadTaskIcon/>: item.type == "payment" ? <PaymentTaskIcon/>: <GeneralTaskIcon/>}</Row></Col>
                                                    <Col span={21}>
                                                        <Row className="task-title">{item.title}</Row>
                                                        <Row className="task-due-date">
                                                            <b>Due Date</b> {item.task_due_on.utcLocal().dateTimeFormat()} 
                                                            <span className={item.task_status == "Overdue" ? "text-red": item.task_status == "Completed" ? "text-green": item.task_status == "Pending" ? "text-orange": ""}>{item.task_status}</span>
                                                        </Row>
                                                    </Col>                               
                                                </Row>
                                            </div>
                                        ))
                                        :
                                            <div className="empty-nocontent">
                                                <span>
                                                    <EmptyTaskIcon/>
                                                </span>
                                                <p className="no-yet">
                                                    No Task assigned yet !
                                                </p>
                                            </div>
                                    }
                                </InfiniteScroll>
                        }
                        
                        {taskView && (<TaskView caseId={caseId} visible={taskView} taskId={taskId} user={user} history={history} closeTaskViewVisible={closeTaskView}/>)}

                    </Card>
                </Col>
            </Row>
            <Drawer
                placement="bottom"
                closable={false}
                mask={false}
                className="task-filter-menu"
                contentWrapperStyle={{ bottom: 0, borderRadius: "4px 4px 0px 0px" }}
                height={360}
                onClose={onClose}
                visible={visible}
                zIndex={1200}
                style={{ position: "fixed" }}
            >
                <div>
                <Row className="filter-header">
                    <Col>Filter</Col>
                    <Col>
                    <CloseIcon onClick={onClose}/>
                    </Col>
                </Row>
                <Collapse 
                    defaultActiveKey={['1']} 
                    expandIconPosition={"right"} 
                    expandIcon={({ isActive }) => isActive ? <MinimizeIcon /> : <AddIcon />}
                    //onChange={onChange}
                >
                    <Panel header="Created on" key="1">
                        <Row>
                            <Col span={24}>
                                <div className="from-content">
                                    <div className="date-range-label">From</div>
                                    <div className="date-range-picker">
                                        <DatePicker 
                                            value={createdFromDate !== "" ? moment(createdFromDate) : ""} 
                                            onChange={createdFromDateChange}
                                            disabledDate={(current) => {
                                                return current && current > moment().endOf('day');
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ clear:'both', marginBottom: '10px' }}></div>
                                    <div  className="from-content">
                                        <div className="date-range-label">To</div>
                                        <div className="date-range-picker">
                                            <DatePicker 
                                                value={createdToDate !== "" ? moment(createdToDate) : ""} 
                                                onChange={createdToDateChange}
                                                disabledDate={(current) => {
                                                    return current && current > moment().endOf('day');
                                                }}
                                            />
                                        </div>
                                    </div>
                                <div style={{ clear:'both'}}></div>
                            </Col>
                        </Row>                            
                    </Panel>
                    <Panel header="Due date" key="2">
                        <Row>
                            <Col span={24}>
                                <div className="from-content">
                                    <div className="date-range-label">From</div>
                                    <div className="date-range-picker">
                                        <DatePicker value={dueFromDate !== "" ? moment(dueFromDate) : ""} onChange={dueFromDateChange} />
                                    </div>
                                </div>
                                <div style={{ clear:'both', marginBottom: '10px' }}></div>
                                    <div  className="from-content">
                                        <div className="date-range-label">To</div>
                                        <div className="date-range-picker">
                                            <DatePicker value={dueToDate !== "" ? moment(dueToDate) : ""} onChange={dueToDateChange} />
                                        </div>
                                    </div>
                                <div style={{ clear:'both'}}></div>
                            </Col>
                        </Row>   
                    </Panel>
                    <Panel header="Can view" key="3">
                       <Row>
                           <Col>
                           <Checkbox.Group style={{ width: '100%' }} value={caseCanViewParties} onChange={onCanViewChange}>
                                <Row>
                                    {   caseCanViewPartiesList && caseCanViewPartiesList.length > 0
                                        ? caseCanViewPartiesList.map((canViewPaties, index) => (
                                            <Col span={24} key={index}>
                                                <Checkbox value={canViewPaties.value}>{canViewPaties.label}</Checkbox>
                                            </Col>
                                        ))
                                        :
                                            <></>
                                    }
                                </Row>
                            </Checkbox.Group>
                           </Col>
                       </Row>
                    </Panel>
                    <Panel header="Task status" key="4">
                       <Row>
                           <Col>
                           <Checkbox.Group style={{ width: '100%' }} value={taskStatus} onChange={onTaskStatusChange}>
                                <Row>
                                    {   taskStatusList && taskStatusList.length > 0
                                        ? taskStatusList.map((taskStatus, index) => (
                                            <Col span={24} key={index}>
                                                <Checkbox value={taskStatus.value}>{taskStatus.label}</Checkbox>
                                            </Col>
                                        ))
                                        :
                                            <></>
                                    }
                                </Row>
                            </Checkbox.Group>
                           </Col>
                       </Row>
                    </Panel>
                    <Panel header="Task type" key="5">
                       <Row>
                           <Col>
                           <Checkbox.Group style={{ width: '100%' }} value={taskFilterType} onChange={onTaskTypeChange}>
                                <Row>
                                    {   taskTypeList && taskTypeList.length > 0
                                        ? taskTypeList.map((taskType, index) => (
                                            <Col span={24} key={index}>
                                                <Checkbox value={taskType.value}>{taskType.label}</Checkbox>
                                            </Col>
                                        ))
                                        :
                                            <></>
                                    }
                                </Row>
                            </Checkbox.Group>
                           </Col>
                       </Row>
                    </Panel>
                </Collapse>
                <Row className="filter-footer">
                    <Col id="task-clear-filter" className="clear-filter" onClick={() => clearFilters()}>Clear Filter</Col>
                    <Button id="task-apply-filter" type="primary" size="small" className="textright" onClick={() => applyFilter()}>
                        Apply Filter
                    </Button>
                </Row>
                </div>
            </Drawer>
        </div>
    );
}
export default Tasks;