import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Avatar,
  Tag,
  Collapse,
  Skeleton,
  Form,
  Input,
  Button,
  Select,
  Menu,
  Dropdown,
  Space,
  message,
  Modal
} from "antd";
import Icon from "@ant-design/icons";
// import moment from "moment";
import apiService from "../../../logics/services/api.service";
import { useSelector, useDispatch } from "react-redux";
import {
  useRouteMatch,
  Switch,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import userService from "../../../logics/services/user.service";
import closeSVG from "../../../assets/svg-icons/close.svg";
import "./styles.less";
import * as moment from 'moment';

const CloseIcon = (props) => (
  <Icon
    component={closeSVG}
    style={{ fontSize: 24, verticalAlign: "middle" }}
    {...props}
  />
);

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

function handleChange(value) {
  console.log(`Selected: ${value}`);
}

function Calender() {
  const history = useHistory();
  const user = userService.getUser();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [TimeZoneDetails, setTimeZoneDetails] = useState([]);
  const [weekStart, setWeekStart] = useState([]);
  const [calendarId, setCalendarId] = useState([]);
  const [agendaMail, setAgendaMail] = useState([]);
  const [timeformat, setTimeFormat] = useState([]);
  const [timezone, setTimeZone] = useState([]);
  const [shortTime, setShortTime] = useState([]);
  const [longTime, setLongTime] = useState([]);
  const [workDays, setWorkDays] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);


  const [form] = Form.useForm();

  const [activeTabKey1, setActiveTabKey1] = useState("tab2");
  const [placement, SetPlacement] = React.useState("topLeft");
  const [size, setSize] = React.useState("default");
  // let workDays = [];
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const placementChange = (e) => {
    SetPlacement(e.target.value);
  };

  const checkOptionsOne = [
    { label: "Sun", value: "0", checked: false },
    { label: "Mon", value: "1", checked: false },
    { label: "Tue", value: "2", checked: false },
    { label: "Wed", value: "3", checked: false },
    { label: "Thu", value: "4", checked: false },
    { label: "Fri", value: "5", checked: false },
    { label: "Sat", value: "6", checked: false },
  ];
  const weekStarts = [
    { label: "Sunday", value: "0" },
    { label: "Monday", value: "1" },
    { label: "Tuesday", value: "2" },
    { label: "Wednesday", value: "3" },
    { label: "Thursday", value: "4" },
    { label: "Friday", value: "5" },
    { label: "Saturday", value: "6" },
  ];
  const dateFormats = [
    { format: moment().format("DD/MM/YYYY"), value: "dd/MM/YYYY" },
    { format: moment().format("MM/DD/YYYY"), value: "MM/dd/YYYY" },
    { format: moment().format("MMM DD, YYYY"), value: "MMM dd, YYYY" },
    { format: moment().format("DD MMM YYYY"), value: "dd MMM YYYY" },
    { format: moment().format("ddd, DD/MM/YYYY"), value: "EEE, dd/MM/YYYY" },
    { format: moment().format("ddd, MM/DD/YYYY"), value: "EEE, MM/dd/YYYY" },
    {format: moment().format("ddd, MMM DD, YYYY"), value: "EEE, MMM dd, YYYY"},
    { format: moment().format("ddd, DD MMM YYYY"), value: "EEE, dd MMM YYYY" },
  ];
  // dateLongFormats = [
  // 	{format: moment().format('D MMM YYYY'),value: 'd MMM yyyy'},
  // 	{format: moment().format('D MMMM YYYY'),value: 'd MMMM yyyy'},
  // 	{format: moment().format('dddd, D MMMM YYYY'),value: 'EEEE, d MMMM yyyy'},
  // 	{format: moment().format('ddd, MMM D YYYY'),value: 'EEE, MMM d yyyy'}
  // ];
  const timeFormat = [
    { label: "12 hour format", value: "hh:mm a" },
    { label: "24 hour format", value: "HH:mm" },
  ];

  useEffect(() => {
    console.log(user?.date_short);
    console.log(user?.timezone);
    setTimeZone(user?.timezone);
    setShortTime(user?.date_short);

    apiService.getTimeZone().then((res) => {
      if (res.status === "success") {
        setTimeZoneDetails(res.data);
      }
    });

    apiService.getCalenderSetting().then((res) => {
      if (res.status === "success") {
        if (res.data) {
          console.log(res.data.u_cs_id);
          setCalendarId(res.data.u_cs_id);
          setAgendaMail(res.data.agenda_mail);
          setWeekStart(res.data.week_starts?.toString());
          checkOptionsOne.forEach((e) => {
            if (res.data.work_days.indexOf(e.value) >= 0) {
              console.log(e.value + "true");
              setWorkDays(workDays.concat(e.values));
              console.log(workDays + "true");

              e.checked = true;
            } else {
              e.checked = false;
            }
          });
        }
      }
    });
  }, []);

  const setcalenderdetails= () => {
    console.log(user?.date_short);
    console.log(user?.timezone);
    setTimeZone(user?.timezone);
    setShortTime(user?.date_short);

    apiService.getTimeZone().then((res) => {
      if (res.status === "success") {
        setTimeZoneDetails(res.data);
      }
    });

    apiService.getCalenderSetting().then((res) => {
      if (res.status === "success") {
        if (res.data) {
          console.log(res.data.u_cs_id);
          setCalendarId(res.data.u_cs_id);
          setAgendaMail(res.data.agenda_mail);
          setWeekStart(res.data.week_starts?.toString());
          checkOptionsOne.forEach((e) => {
            if (res.data.work_days.indexOf(e.value) >= 0) {
             // console.log(e.value + "true");
              setWorkDays(workDays.concat(e.values));
              e.checked = true;
            } else {
              console.log(e.value);
              e.checked = false;
            }
          });
        }
      }
    });
  }

   const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setcalenderdetails();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = (fieldsValue) => {};

  const Submit = () => {
    let workDays = [];
    checkOptionsOne.forEach((e) => {
      if (e.checked == true) {
        workDays.push(e.value);
      }
    });

    console.log(calendarId);
    console.log(workDays.join(","));

    apiService
      .updateCalender({
        u_cs_id: calendarId,
        agenda_mail: agendaMail,
        week_starts: weekStart,
        time_format: timeformat,
        time_zone: timezone,
        date_format_short: shortTime,
        date_format_long: shortTime,
        work_days: workDays?.join(",")

        // u_cs_id: calendarId,
        // agenda_mail: agendaMail,
        // week_starts: weekStart,
        // work_days: workDays.join(','),
        // time_format: timeformat,
        // time_zone: timezone,
        // date_format_short: shortTime,
        // date_format_long: shortTime,
      })
      .then((res) => {
        if (res.status === "success") {
            message.success("Calender updated successfully.");

        } else {
        }
      });
  };

  const onClose = () => {
    let prev = localStorage.getItem("previousUrl")
      ? localStorage.getItem("previousUrl")
      : "app/allcases";
    history.push(prev);
  };

  const handleClick = (e) => {
    if (e.key === "myprofile") {
      history.push("/app/account/profile");
    } else if (e.key === "payment") {
      history.push("/app/account/payment");
    }
  };

  return (
    <div className="profile-section">
      <Row className="header">
        <Col className="textCenter">
          <CloseIcon onClick={onClose} />
        </Col>
        <Col span={22} className="textCenter accountheading">
          My Account
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Menu
            mode="horizontal"
            onClick={handleClick}
            className="p-menu"
            defaultSelectedKeys={["calendar"]}
          >
            <Menu.Item
              key="myprofile"
              style={{ width: "33%", textAlign: "center" }}
            >
              My Profile
            </Menu.Item>
            <Menu.Item
              key="calendar"
              style={{ width: "33%", textAlign: "center" }}
            >
              Calendar
            </Menu.Item>
            <Menu.Item
              key="payment"
              style={{ width: "33%", textAlign: "center" }}
            >
              Payment
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <div className="profile-inner-section p16">
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item>
            <Form.Item label="Time Zone" name="Timezone" className="w100 mb0">
              <Select
                defaultValue={user?.timezone}
                style={{}}
                dropdownMatchSelectWidth={false}
                placement={placement}
                onChange={setTimeZone}
                optionLabelProp="label" 
                showSearch
              >
                {TimeZoneDetails.map((item, index) => (
                  <Option label={"(UTC " + item.offset + ") " + item.zone_name} value={item.zone_name}>
                    {"(UTC " + item.offset + ") " + item.zone_name}
                  </Option>
                ))}
              </Select>

              {/* <Select size={size} defaultValue="a1" onChange={handleChange} style={{ width: 200 }}>
                {children}
            </Select> */}

              {/* <Input placeholder="Joe" name="Firstname" /> */}
              {/* <DownOutlined/> */}
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Form.Item
              label="Date short format"
              name="DateShortFormat"
              className="w100 mb0"
            >
              <Select
                defaultValue={user?.date_short}
                style={{}}
                dropdownMatchSelectWidth={false}
                placement={placement}
                onChange={setShortTime}
                optionLabelProp="label"
              >
                {dateFormats.map((item, index) => (
                  <Option value={item.value} label={item.format}>
                    { item.format } ({ item.value })
                    {/* {item.country_name} */}
                  </Option>
                ))}
              </Select>
              {/* <Col>For example 12/08/2021 will be displayed as above</Col> */}
            </Form.Item>
          </Form.Item>
          {/* <Form.Item>
                    <Form.Item
                        label="Date long format"
                        name="email"
                        className="w100 mb0"
                    >
                        <Select
                        defaultValue="HangZhou"
                        style={{}}
                        dropdownMatchSelectWidth={false}
                        placement={placement}
                        >

        {timeFormat.map((item, index) => (
                                <Option value={item.value}>
                                {item.label}
                                </Option>
                            ))}
                        <Option value="HangZhou">HangZhou #310000</Option>
                        <Option value="NingBo">NingBo #315000</Option>
                        <Option value="WenZhou">WenZhou #325000</Option>
                        </Select>{" "}
                        <Col>
                        For example Thursday, 12 August 2021 will be displayed as
                        above
                        </Col>
                    </Form.Item>
                    </Form.Item> */}
          <Form.Item>
            <Form.Item
              label="Time format"
              name="TimeFormat"
              className="w100 mb0"
            >
              <Select
                defaultValue="12 hour format"
                style={{}}
                dropdownMatchSelectWidth={false}
                placement={placement}
                onChange={setTimeFormat}
              >
                {timeFormat.map((item, index) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>{" "}
            </Form.Item>
          </Form.Item>

          {/* <Form.Item>
                    <Form.Item
                        label="Week starts on"
                        name="email"
                        className="w100 mb0"
                    >
                        <Select
                        defaultValue="Sunday"
                        style={{}}
                        dropdownMatchSelectWidth={false}
                        placement={placement}
                        >
                        <Option value="Sunday">Sunday</Option>
                        <Option value="Monday">Monday</Option>
                        <Option value="Tuesday">Tuesday</Option>
                        <Option value="Wednesday">Wednesday</Option>
                        <Option value="Thursday">Thursday</Option>
                        <Option value="Friday">Friday</Option>
                        <Option value="Saturday">Saturday</Option>

                        </Select>
                    </Form.Item>
                    </Form.Item> */}
        </Form>
      </div>
      <div className="p-footer">
        <Row gutter={32} style={{ margin: 0 }} justify="space-between">
          <Col span={12}>
            <Button type="default" size="large" className="w100" onClick={showModal}>
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              size="large"
              className="w100"
              onClick={() => Submit()}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </div>

      <Modal
        title="Cancel Changes"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
            <div className="p-footer" style={{ margin: 0}}>
           
            <Row
              gutter={32}
              style={{ margin: 0 }}
              justify="space-between"
            >
              <Col span={12}>
                <Button
                  type="primary"
                  size="large"
                  className="w100"
                  onClick={() => handleOk()}
                >
                  Yes
                </Button>
              </Col>
              <Col span={12}>
              <Button
              size="large"
              className="cancelbutton"
              type="default"
              onClick={()=> handleCancel()}
            >
              Cancel
            </Button>
              </Col>
            </Row>
          </div>
        }
      >
        <Col style={{justify:'center'}}>Changes will be lost. Are you sure?</Col>
      </Modal>
    </div>
  );
}
export default Calender;
