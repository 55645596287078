import React, { useState } from "react";
import { Row, Col, Form, Input, Button, message, Skeleton } from 'antd';
import { Link, useHistory } from "react-router-dom";
import rdologo from '../../assets/rdo-logo.png';
import apiService from "../../logics/services/api.service";
import { useSelector } from "react-redux";

function ForgotPassword() {
    const settings = useSelector(state => state.settings);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onSubmit = (fieldsValue) => {
        setLoading(true);
        apiService.forgotPassword({
            email: fieldsValue['email']
        }).then((res) => {
            setLoading(false);
            if (res.status === 'success') {
                message.success(res.message);
                let path = `/auth/login`; 
                history.push(path);
            } else {
                message.error(res.message)
            }
        });
    }
    return (
        <Row className="max-480 p16 auth-container">
            <Col className="textCenter mb24 mt40" span={24}>
                { settings && <img className="portal-logo" src={settings?.logo} alt="logo" /> }
                { !settings && <Skeleton style={{width: 100, margin: '0 auto'}} active paragraph={false} /> }
            </Col>
            <Col className="textCenter mb40" span={24}>
                <p className="f24">Welcome to the {settings?.site_name } Case Resolution Portal</p>
            </Col>
            <Col span={24}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <h3 className="mb8">Forgot Password</h3>
                    <p className="infoText mb24">Please enter your email address and we'll get you back on track.</p>
                    <Form.Item>
                        <Form.Item label="Email" name='email' className="w100 mb0">
                            <Input placeholder="Username" />
                        </Form.Item> 
                    </Form.Item>
                    <Form.Item>
                        <Button className="w100 br8" loading={loading} type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24} className="textCenter mb40"><Link to={`/auth/login`}>Return to Login</Link></Col>
            <Col span={24} className="powered-by">
                <p>Powered by <img src={rdologo} width="33" alt="" /></p>
            </Col>
        </Row>
    );
}
export default ForgotPassword;