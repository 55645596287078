import React, { useState, useEffect } from "react";
import { Row, Col, Card, Menu, Skeleton } from "antd";
import apiService from "../../../logics/services/api.service";
import { useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import "./styles.less";
import closeSVG from "../../../assets/svg-icons/close.svg";
import { APIURL } from "../../../constants";
import EmptyTransactionSvg from "../../../assets/svg-icons/no-transactions.svg";
import userService from "../../../logics/services/user.service";

const CloseIcon = (props) => (
  <Icon
    component={closeSVG}
    style={{ fontSize: 24, verticalAlign: "middle" }}
    {...props}
  />
);
const EmptyTransactionIcon = (props) => (
  <Icon
    component={EmptyTransactionSvg}
    style={{ fontSize: 236, verticalAlign: "middle" }}
    {...props}
  />
);

function Payment() {
  const history = useHistory();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = userService.getUser();
  const loaderList = [1, 2, 3];

  useEffect(() => {
    setIsLoading(true);
    apiService.getPaymentDetails().then((res) => {
      setIsLoading(false);
      if (res.status === "success") {
        setPaymentDetails(res.data);
      }
    });
  }, []);

  const onClose = () => {
    let prev = localStorage.getItem("previousUrl")
      ? localStorage.getItem("previousUrl")
      : "app/allcases";
    history.push(prev);
  };
  
 const handleDownloadRecipt = (myLink) => () => {
    window.location.href = myLink;
  };
  
  const handleClick = (e) => {
    if (e.key === "calendar") {
      history.push("/app/account/calendar");
    } else if (e.key === "myprofile") {
      history.push("/app/account/profile");
    }
  };

  return (
    <div className="profile-section">
      <Row className="header">
        <Col className="textCenter">
          <CloseIcon onClick={onClose} />
        </Col>
        <Col span={22} className="textCenter">
          My Account
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Menu
            mode="horizontal"
            onClick={handleClick}
            className="p-menu"
            defaultSelectedKeys={["payment"]}
          >
            <Menu.Item
              key="myprofile"
              style={{ width: "33%", textAlign: "center" }}
            >
              My Profile
            </Menu.Item>
            <Menu.Item
              key="calendar"
              style={{ width: "33%", textAlign: "center" }}
            >
              Calendar
            </Menu.Item>
            <Menu.Item
              key="payment"
              style={{ width: "33%", textAlign: "center" }}
            >
              Payment
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      {isLoading ? (
        loaderList.map((item, index) => (
          <Card key={index}>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 2 }}
              className="p8"
            />
          </Card>
        ))
      ) : (
        <div className="profile-inner-section p16">
          {paymentDetails && paymentDetails.length > 0 ? (
            paymentDetails.map((item, index) => (
              <Card className="paymentcard mb24" key={index}>
                <Row
                  className={
                    item.details
                      ? item.details.paid
                        ? "success"
                        : "failure"
                      : "inprogress"
                  }
                  justify="space-between"
                >
                  <Col className="paymentHeading">
                    Case ID : {item.caseInfo.case_ref_no}
                  </Col>
                  <Col className="paymentborder">$ {item.details?.amount}</Col>
                </Row>
                <Row className="paymentcardpadding" justify="space-between">
                  <Col className="paymentsubtitle">Date: {item.date}</Col>
                  <Col className="paymentsubtitle">Time: 12:48 pm</Col>
                </Row>
                <Row className="paymentcardpadding" justify="space-between">
                  <Col className="paymentsubtitle">
                    Charge Id:{" "}
                    {item.details && item.details.charge_id
                      ? item.details.charge_id
                      : "N/A"}{" "}
                  </Col>
                </Row>
                <Row className="paymentcardpadding" justify="space-between">
                  <Col className="paymentsubtitle">
                    Status:{" "}
                    {item.details
                      ? item.details.paid
                        ? "Success"
                        : "Failure"
                      : "Inprogress"}{" "}
                  </Col>
                </Row>
                <Row className="paymentcardpadding" justify="end">
                  <Col className="textRight paymentdownloadrecpit"
		   onClick={handleDownloadRecipt(
                  APIURL +
                    "payment/downloadBurtonReceipt?case_id=" +
                    item.case_id +
                    "&tk_id" +
                    "=" +
                    item.tk_id +
                    "&requested_by=" +
                    item.user_id +
                    "&token=" +
                    user?.token
                )}>
                    Download Receipt{" "}
                  </Col>
                </Row>
              </Card>
            ))
          ) : (
            <div className="empty-nocontent">
              <span>
                <EmptyTransactionIcon />
              </span>
              <p className="no-yet">No Transaction yet !</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default Payment;