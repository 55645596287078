import React, { useState } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Input, Button, Checkbox , Drawer, Select, message, Spin, Card, Tabs, Badge, Avatar, BackTop, Skeleton } from 'antd';
import Icon from '@ant-design/icons';
import { websocketService, socket } from "../../../logics/services/websocket.service";
import "../../../logics/prototypes";
import {withRouter} from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";

import NoNotificationSvg from  '../../../assets/svg-icons/no-notifications.svg';

const NoNotificationIcon = props => <Icon component={NoNotificationSvg} style={{ fontSize: 80, verticalAlign: 'middle'}} {...props} />;

const { TabPane } = Tabs;
const loaderList = [1,2,3];
const scrollHeight = 500;

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.visible=this.props.visible;
        this.notificationType="all";
        this.notificationPage = 0;
        this.count = 25;
        this.offset = 0;
        this.state = {
            notificationVisible: this.visible,
            initLoading: true,
            exceedNotifyRecord: false,
            notificationCount: 0,
            chatCount: 0,
            inviteCount: 0,
            totalCount: 0,
            notifyType: this.notificationType,
            hasMore: false,
            notifyList: [],
            chatList: []
        };
        this.clearNotifications = this.clearNotifications.bind(this);
        
    }

    componentDidMount = async () => {
        let response;
        const caseId = this.caseId;
        this.user = userService.getUser();
        this.getNotificationData();
        this.getNotificationCount();
        this.getChatNotifications();

        socket.onmessage = resData => {
            let res = (resData && resData.data) ? JSON.parse(resData.data): [];
            if (res && res.isDisplay && res.data && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(this.user.id) != -1) {
                const data = res.data;
                this.getNotificationCount();
            } 
            if (res && (res.method == 'message' || res.method == 'chat') && res.data && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(this.user.id) != -1) {
                this.getNotificationCount();
                const data = res.data;
            }
            if (res && res.method == 'chat' && res.data && res.data.type == 'join_conference' && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(this.user.id) != -1) {
                const data = res.data;
            }
        }
    };

    getNotificationCount() {
        apiService.getNotificationCount({
            type:'global'
        })
        .then(res => {
            if (res.status == 'success') {
                this.setState({notificationCount: res.notificationCount, chatCount: res.chatCount, inviteCount: res.inviteCount, totalCount: res.totalCount});
            }
        });
    }

    getNotificationData = () => {
        this.setState({hasMore: true});
        apiService.getUsersNotifications({
            type:this.notificationType,
            offset:this.offset,
            limit:this.count
        }).then((res) => {
            if (res.status === 'success') {
                this.setState({notifyList: this.state.notifyList.concat(res.data.notification), initLoading: false});
                this.notificationPage = this.notificationPage+1;
                var nextOffset = ((this.notificationPage)*this.count); 
                this.offset = nextOffset;
                if (res.data.notificationCount <= nextOffset) {  
                    this.setState({exceedNotifyRecord: true, hasMore: false});
                } else {
                    this.setState({exceedNotifyRecord: false});
                }  
            }
        });
    }

    getNotifications(type) {
        this.setState({initLoading: true, notifyList: [], exceedNotifyRecord: false, hasMore: false});
        this.notificationType = type;
        this.notificationPage = 0;
        //this.notifyData = [];
        this.getNotificationData();
    }

    getChatNotifications() {
        apiService.getChatNotifications({offset:0,limit:this.count,type:'global'})
        .then(res => {
            if (res.status == 'success') {
                this.setState({chatList: res.data});
            }
        });
    }

    clearNotifications() {
        var notifyId = [];
        this.state.notifyList.forEach((paramObj,index) => {
            notifyId.push(paramObj.n_id);
        });
        if (notifyId.length > 0) {
            apiService.clearNotifications({type:this.notificationType, notifyId:notifyId.toString()})
            .then(res => {
                if (res.status == 'success') {
                    this.getNotificationCount();
                    this.getNotifications(this.notificationType);
                }
            });
        }
    }

    onClose = () => {
        this.setState({notificationVisible: false});
        this.props.closeNotificationVisible(false);
    };

    onTabChange = key => {
        this.count = 25;
        this.offset = 0;
        this.setState({notifyList: [], initLoading: true});
        this.notificationType = (key == "invite") ? key: this.state.notifyType;
        this.getNotificationData();
    };

    onUpdatesTabChange = key => {
        this.count = 25;
        this.offset = 0;
        this.setState({notifyList: [], initLoading: true, notifyType: key});
        this.notificationType = key;
        this.getNotificationData();
    };

    navigateToPage(data) {
        let link;
        this.onClose();
        switch (data.type) {
            case 'chat':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/message/'+btoa(JSON.stringify(link)));
                break;
            case 'invite':
                link = {
                    link_id: data.in_id
                };
                if ((data.case_invite && data.case_invite.invite_status != 1) || data.read === 0) {
                    this.props.history.push('/app/caseresponse/'+(data.in_id));
                } else {
                    this.props.history.push('/app/case/'+(data.case_case_id)+'/overview');
                }
                break;
            case 'task':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/tasks/'+btoa(JSON.stringify(link)));
                break;
            case 'calendar':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'keydate':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'file':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/files/'+btoa(JSON.stringify(link)));
                break;
            case 'settlement':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/outcomes/'+btoa(JSON.stringify(link)));
                break;
            case 'decision':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/outcomes/'+btoa(JSON.stringify(link)));
                break;
            case 'questionnaire':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/questionnaires/'+btoa(JSON.stringify(link)));
                break;
            case 'escalation':
                link = {
                    link_id: data.in_id
                };
                this.props.history.push('/app/case/'+(data.case_case_id)+'/overview/'+btoa(JSON.stringify(link)));
                break;
            case 'reminder':
                link = {
                    link_id: data.in_id
                };                
                if (data.description.includes("Task")) {
                    this.props.history.push('/app/case/'+(data.case_case_id)+'/tasks/'+btoa(JSON.stringify(link)));
                } else if (data.description.includes("Questionnaire")) {
                    this.props.history.push('/app/case/'+(data.case_case_id)+'/questionnaires/'+btoa(JSON.stringify(link)));
                } else {
                    this.props.history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                }
                break;
            default:
                this.props.history.push('/app/case/'+(data.case_case_id)+'/overview');
                break;
        }
    }

    render() {
        const { notificationVisible, initLoading, notifyList, exceedNotifyRecord, notificationCount, inviteCount, hasMore } = this.state;
        return (
            <div>
                
                <Drawer
                    title={<Row className="notification-drawer-header"><Col span={16} className="notification-header">Notification</Col> <Col span={8} className="notification-clear-all" onClick={this.clearNotifications}>Clear all</Col></Row>}
                    className="notification-drawer"
                    id="notification-drawer"
                    placement="top"
                    forceRender={true}
                    height={"100%"}
                    onClose={this.onClose}
                    visible={notificationVisible}
                    >
                        
                        <Row className="notification-tab-content">
                            <Tabs defaultActiveKey="1" onChange={this.onTabChange}>
                                <TabPane tab={<span>Updates <Badge count={notificationCount} /></span>} key="1">
                                    <Tabs className="updates-tab" defaultActiveKey="all" onChange={this.onUpdatesTabChange}>
                                        <TabPane tab="All" key="all">
                                            {initLoading?loaderList.map((item, index) => (<Card key={index}>
                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                        </Card>)):
                                                <div id="scrollableDiv"
                                                    style={{
                                                        height: 'calc(100vh - 210px)',
                                                        overflow: 'auto',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <BackTop style={{bottom: 80 }} target={() => document.getElementById("scrollableDiv")} />
                                                    <InfiniteScroll
                                                        dataLength={notifyList.length}
                                                        next={this.getNotificationData}
                                                        hasMore={hasMore}
                                                        loader={loaderList.map((item, index) => (<Card key={index}>
                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                        </Card>))}
                                                        scrollableTarget="scrollableDiv"
                                                    >
                                                        <Row>
                                                            { notifyList.length > 0 
                                                            ? notifyList.map((notify, index) => (
                                                                <Card className="notification-updates" key={index} onClick={() => this.navigateToPage(notify)}>
                                                                    <Row>
                                                                        <Col span={3}><Avatar src={notify.creator.user_image}>{notify.creator.p_name}</Avatar></Col>
                                                                        <Col span={21}>
                                                                            <Row className="notification-content">
                                                                                <Col>
                                                                                    <p>{ notify.creator.name } ({ notify.creator.role_name }) { notify.description }</p>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="notification-case-name-time">
                                                                                <Col span={11} className="notification-time">
                                                                                    <p>{notify.created_at.utcLocal().dateTimeFormat()}</p>
                                                                                </Col>
                                                                                <Col span={13} className="notification-case-name">
                                                                                    <p>{ notify.casedetails.case_name }</p>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            ))
                                                            :
                                                                <div className="global-notification-nocontent">
                                                                    <p className="global-notification-icon">
                                                                        <NoNotificationIcon/>
                                                                    </p>
                                                                    <p className="global-no-yet">
                                                                        No new notifications yet!
                                                                    </p>
                                                                </div>
                                                            }
                                                        </Row>
                                                        { exceedNotifyRecord && notifyList.length > 0
                                                            ?
                                                                <Row className="notification-end-content">
                                                                    <Col>
                                                                        <p>You’ve reached the end of the list! 🎉</p>
                                                                    </Col>
                                                                </Row>
                                                            :
                                                                <></>
                                                        }
                                                    </InfiniteScroll>
                                                </div>
                                            }
                                        </TabPane>
                                        <TabPane tab="Tasks" key="task">
                                            {initLoading?loaderList.map((item, index) => (<Card key={index}>
                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                        </Card>)):
                                                <div id="scrollableTaskDiv"
                                                    style={{
                                                        height: 'calc(100vh - 210px)',
                                                        overflow: 'auto',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <BackTop style={{bottom: 80 }} target={() => document.getElementById("scrollableTaskDiv")} />
                                                    <InfiniteScroll
                                                        dataLength={notifyList.length}
                                                        next={this.getNotificationData}
                                                        hasMore={hasMore}
                                                        loader={loaderList.map((item, index) => (<Card key={index}>
                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                        </Card>))}
                                                        scrollableTarget="scrollableTaskDiv"
                                                    >
                                                        <Row>
                                                            { notifyList.length > 0 
                                                            ? notifyList.map((notify, index) => (
                                                                <Card className="notification-updates" key={index} onClick={() => this.navigateToPage(notify)}>
                                                                    <Row>
                                                                        <Col span={3}><Avatar src={notify.creator.user_image}>{notify.creator.p_name}</Avatar></Col>
                                                                        <Col span={21}>
                                                                            <Row className="notification-content">
                                                                                <Col>
                                                                                    <p>{ notify.creator.name } ({ notify.creator.role_name }) { notify.description }</p>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="notification-case-name-time">
                                                                                <Col span={11} className="notification-time">
                                                                                    <p>{notify.created_at.utcLocal().dateTimeFormat()}</p>
                                                                                </Col>
                                                                                <Col span={13} className="notification-case-name">
                                                                                    <p>{ notify.casedetails.case_name }</p>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            ))
                                                            :
                                                                <div className="global-notification-nocontent">
                                                                    <p className="global-notification-icon">
                                                                        <NoNotificationIcon/>
                                                                    </p>
                                                                    <p className="global-no-yet">
                                                                        No new notifications yet!
                                                                    </p>
                                                                </div>
                                                            }
                                                        </Row>
                                                        { exceedNotifyRecord && notifyList.length > 0
                                                            ?
                                                                <Row className="notification-end-content">
                                                                    <Col>
                                                                        <p>You’ve reached the end of the list! 🎉</p>
                                                                    </Col>
                                                                </Row>
                                                            :
                                                                <></>
                                                        }
                                                    </InfiniteScroll>
                                                </div>
                                            }
                                        </TabPane>
                                        <TabPane tab="Reminders" key="reminders">
                                            {initLoading?loaderList.map((item, index) => (<Card key={index}>
                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                        </Card>)):
                                                <div id="scrollableReminderDiv"
                                                    style={{
                                                        height: 'calc(100vh - 210px)',
                                                        overflow: 'auto',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <BackTop style={{bottom: 80 }} target={() => document.getElementById("scrollableReminderDiv")} />
                                                    <InfiniteScroll
                                                        dataLength={notifyList.length}
                                                        next={this.getNotificationData}
                                                        hasMore={hasMore}
                                                        loader={loaderList.map((item, index) => (<Card key={index}>
                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                        </Card>))}
                                                        scrollableTarget="scrollableReminderDiv"
                                                    >
                                                        <BackTop target={() => document.getElementById("scrollableDiv")} />
                                                        <Row>
                                                            { notifyList.length > 0 
                                                            ? notifyList.map((notify, index) => (
                                                                <Card className="notification-updates" key={index} onClick={() => this.navigateToPage(notify)}>
                                                                    <Row>
                                                                        <Col span={3}><Avatar src={notify.creator.user_image}>{notify.creator.p_name}</Avatar></Col>
                                                                        <Col span={21}>
                                                                            <Row className="notification-content">
                                                                                <Col>
                                                                                    <p>{ notify.creator.name } ({ notify.creator.role_name }) { notify.description }</p>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="notification-case-name-time">
                                                                                <Col span={11} className="notification-time">
                                                                                    <p>{notify.created_at.utcLocal().dateTimeFormat()}</p>
                                                                                </Col>
                                                                                <Col span={13} className="notification-case-name">
                                                                                    <p>{ notify.casedetails.case_name }</p>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            ))
                                                            :
                                                                <div className="global-notification-nocontent">
                                                                    <p className="global-notification-icon">
                                                                        <NoNotificationIcon/>
                                                                    </p>
                                                                    <p className="global-no-yet">
                                                                        No new notifications yet!
                                                                    </p>
                                                                </div>
                                                            }
                                                        </Row>
                                                        { exceedNotifyRecord && notifyList.length > 0
                                                            ?
                                                                <Row className="notification-end-content">
                                                                    <Col>
                                                                        <p>You’ve reached the end of the list! 🎉</p>
                                                                    </Col>
                                                                </Row>
                                                            :
                                                                <></>
                                                        }
                                                    </InfiniteScroll>
                                                </div>
                                            }
                                        </TabPane>
                                    </Tabs>
                                </TabPane>
                                <TabPane tab={<span>Invites <Badge count={inviteCount} /></span>} key="invite">
                                    {initLoading?loaderList.map((item, index) => (<Card key={index}>
                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                        </Card>)):
                                        <div id="scrollableInviteDiv"
                                            style={{
                                                height: 'calc(100vh - 165px)',
                                                overflow: 'auto',
                                                display: 'flex',
                                            }}
                                        >
                                            <BackTop style={{bottom: 80 }} target={() => document.getElementById("scrollableInviteDiv")} />
                                            <InfiniteScroll
                                                dataLength={notifyList.length}
                                                next={this.getNotificationData}
                                                hasMore={hasMore}
                                                loader={loaderList.map((item, index) => (<Card key={index}>
                                                    <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                </Card>))}
                                                scrollableTarget="scrollableInviteDiv"
                                            >
                                                <BackTop target={() => document.getElementById("scrollableDiv")} />
                                                <Row>
                                                    { notifyList.length > 0 
                                                    ? notifyList.map((notify, index) => (
                                                        <Card className="notification-updates" key={index} onClick={() => this.navigateToPage(notify)}>
                                                            <Row>
                                                                <Col span={3}><Avatar src={notify.creator.user_image}>{notify.creator.p_name}</Avatar></Col>
                                                                <Col span={21}>
                                                                    <Row className="notification-content">
                                                                        <Col>
                                                                            <p>{ notify.creator.name } ({ notify.creator.role_name }) { notify.description }</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="notification-case-name-time">
                                                                        <Col span={9} className="notification-time">
                                                                            <p>{notify.created_at.utcLocal().dateTimeFormat()}</p>
                                                                        </Col>
                                                                        <Col span={15} className="notification-case-name">
                                                                            <span className="invite-case-noti">
                                                                                {notify.case_invite && notify.case_invite.invite_status == 1 && notify.case_invite.status != 0 && (<span className="accept-invite">Accepted</span>)}
                                                                                {notify.case_invite && notify.case_invite.invite_status == 2 && notify.case_invite.status != 0 && (<span className="reject-invite">Rejected</span>)}
                                                                                {(notify.case_invite && notify.case_invite.invite_status == 0 && notify.casedetails && notify.casedetails.case_status == 5 ) || (notify.case_invite && notify.case_invite.status == 0) && (<span className="invalid-invite">Invalid</span>)}
                                                                                {notify.case_invite && notify.case_invite.invite_status == 0 && notify.case_invite.status != 0 && notify.casedetails.case_status != 5 && (<span className="pending-invite">Pending</span>)}
                                                                            </span>
                                                                            <p className="notify-case-name">{ notify.casedetails.case_name }</p>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    ))
                                                    :
                                                        <div className="global-notification-nocontent">
                                                            <p className="global-notification-icon">
                                                                <NoNotificationIcon/>
                                                            </p>
                                                            <p className="global-no-yet">
                                                                No new notifications yet!
                                                            </p>
                                                        </div>
                                                    }
                                                </Row>
                                                { exceedNotifyRecord && notifyList.length > 0
                                                    ?
                                                        <Row className="notification-end-content">
                                                            <Col>
                                                                <p>You’ve reached the end of the list! 🎉</p>
                                                            </Col>
                                                        </Row>
                                                    :
                                                        <></>
                                                }
                                            </InfiniteScroll>
                                        </div>
                                    }
                                </TabPane>
                            </Tabs>
                        </Row>
                </Drawer>
            </div>            
        );
    }
}

export default withRouter(Notification);