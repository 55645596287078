import { isExpired, decodeToken } from "react-jwt";
const AUTHTOKEN = 'accessToken';

class UserService {
    getUser() {
        const user = decodeToken(localStorage.getItem(AUTHTOKEN));
        if (user) {
            user.token = localStorage.getItem(AUTHTOKEN);
        }
        return user;
    }

    getToken() {
        return localStorage.getItem(AUTHTOKEN);
    }

    setUserToken(token) {
        localStorage.setItem(AUTHTOKEN, token);
    }

    isLoggedIn() {
        return !isExpired(localStorage.getItem(AUTHTOKEN));
    }

    removeUser() {
        localStorage.removeItem(AUTHTOKEN);
    }

}
export default new UserService();