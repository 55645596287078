import React from "react";
import {
    Route,
    Redirect, 
    useRouteMatch,
    Switch
} from "react-router-dom";
import ForgotEmail from "./ForgotEmail";
import ForgotPassword from "./ForgotPassword";
import SetupAccount from "./SetupAccount";
import Intro from "./Intro";
import Login from "./Login";
import CaseResponse from "./CaseResponse";
import SetPassword from "./SetPassword";
import './styles.less'

// Since routes are regular React components, they
// may be rendered anywhere in the app, including in
// child elements.
//
// This helps when it's time to code-split your app
// into multiple bundles because code-splitting a
// React Router app is the same as code-splitting
// any other React app.

export default function Auth() {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path } = useRouteMatch();
  
    return (
        <div>  
            <Switch>
                <Route path={`${path}/intro`}>
                    <Intro />
                </Route>
                <Route path={`${path}/login`}>
                    <Login />
                </Route>
                <Route path={`${path}/forgotemail`}>
                    <ForgotEmail />
                </Route>
                <Route path={`${path}/forgotpassword`}>
                    <ForgotPassword />
                </Route>
                <Route path={`${path}/setupaccount/:token`}>
                    <SetupAccount />
                </Route>
                <Route path={`${path}/setpassword/:token`}>
                    <SetPassword />
                </Route>
                <Route path={`${path}/caseresponse/:token`}>
                    <CaseResponse />
                </Route>
                <Redirect from="*" to="/auth/intro" />
            </Switch>
        </div>
    );
}