import axios from "axios";
import userService from './user.service';
import { APIURL } from '../../constants';

const token = userService.getToken();
const auth = token ? `Bearer ${token}` : '';

export default axios.create({
  baseURL: APIURL,
  headers: {
    "Content-type": "application/json",
    "Authorization": auth
  }
});