import React from "react";
import {
    Redirect, 
    useRouteMatch,
    Switch
} from "react-router-dom";
import { Layout } from 'antd';
import AllCases from "./AllCases";
import Toolbar from "./Toolbar";
import './styles.less'
import CaseView from "./CaseView/CaseView";
import CaseResponse from "./CaseResponse/CaseResponse";
import PrivateRoute from "../../logics/PrivateRoute";
import AllActivity from "./AllActivity";
import MyAccount from "./my-account/MyAccount";
import Calender from "./my-account/Calender";
import Payment from "./my-account/Payment";

// Since routes are regular React components, they
// may be rendered anywhere in the app, including in
// child elements.
//
// This helps when it's time to code-split your app
// into multiple bundles because code-splitting a
// React Router app is the same as code-splitting
// any other React app.

export default function MainApp() {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path } = useRouteMatch();
    const { Header, Content } = Layout;
    return (
        <Layout>
            <Header>
                <Toolbar />
            </Header>
            <Content>
                <Switch>
                    <PrivateRoute path={`${path}/allcases`} component={AllCases} />
                    <PrivateRoute path={`${path}/allcases-activity`} component={AllActivity} />
                    <PrivateRoute path={`${path}/account/profile`} component={MyAccount} />
                    <PrivateRoute path={`${path}/account/calendar`} component={Calender} />
                    <PrivateRoute path={`${path}/account/payment`} component={Payment} />
                    <PrivateRoute path={`${path}/case/:caseId/:caseDetail/:caseDetailView`} component={CaseView} />
                    <PrivateRoute path={`${path}/case/:caseId`} component={CaseView} />
                    <PrivateRoute path={`${path}/caseresponse/:token`} component={CaseResponse} />
                    <Redirect from="*" to="/app/allcases" />
                </Switch>
            </Content>
        </Layout>
    );
}