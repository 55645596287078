import React, { useState, useEffect } from "react";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';


function PdfView(props) {

    let pdfUrl;
    if(props.isUrl) {
        pdfUrl = props.pdfData;
    } else {
        pdfUrl = "data:application/pdf;base64," + props.pdfData;
    }

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
        ],
    });

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <Viewer
                fileUrl={pdfUrl}
                plugins={[
                    defaultLayoutPluginInstance,
                ]}
            />
        </Worker>
    );
}

export default PdfView;