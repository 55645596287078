import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiService from "../../logics/services/api.service";
import { Row, Col, Skeleton, message, Popover } from 'antd';
import { useSelector } from "react-redux";
import Icon from "@ant-design/icons";
import CommonCaseResponse from "../../components/CommonCaseResponse/CommonCaseResponse";

import HelpInfoSvg from "../../assets/svg-icons/help_info.svg";
import CloseSvg from "../../assets/svg-icons/close.svg";
import "../auth/styles.less";

const HelpInfoIcon = props => <Icon component={HelpInfoSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const CloseIcon = props => <Icon component={CloseSvg} style={{ fontSize: 24, verticalAlign: 'middle', float: 'right'}} {...props} />;

function CaseResponse() {

    const settings = useSelector(state => state.settings);
    const history = useHistory();
    let { token } = useParams();
    
    const [caseId, setCaseId] = useState();
    const [showCaseResponse, setShowCaseResponse] = useState(false);
    const [caseResponseDrawerVisible, setCaseResponseDrawerVisible] = useState(false);

    useEffect(() => {
        apiService.inviteeToken(token)
        .then(res => {
            if(res.data.portal_user > 0) {
                if(res.data.isMessage) {
                    message.error(res.message);   
                }
                history.push('/auth/login');
            } else if (res.status === 'success') {
                setCaseId(res.data.case_id);
                setShowCaseResponse(true);
            } else if (res.status === 'error') {
                if(res.data.isMessage) {
                    message.error(res.message);   
                }
                if(res.data && res.data.user_id == 0) {                        
                    history.push('/auth/accessdenied');
                } else if(res.data && res.data.user_id != 0) {
                    history.push('/auth/login');
                } else {
                    history.push('/auth/login');
                }
            }
        });
    });

    const closeCaseResponse = (event) => {
      if (event && event.status == 'success') {
        history.push('/app/case/'+(caseId)+'/overview');
      }
    }

    const opnCaseResponseDrawer = () => {
        setCaseResponseDrawerVisible(true);
        document.body.style.overflow = 'hidden';
    }

    const closeCaseResponseDrawer = () => {
        setCaseResponseDrawerVisible(false);
        document.body.style.overflow = 'unset';
    }

    const getHelpContent = () => {
        return (
            <p> Welcome to the { settings?.site_name } Case Resolution Platform!<br/><br/>

                You have been invited to this Platform because - <br/>
                a. You are a Consumer and you have filed a complaint on a business establishment on the { settings?.site_name } Website, or<br/>
                b. You represent a Business and a complaint has been filed on your business establishment on the { settings?.site_name } Website<br/><br/>

                When you accept this invitation, as a Consumer or Business Representative, this Platform allows you to work with your counterparts on the complaint/case and { settings?.site_name } Officers to seek resolution in an easy-to-use and secure setting. Interactions between the parties, files shared, appointments made and outcomes obtained are duly recorded and available to you for future reference.<br/><br/>

                As a Consumer, if you choose to reject the case, the complaint raised by you on our Website will be closed and no further action may be taken.<br/>
                As a representative of the Business, if you choose to reject the case, your accreditation score may have an impact.<br/><br/>

                Please reach out to a { settings?.site_name } contact if you have any questions.&#58;<br/> Participant does not need to act on this item&#44; will not have access to it&#44; and will not be able to view any details of the item.
            </p>
        );
    }

    return (
      <div className="case-response-container">
        <Row className="mt10">
            <Col className="case-response-header-logo" span={12}>
                { settings && <img className="portal-logo" src={settings?.logo} alt="logo" /> }
                { !settings && <Skeleton style={{width: 100, margin: '0 auto'}} active paragraph={false} /> }
            </Col>
            <Col className="case-response-header-help" span={12}>
                <Popover
                    content={getHelpContent}
                    title={<CloseIcon onClick={closeCaseResponseDrawer}/>}
                    trigger="click"
                    visible={caseResponseDrawerVisible}
                    extra={<CloseIcon onClick={closeCaseResponseDrawer}/>}
                >
                    <a onClick={opnCaseResponseDrawer}>Help to decide &nbsp;<HelpInfoIcon/></a>
                </Popover>
            </Col>
        </Row>
        { showCaseResponse &&
          (<CommonCaseResponse type={0} caseId={caseId} inviteId={token} history={history} closeCaseResponseVisible={closeCaseResponse} />)
        }
      </div>
    );
}
export default CaseResponse;
