import userService from "./user.service";
const ws = new WebSocket(window['odrWsUrl']);
export const socket = ws;

class WebsocketService {

    send(data) {
        const user = userService.getUser();
        if (!data.data.customFrom) {
            data.data.from = {
                'u_id': user.id,
                'p_name': user.p_name,
                'user_image': user.image,
                'color': user.color,
                'name': user.name,      
                'role_name': user.role_name      
            }
        }
        ws.send(JSON.stringify(JSON.stringify(data)));
    }
}
export const websocketService = new WebsocketService();