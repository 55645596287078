import React, { useState, useEffect } from "react";
import Icon from '@ant-design/icons';
import './styles.less';
import CaseStatus from "./CaseStatus";

import CaseCreateSvg from  '../../../assets/svg-icons/case_create.svg';
import CaseInvitationResponseSvg from  '../../../assets/svg-icons/case_invitation_response.svg';
import CaseDiscussionSvg from  '../../../assets/svg-icons/case_discussion.svg';
import CaseMediationSvg from  '../../../assets/svg-icons/case_mediation.svg';
import CaseDecisionSvg from  '../../../assets/svg-icons/case_dc.svg';
import CaseClosedSvg from  '../../../assets/svg-icons/case_closed.svg';

export default function CaseStatusIcon(props) {

    const CaseCreateIcon = prop => <Icon component={CaseCreateSvg} style={{ fontSize: props.size, verticalAlign: 'middle'}} {...prop} />;
    const CaseInvitationResponseIcon = prop => <Icon component={CaseInvitationResponseSvg} style={{ fontSize: props.size, verticalAlign: 'middle'}} {...prop} />;
    const CaseDiscussionIcon = prop => <Icon component={CaseDiscussionSvg} style={{ fontSize: props.size, verticalAlign: 'middle'}} {...prop} />;
    const CaseMediationIcon = prop => <Icon component={CaseMediationSvg} style={{ fontSize: props.size, verticalAlign: 'middle'}} {...prop} />;
    const CaseDecisionIcon = prop => <Icon component={CaseDecisionSvg} style={{ fontSize: props.size, verticalAlign: 'middle'}} {...prop} />;
    const CaseClosedIcon = prop => <Icon component={CaseClosedSvg} style={{ fontSize: props.size, verticalAlign: 'middle'}} {...prop} />;

    const [caseStatus, setCaseStatus] = useState(false);

    const showCaseStatus = () => {
        setCaseStatus(true);
    };

    const closeCaseStatus = (val) => {
        setCaseStatus(val);
    }

    return (
        <>
            {props.case_status == 0 && (<CaseCreateIcon onClick={showCaseStatus}/>)}
            {(props.case_status == 1 || props.case_status == 2) && (<CaseInvitationResponseIcon onClick={showCaseStatus}/>)}
            {(props.case_status == 3 && props.case_type == 1) && (<CaseDiscussionIcon onClick={showCaseStatus}/>)}
            {(props.case_status == 3 && props.case_type == 2) && (<CaseMediationIcon onClick={showCaseStatus}/>)}
            {(props.case_status == 3 && props.case_type == 3) && (<CaseDecisionIcon onClick={showCaseStatus}/>)}
            {(props.case_status == 4 || props.case_status == 5 || props.case_status == 6 || props.case_status == 7) && (<CaseClosedIcon onClick={showCaseStatus}/>)}

            { caseStatus 
                ?
                    <CaseStatus caseId={props.caseId} visible={caseStatus} closeCaseStatusVisible={closeCaseStatus}/>
                :
                    <></>
            }
        </>
    );
}