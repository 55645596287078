import userService from "../services/user.service";
import * as moment from 'moment';

String.prototype.timeFormat = function () {
    let timeFormat = 'hh:mm a';
    const user = userService.getUser();
    if(user) {
        timeFormat = user.time;
    }
    return moment(this).format(timeFormat);
}
