import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    Avatar,
    Tag,
    Collapse,
    Button,
    Timeline,
    Drawer,
    Skeleton,
    Divider
} from "antd";
import apiService from "../../../logics/services/api.service";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import Icon from "@ant-design/icons";
import { useRouteMatch, Switch, Link, useParams } from "react-router-dom";
import filter from "../../../assets/svg-icons/filter.svg";
import close from "../../../assets/svg-icons/close.svg";
import emptySearch from "../../../assets/svg-icons/empty_search.svg";
import { useHistory } from "react-router-dom";
import "../../../logics/prototypes";


const Filtericon = (props) => (
    <Icon
        component={filter}
        style={{ fontSize: 24, verticalAlign: "middle" }}
        {...props}
    />
);

const Closeicon = (props) => (
    <Icon
        component={close}
        style={{ fontSize: 24, verticalAlign: "middle" }}
        {...props}
    />
);

const EmptySearchIcon = (props) => (
    <Icon
        component={emptySearch}
        style={{ fontSize: 140, verticalAlign: "middle" }}
        {...props}
    />
);

function Activity() {
    let { caseId } = useParams();
    const history = useHistory();
    const [activityDetails, setActivityDetails] = useState([]);
    const [activityCount, setActivityCount] = useState([]);
    const [activityPage, setActivityPage] = useState([]);
    const [activityDetailsGroup, setActivityDetailsGroup] = useState([]);
    const [offset, setOffset] = useState(0);
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [exceedNotifyRecord,setExceedNotifyRecord] = useState(false);
    const [page, setPage] = useState(1);
    const [filtervalue, setFilterValue] = useState("");

    const loaderList = [1, 2, 3];
    const count = 20;
    let pagesize = 0;

    const { Panel } = Collapse;
    let caseTags = [];

    caseTags["case"] = "Case Updates";
    caseTags["task"] = "Tasks";
    caseTags["calendar"] = "Meetings";
    caseTags["keydate"] = "Key Dates";
    caseTags["meeting"] = "Meetings";
    caseTags["file"] = "Files & Folders";
    caseTags["settlement"] = "Case Outcomes";
    caseTags["questionnaire"] = "Questionnaires";

    // useEffect(() => {
    //     apiService.getCaseActivity({
    //         case_id: caseId,
    //         all: true,
    //         offset: offset,
    //         limit:20,
    //         type:'',
    //         search:''
    //     }).then((res) => {
    //         if (res.status === 'success') {
    //             dispatch({ type: 'store', value: res.data});
    //             setActivityDetails(res.data);
    //         }
    //     });
    // }, [caseId, dispatch]);

    const groupBy = (collection, keyGetter) => {
        if (!collection) {
            return null;
        }
        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[keyGetter]]) {
                previous[current[keyGetter]] = [current];
            } else {
                previous[current[keyGetter]].push(current);
            }
            return previous;
        }, {});
        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map((key) => ({
            key,
            value: groupedCollection[key],
        }));
        // // Return the end result
        // return array.reduce((result, currentValue) => {
        //   // If an array already present for key, push it to the array. Else create an array and push the object
        //   (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        //   // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        //   return result;
        // }, {}); // empty object is the initial value for result object
    };

    const fetchMoreData = (offset) => {
        setIsLoading(true);
        apiService
            .getCaseActivity({
                case_id: caseId,
                all: true,
                offset: offset,
                limit: count,
                type: "",
                search: "",
            })
            .then((res) => {
                if (res.status === "success") {
                    setOffset(offset + 10);
                    setActivityCount(res.activityCount);
                    setActivityPage(0);
                    setIsLoading(false);
                    var nextOffset = (pagesize + 1) * count;
                    if (res.activityCount <= nextOffset) {
                        setExceedNotifyRecord[true]
                    } else {
                        setExceedNotifyRecord[false]
                    }

                    let data = activityDetails.concat(res.data);
                    setActivityDetails(data);
                    const datass = groupBy(data, "createdAt");
                    setActivityDetailsGroup(datass);
                }
            });
    };

    const loadMoreData = () => {
        setIsLoading(true);

        apiService
            .getCaseActivity({
                case_id: caseId,
                all: true,
                offset: offset,
                limit: count,
                type: "",
                search: "",
            })
            .then((res) => {
                setIsLoading(false);
                if (res.status === "success") {
                    setOffset(offset + 20);
                    let d = activityDetails.concat(res.data);
                    setActivityDetails(d);
                    const dd = groupBy(d, "createdAt");
                    setActivityDetailsGroup(dd);
                }
            });
    };


    function navigateToPage(data) {
        let link;
        //this.onClose();
        switch (data.type) {
            case 'chat':
                link = {
                    link_id: data.in_id
                };
             history.push('/app/case/'+(data.case_case_id)+'/message/'+btoa(JSON.stringify(link)));
                break;
            case 'invite':
                link = {
                    link_id: data.in_id
                };
               
                   history.push('/app/case/'+(data.case_case_id)+'/overview');
            
                break;
                case "case":
          
                    break;
            case 'task':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/tasks/'+btoa(JSON.stringify(link)));
                break;
            case 'calendar':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'keydate':
                link = {
                    link_id: data.in_id
                };
               history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                break;
            case 'settlement':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/outcomes/'+btoa(JSON.stringify(link)));
                break;
            case 'decision':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/outcomes/'+btoa(JSON.stringify(link)));
                break;
            case 'escalation':
                link = {
                    link_id: data.in_id
                };
                history.push('/app/case/'+(data.case_case_id)+'/overview/'+btoa(JSON.stringify(link)));
                break;
            case 'reminder':
                link = {
                    link_id: data.in_id
                };                
                if (data.description.includes("Task")) {
                    history.push('/app/case/'+(data.case_case_id)+'/tasks/'+btoa(JSON.stringify(link)));
                } else {
                    history.push('/app/case/'+(data.case_case_id)+'/events/'+btoa(JSON.stringify(link)));
                }
                break;
            default:
                history.push('/app/case/'+(data.case_case_id)+'/overview');
                break;
        }
    }

    const fetchFilterData = (filt_val) => {
        apiService
            .getCaseActivity({
                case_id: caseId,
                all: true,
                offset: 0,
                limit: 20,
                type: filt_val,
                search: "",
            })
            .then((res) => {
                if (res.status === "success") {
                    setOffset(offset + 10);
                    setIsLoading(false);
                    setActivityCount(res.activityCount);
                    setActivityDetails(res.data);
                    const datass = groupBy(res.data, "createdAt");
                    setActivityDetailsGroup(datass);
                }
            });
    };

    useEffect(() => {
        fetchMoreData(0);
    }, []);

    function activityfilter(filt_val) {
        setFilterValue(filt_val);
        //setOffset(0)
        onClose();
        fetchFilterData(filt_val);
    }

    function closeFunc(filt_val) {
        setFilterValue("");
        onClose();
        fetchFilterData("")
        // setOffset(0);
        // setActivityDetails([]);
        // fetchMoreData(0);
    }

    const showDrawer = () => {
        setVisible(!visible);
    };

    const onClose = () => {
        setVisible(!visible);
    };

    return (
        <div>
            <Row className="page-header" justify="space-between">
                <Col>Activity</Col>
                <Col onClick={showDrawer}>
                    <Filtericon />
                </Col>
            </Row>
            <Row>
            {isLoading?<Skeleton active loading={isLoading}></Skeleton>:

                <Collapse
                    className="activity_section"
                    defaultActiveKey={["sec_0"]}
                    bordered={false}
                    expandIconPosition={"right"}
                >
                    {activityDetailsGroup.map((actGroup, index) => (
                        <Panel header={<Divider orientation="left" dashed='true' orientationMargin={0} style={{margin: 0}}>{actGroup.key}</Divider>} className="activity_panel" key={'sec_'+index}>
                            <Timeline className="p16">
                                {actGroup.value.map((item, index) => (
                                    <Timeline.Item
                                        dot={<Avatar src={item.user_image}>{item.p_name}</Avatar>}
                                        color="red"
                                        key={index}
                                        onClick={() =>navigateToPage(item)}
                                    >
                                        <Col>
                                            <Row className="activity_timelineheader">
                                                {item.creator.name} ({item.creator.role_name}){" "}
                                                {item.description}
                                            </Row>
                                            <Row justify="space-between">
                                                <Col className="activitydate">{String(item.created_at).utcLocal().dateTimeFormat()}</Col>
                                                <Col className="activity_casefilter_text">
                                                    {caseTags[item.type]}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    ))}
                </Collapse>
            }
                {!isLoading && activityCount == 0 && (
                    <div className="activity-empty-data">
                        <EmptySearchIcon />
                        <p className="no-activity">No activity found</p>
                        <p className="filter-search">Try adjusting your search  or filter to find what you’re looking for.</p>
                    </div>
                )}
               {activityCount <=offset && exceedNotifyRecord == true ?
                     (loading ? (
                    <Row align="middle" justify="space-around">
                        <Col span={24}>
                            {/* <Spin spinning></Spin> */}
                            <Skeleton active title={true} avatar={true} paragraph={{ rows: 1 }} className="p8" />
                            <Skeleton active title={true} avatar={true} paragraph={{ rows: 1 }} className="p8" />
                            <Skeleton active title={true} avatar={true} paragraph={{ rows: 1 }} className="p8" />
                        </Col>
                    </Row>
                ) : (
                        <Row className="mb48 mt10" align="middle" justify="space-around">
                            <Col>
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => loadMoreData(offset)}
                                >
                                    Load More
                            </Button>
                            </Col>
                        </Row>
                    )): <Col className="activity-endlist-alignText">
                    <p>You’ve reached the end of the list! 🎉</p>
                </Col>}
            </Row>
            <Drawer
                placement="bottom"
                closable={false}
                mask={false}
                className="activityfilter-menu"
                contentWrapperStyle={{ bottom: 0, borderRadius: "4px 4px 0px 0px" }}
                height={360}
                onClose={onClose}
                visible={visible}
                zIndex={1200}
                style={{ position: "fixed" }}
            >
                <div>
                    <Row>
                        <Col>Filter</Col>
                        <Col>
                            <Closeicon onClick={onClose} />
                        </Col>
                    </Row>
                    <Col
                        className="filter_top mb8"
                        onClick={() => activityfilter("case")}
                    >
                        Case Updates
          </Col>
                    <Col
                        className="filter_top mb8"
                        onClick={() => activityfilter("task")}
                    >
                        Tasks
          </Col>
                    <Col
                        className="filter_top mb8"
                        onClick={() => activityfilter("meeting")}
                    >
                        Meetings{" "}
                    </Col>
                    <Col
                        className="filter_top mb8"
                        onClick={() => activityfilter("keydate")}
                    >
                        Key Date
          </Col>
                    <Col
                        className="filter_top mb8"
                        onClick={() => activityfilter("file")}
                    >
                        Files Folders
          </Col>
                    <Col
                        className="filter_top mb8"
                        onClick={() => activityfilter("questionnaire")}
                    >
                        Questionnaires{" "}
                    </Col>
                    <Col
                        className="filter_top mb8"
                        onClick={() => activityfilter("settlement")}
                    >
                        Case outcomes{" "}
                    </Col>
                    <Row>
                        <Col className="filter_top mb8" onClick={() => closeFunc()}>
                            Clear Filter
            </Col>
                        {/* <Button type="primary" size="small" className="textright">
                Apply Filter
                </Button> */}
                    </Row>
                </div>
            </Drawer>
        </div>
    );
}
export default Activity;
