import React, { useState, useEffect } from "react";
import { Row, Col, Menu, Dropdown, Avatar, Badge, Skeleton, Modal, ModalProps, Button, Spin, message } from 'antd';
import Icon from '@ant-design/icons';
import { Link, useHistory, useParams } from "react-router-dom";
import logo from '../../assets/logo.png';
import AlertSvg from  '../../assets/svg-icons/Alert.svg';
import ChatMultipleSvg from  '../../assets/svg-icons/ChatMultiple.svg';
import QuestionCircleSvg from  '../../assets/svg-icons/QuestionCircle.svg';
import AccountSvg from  '../../assets/svg-icons/account.svg';
import SettingsSvg from  '../../assets/svg-icons/settings.svg';
import ExitSvg from  '../../assets/svg-icons/exit_to_app.svg';
import SwitchUserSvg from  '../../assets/svg-icons/switch_user_icon.svg';
import SelectGreenSvg from  '../../assets/svg-icons/select_green.svg';
import userService from "../../logics/services/user.service";
import apiService from "../../logics/services/api.service";
import Notification from "./CaseView/Notification";
import MessageNotification from "./CaseView/MessageNotification";
import { websocketService, socket } from "../../logics/services/websocket.service";
import { useDispatch, useSelector } from "react-redux";
import addNotification from 'react-push-notification';
import store from "../../logics/store/case.store";
import { decodeToken } from "react-jwt";

import RoleClaimantSvg from  '../../assets/svg-icons/role_claimant.svg';
import RoleRespondentSvg from  '../../assets/svg-icons/role_respondent.svg';
import RoleLegalRepSvg from  '../../assets/svg-icons/role_legal_rep.svg';
import RoleRepSvg from  '../../assets/svg-icons/role_rep.svg';
import RoleCaseOfficerSvg from  '../../assets/svg-icons/role_case_officer.svg';
import RoleMediatorSvg from  '../../assets/svg-icons/role_mediator.svg';
import RoleArbitratorSvg from  '../../assets/svg-icons/role_arbitrator.svg';
import RoleWitnessSvg from  '../../assets/svg-icons/role_witness.svg';

const BellIcon = props => <Icon component={AlertSvg} {...props} />;
const ChatIcon = props => <Icon component={ChatMultipleSvg} {...props} />;
const QuestionIcon = props => <Icon component={QuestionCircleSvg} {...props} />;
const AccountIcon = props => <Icon component={AccountSvg} {...props} />;
const ExitIcon = props => <Icon component={ExitSvg} {...props} />;
const SwitchUserIcon = props => <Icon component={SwitchUserSvg} {...props} />;
const SelectGreenIcon = props => <Icon component={SelectGreenSvg} {...props} />;
const RoleClaimantIcon = props => <Icon style={{ fontSize: 36, verticalAlign: 'middle'}} component={RoleClaimantSvg} {...props} />;
const RoleRespondentIcon = props => <Icon style={{ fontSize: 36, verticalAlign: 'middle'}} component={RoleRespondentSvg} {...props} />;
const RoleLegalRepIcon = props => <Icon style={{ fontSize: 36, verticalAlign: 'middle'}} component={RoleLegalRepSvg} {...props} />;
const RoleRepIcon = props => <Icon style={{ fontSize: 36, verticalAlign: 'middle'}} component={RoleRepSvg} {...props} />;
const RoleCaseOfficerIcon = props => <Icon style={{ fontSize: 36, verticalAlign: 'middle'}} component={RoleCaseOfficerSvg} {...props} />;
const RoleMediatorIcon = props => <Icon style={{ fontSize: 36, verticalAlign: 'middle'}} component={RoleMediatorSvg} {...props} />;
const RoleArbitratorIcon = props => <Icon style={{ fontSize: 36, verticalAlign: 'middle'}} component={RoleArbitratorSvg} {...props} />;
const RoleWitnessIcon = props => <Icon style={{ fontSize: 36, verticalAlign: 'middle'}} component={RoleWitnessSvg} {...props} />;

function Toolbar() {
    const settings = useSelector(state => state.settings);
    const chatRefresh = useSelector(state => state.chatCountRefresh);
    const dispatch = useDispatch();
    const history = useHistory();
    let { caseId } = useParams();
    history.listen((location, action) => {
        // location is an object like window.location
        if (!location.pathname.includes('/app/account')) {
            localStorage.setItem('previousUrl', location.pathname);
        }
    });
    const user = store.getState().user;//userService.getUser();
    if (!user) {
        const user = decodeToken(localStorage.getItem('accessToken'));
        dispatch({type: 'user', value: user});
    }
    const [notification, setNotification] = useState(false);
    const [messageNotification, setMessageNotification] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [chatCount, setChatCount] = useState(0);
    const [inviteCount, setInviteCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [showSwitchRole, setShowSwitchRole] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [admin_email, setAdminEmail] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [roleLoading, setRoleLoading] = useState(false);
    const [roleBtnLoading, setRoleBtnLoading] = useState(false);

    useEffect(() => {
        getNotificationCount();

        socket.onopen = () => {
            console.log("Connected");
        };

        socket.onmessage = resData => {
            let res = (resData && resData.data) ? JSON.parse(resData.data): [];
            if (res && res.isDisplay && res.data && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(user.id) != -1) {
                const data = res.data;
                getNotificationCount();
                if (user.allow_notify) {
                    addNotification({
                        title: data.case_name,
                        message: (data.from ? data.from.name + ' (' + data.from.role_name + ') ': '') + data.content,
                        duration: 30000,
                        native: true
                    });
                }
            } 
            if (res && (res.method == 'message' || res.method == 'chat') && res.data && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(user.id) != -1) {
                getNotificationCount();
                const data = res.data;
                if (data.type == 'send_msg' && (!location.pathname.includes('/message') || (location.pathname.includes('/message') && data.case_id != caseId))) {                                                                                        
                    if (user.allow_notify) {
                        addNotification({
                            title: data.case_name,
                            message: (data.from ? data.from.name + ' (' + data.from.role_name + ') ': '') + data.content,
                            duration: 30000,
                            native: true
                        });
                    }
                }
            }
            if (res && res.method == 'chat' && res.data && res.data.type == 'join_conference' && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(user.id) != -1) {
                const data = res.data;
                addNotification({
                    title: data.case_name,
                    message: (data.from ? data.from.name + ' (' + data.from.role_name + ') ': '') + data.content,
                    duration: 30000,
                    native: true
                });
            }
        }

        socket.onclose = () => {
            console.log("Closed...");
        };
    }, []);

    const getNotificationCount = () => {
        apiService.getNotificationCount({
            type:'global'
        }).then((res) => {
            if (res.status === 'success') {
                setNotificationCount(res.notificationCount);
                setChatCount(res.chatCount);
                setInviteCount(res.inviteCount);
                setTotalCount(res.totalCount);
            }
        });
    }

    if (chatRefresh) {
        getNotificationCount();
        dispatch({ type: 'chatCountRefresh', value: null});
    }

    const logout = () => {
        apiService.logout()
            .then((res) => {
                if (res.status === 'success') {
                    dispatch({type: 'user', value: null});
                    userService.removeUser();
                    history.push('/auth/login');
                }
            });
    }

    const showNotification = () => {
        setNotification(true);
    };

    const closeNotification = (val) => {
        setNotification(val);
    }

    const showMessageNotification = () => {
        setMessageNotification(true);
    };

    const closeMessageNotification = (val) => {
        setMessageNotification(val);
    }

    const onLogoClick = () => {
        history.push(`/app/allcases`)
    }

    const showRoles = () => {
        setSelectedRole(user.role_label)
        setRoleLoading(true);
        setShowSwitchRole(true);
        apiService.getCurrentUserRoles()
            .then((res) => {
                setRoleLoading(false);
                if (res.status == 'success') {
                    setRolesList(res.data);
                    setAdminEmail(res.admin_email);
                }
            });
    }

    const closeRoleChange = () => {
        setShowSwitchRole(false);
    }

    const selectRole = (value) => {
        setSelectedRole(value)
    }

    const submitRoleChange = () => {
        setRoleBtnLoading(true);
        apiService.switchRole({
            role_label: selectedRole
        }).then((res) => {
            setRoleBtnLoading(false);
            if (res.status == 'success') {
                setShowSwitchRole(false);
                message.success(res.message);
                if (selectedRole != user.role_label && res.token) {
                    userService.setUserToken(res.token);
                    const user = decodeToken(localStorage.getItem('accessToken'));
                    dispatch({type: 'user', value: user});
                    let url = `/app/allcases`;
                    if(location.pathname == url) {
                        window.location.reload();
                    } else {
                        history.push(url);
                    }
                }
            } else {                
                message.error(res.message);
            }
        });
    }

    const menu = (
        <Menu className="profile-menu">
            <Menu.Item key="0" className="profile">
                <table>
                    <tbody>
                        <tr>
                            <td>{ user?.name }</td>
                            <td rowSpan={2}>
                                <Avatar size={'large'} src={user?.image}>{user?.p_name}</Avatar>
                            </td>
                        </tr>
                        <tr className="role">
                            <td>{ user?.role_name }</td>
                        </tr>
                    </tbody>
                </table>
            </Menu.Item>
            <Menu.Item key="1">
                <AccountIcon /> <Link to={`/app/account/profile`}>My Account</Link>
            </Menu.Item>
            {   user && user.role_count > 1 &&
                (
                    <Menu.Item key="2" onClick={showRoles}>
                        <SwitchUserIcon /> Switch Role
                    </Menu.Item>
                )
            }
            <Menu.Item className="logout" key="3">
                <ExitIcon /> <span onClick={logout}>Logout</span>
            </Menu.Item>
        </Menu>
    );
    return (
        <>
        <Row className="header">
            <Col span={10} className="header-left" onClick={onLogoClick}>                
                { settings && <img className="portal-logo" src={settings?.logo} alt="logo" /> }
                { !settings && <Skeleton style={{width: 100}} active paragraph={false} /> }
            </Col>
            <Col span={14} className="header-right">
                <ul>
                    {/* <li>
                        <QuestionIcon style={{ fontSize: '24px' }} />
                    </li> */}
                    <li>
                        <Badge count={chatCount} showZero={false} overflowCount={99}><ChatIcon style={{ fontSize: '24px' }} onClick={showMessageNotification}/></Badge>
                        { messageNotification 
                            ?
                                <MessageNotification visible={messageNotification} closeMessageNotificationVisible={closeMessageNotification}/>
                            :
                                <></>
                        }
                    </li>
                    <li>
                        <Badge count={totalCount} showZero={false} overflowCount={99}>
                            <BellIcon style={{ fontSize: '24px' }} onClick={showNotification} />
                        </Badge>
                        { notification 
                            ?
                                <Notification visible={notification} closeNotificationVisible={closeNotification}/>
                            :
                                <></>
                        }
                    </li>
                    <li>
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" arrow={{ pointAtCenter: true }}>
                            <a className="ant-dropdown-link" href="/" onClick={e => e.preventDefault()}>
                                <Avatar src={user?.image}>{user?.p_name}</Avatar>
                            </a>
                        </Dropdown>
                    </li>
                </ul>
            </Col>
        </Row>
        
        <Modal
            visible={showSwitchRole}
            title={null}
            zIndex="10000"
            footer={<Button type="primary" disabled={selectedRole == user?.role_label} loading={roleBtnLoading} onClick={submitRoleChange} className="w100">Next</Button>}
            onCancel={closeRoleChange}
            className="switch-role-popup"
        >
            <div className="textCenter">
                <h4>Account Selection</h4>
                <p>Choose a Role you want to move forward</p>
            </div>
            <Row justify="center">
                <Spin className="textCenter" spinning={roleLoading}></Spin>
                {rolesList.map((item, index) => (
                    <Col onClick={() => selectRole(item.role_label)} span={24} className={"role-list-card "+(selectedRole == item.role_label?'selected':'') } key={'role_'+index}>
                        <Row gutter={16} align={"middle"}>
                            <Col>
                                { selectedRole == item.role_label ? <SelectGreenIcon />: <SelectGreenIcon style={{visibility:'hidden'}} />}
                            </Col>
                            <Col>
                                { item.role_label == 'Claimant' && <RoleClaimantIcon />}
                                { item.role_label == 'Respondent' && <RoleRespondentIcon />}
                                { item.role_label == 'Legal Representative' && <RoleLegalRepIcon />}
                                { item.role_label == 'Representative' && <RoleRepIcon />}
                                { item.role_label == 'Witness' && <RoleWitnessIcon />}
                                { item.role_label == 'Case Officer' && <RoleCaseOfficerIcon />}
                                { item.role_label == 'Mediator' && <RoleMediatorIcon />}
                                { item.role_label == 'Arbitrator' && <RoleArbitratorIcon />}
                            </Col>
                            <Col>{ item.role_name }</Col>
                        </Row>
                    </Col>
                ))}
            </Row>
            <p className="info">Don't see your other Roles? Please contact support by clicking <a href={"mailto:"+admin_email}>here</a></p>
        </Modal>
        </>
    );
}
export default Toolbar;