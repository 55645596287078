import userService from "../services/user.service";
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';


String.prototype.utcLocal = function () {
    if (!this) {
        return this;
    }
    const user = userService.getUser();
    let timezone = user ? user.timezone: '';
    if(timezone) {
        return momentTimeZone.utc(this).tz(timezone).format('YYYY-MM-DDTHH:mm:ss');
    } else {
        return moment.utc(this).local();
    }
}
