import React, { useState } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Input, Button, Checkbox , Drawer, Select } from 'antd';
import Icon from '@ant-design/icons';

import ArbitrationSvg from  '../../../assets/svg-icons/arbitration.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';

const ArbitrationIcon = props => <Icon component={ArbitrationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;

const { Option } = Select;
const { TextArea } = Input;

class CaseClose extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.caseId=this.props.caseId;
        this.visible=this.props.visible;
        //this.caseName=this.props.caseName;
        this.state = {
            caseCloseVisible: this.visible,
        };
    }

    componentDidMount = async () => {
        let response;
        const caseId = this.caseId;
        this.user = userService.getUser();
        apiService.getChatRoom({
            case_id: caseId
        }).then((res) => {
            if (res.status === 'success') {
                this.chatList=res.data;
            }
        });
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'none';
    };

    onClose = () => {
        this.setState({caseCloseVisible: false});
        this.props.closeCaseVisible(false);
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'flex';
    };

    render() {
        const { caseCloseVisible } = this.state;
        console.log(this.caseName);
        return (
            <div>
                <Drawer
                    title="Close case"
                    className="case-close-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={caseCloseVisible}
                    >

                        <Row className="sub-header mb8">
                            <Col span={20}>
                                <ArbitrationIcon className="fill-svg-w" />
                                <span className="pl16">Test</span>
                                <span className="pl8">
                                    <StarIcon />
                                </span>
                            </Col>
                        </Row>

                        <Row className="case-close-content p15">

                            <Row className="mb16">
                                <Col span={24}>
                                    <p className="mb8">Why do you want to close the case?</p>
                                </Col>
                                <Col span={24}>
                                    <Select name="case-close-reason" id="case-close-reason" style={{ width: "100%" }} placeholder="Select a reason">
                                        <Option value="Answered">Answered</Option>
                                        <Option value="Unresolved">Unresolved</Option>
                                        <Option value="Unanswered">Unanswered</Option>
                                        <Option value="UnPursuable">UnPursuable</Option>
                                        <Option value="Other">Other</Option>
                                    </Select>
                                </Col>
                            </Row>

                            <Row className="mb16">
                                <Col span={24}>
                                    <p className="mb8">Remarks (optional)</p>
                                </Col>
                                <Col span={24}>
                                    <TextArea name="case-close-comment" id="case-close-comment" rows={6} />
                                </Col>
                            </Row>

                            <Row className="mb16">
                                <Col span={24}>
                                    <p className="mb8">What happens if I close the case?</p>
                                </Col>
                                <Col span={24}>
                                    <span>Please note that once case is closed, participants will no longer be able to perform some actions in it.</span>
                                </Col>
                            </Row>

                            <Row className="mb16">
                                <Col span={24}>
                                    <Checkbox>I confirm, I want to close the case.</Checkbox>
                                </Col>
                            </Row>

                        </Row>

                        <Row className="case-close-footer">
                            <Col span={12}>
                                <Button type="primary" className="case-close-cancel">Cancel</Button>
                            </Col>
                            <Col span={12}>
                                <Button type="primary" className="case-close-submit">Close Case</Button>
                            </Col>
                        </Row>
                        
                </Drawer>
            </div>            
        );
    }
}

export default CaseClose;